/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {IntlShape} from "react-intl";
import {ProductsStatus} from "../../../modules/auth";

export const productStatus = (status: ProductsStatus) => {
    switch (status) {
        case ProductsStatus.ENABLED:
            return 'success'
        case ProductsStatus.CONCEPT:
            return 'primary'
        default:
            return 'danger'
    }
}

export const StatusOptions: () => { label: string; value: string }[] = () => Object.values(ProductsStatus).map(status => ({value: status, label: `${status.charAt(0).toUpperCase()}${status.slice(1).toLowerCase()}`}))

export const getCycleFormat = (intl: IntlShape, cycle?:string) => {
    switch (cycle) {
        case 'P1W':
            return intl.formatMessage({id: 'PRODUCTS.CYCLE1W', defaultMessage: 'week'})
        case 'P1M':
            return intl.formatMessage({id: 'PRODUCTS.CYCLE1M', defaultMessage: 'month'})
        case 'P2M':
        case 'P3M':
        case 'P4M':
        case 'P5M':
        case 'P6M':
        case 'P7M':
        case 'P8M':
        case 'P9M':
            return intl.formatMessage({id: 'PRODUCTS.CYCLEXM', defaultMessage: '{count} months'}, {count: cycle.charAt(1)})
        case 'P10M':
        case 'P11M':
        case 'P12M':
            return intl.formatMessage({id: 'PRODUCTS.CYCLEXM', defaultMessage: '{count} months'}, {count: cycle.substr(1, 2)})
        /*case 'P1Y':
            return intl.formatMessage({id: 'PRODUCTS.CYCLE1Y', defaultMessage: 'year'})*/
        case 'P2Y':
            return intl.formatMessage({id: 'PRODUCTS.CYCLE2Y', defaultMessage: '2 years'})
        default:
            return intl.formatMessage({id: 'PRODUCTS.CYCLE1Y', defaultMessage: 'year'})
    }
}

export const cycleUntilFormat = (intl: IntlShape, cycle?:string, until?:string|number) => {
    return intl.formatMessage({id: 'PRODUCTS.CYCLEUNTIL', defaultMessage: 'Every {cycle} {until}'}, {cycle: getCycleFormat(intl,cycle),until: getUntilFormat(intl,until)})
}

export const getUntilFormat = (intl: IntlShape, until?:string|number) => {
    if(until === 0 || until === -1 || until === "" || until == null || until === "0" || until === "-1")
        return intl.formatMessage({id: 'PRODUCTS.INDEFINITELY', defaultMessage: 'indefinitely'} )
    return intl.formatMessage({id: 'PRODUCTS.UNTIL', defaultMessage: 'for {until}'}, {until: getCycleFormat(intl,until.toString())})
}