import {
    Column,
    Row,
    TableInstance,
    useExpanded,
    UseExpandedOptions,
    UseExpandedState,
    useFilters,
    UseFiltersInstanceProps,
    UseFiltersOptions,
    UseFiltersState,
    useGlobalFilter,
    UseGlobalFiltersInstanceProps,
    UseGlobalFiltersOptions,
    UseGlobalFiltersState,
    usePagination,
    UsePaginationInstanceProps,
    UsePaginationState,
    useRowSelect,
    UseRowSelectInstanceProps,
    UseRowSelectOptions,
    UseRowSelectState,
    useSortBy,
    useTable
} from "react-table";
import React, {useEffect, useMemo, useRef, forwardRef, HTMLInputTypeAttribute} from "react";
import {useIntl} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fuzzyTextFilter from "./filters/fuzzyTextFilter";
import rangeDateFilter from "./filters/rangeDateFilter";
import dumbTextFilter from "./filters/dumbTextFilter";
import defaultGlobalFilter from "./filters/defualtGlobalFilter";
import {Pagination} from "./addons/pagination";
import {MenuComponent} from "../../../assets/ts/components";
import dataFilter from "./filters/dataFilter";

export type PaginationTableInstance = TableInstance &
    UsePaginationInstanceProps<any>  &
    UseGlobalFiltersInstanceProps<any> &
    UseGlobalFiltersOptions<any> &
    UseExpandedOptions<any> &
    UseFiltersInstanceProps<any> &
    UseFiltersOptions<any> &
    UseRowSelectInstanceProps<any> &
    UseRowSelectOptions<any> & {
    state: UsePaginationState<any> & UseExpandedState<any> & UseGlobalFiltersState<any> & UseFiltersState<any> & UseRowSelectState<any>;
};

type TPrTable = {
    columns: Array<Column>,
    rawdata: Array<any>,
    globalFilter: string,
    localFilter: IlocalFilter,
    pageSize: number,
    TableContent: React.FC<iContent>
    tableType: string
    rowSelect?: boolean
    reloadTrigger?: Function,
    selectedRows?: Function
    sortee?: any
}

interface iContent {
    prepareRow: Function,
    row: Row,
    rawdata: any[]
}

export interface IlocalFilter {
    columnId: string|null;
    filter: string|Date[]|null
}

// @ts-ignore
const IndeterminateCheckbox = forwardRef<HTMLInputElement,HTMLInputTypeAttribute>( ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef<HTMLInputElement>(null)
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            // @ts-ignore
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input className='form-check-input' type="checkbox" ref={resolvedRef} {...rest} />
            </div>
        )
    }
)

export const PrTable: React.FC<TPrTable> = ({columns,rawdata, globalFilter, localFilter, pageSize, TableContent, tableType, rowSelect=false, reloadTrigger, sortee}) => {
    const intl = useIntl();
    const data = useMemo(() => rawdata,[rawdata]);
    const filterOptions = { filteredIds: [] };
    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilter,
            rangeDate: rangeDateFilter,
            text: dumbTextFilter,
            filterSearch: dataFilter
        }),
        []
    )
    const initialState:{[index:string]:any} = { globalFilter, pageSize:pageSize };
    if(typeof sortee !== "undefined")
        initialState.sortBy = sortee;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        setFilter,
        setGlobalFilter,
    } = useTable({
            columns,
            data,
            // @ts-ignore
            initialState: initialState,
            // @ts-ignore
            globalFilter: (rows: any[], columnIds: any[], filterValue: any) => defaultGlobalFilter(rows, columnIds, filterValue,filterOptions),
            filterTypes
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
    ) as PaginationTableInstance
    useEffect(() => {
        setGlobalFilter(globalFilter || undefined);
        if(localFilter.columnId!==null){
            setFilter(localFilter.columnId,localFilter.filter)
        }
        setPageSize(pageSize);
        setTimeout(() => MenuComponent.reinitialization(),200)
    }, [globalFilter,localFilter,pageSize]);
    return (
        <>
            <table {...getTableProps({className:'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'})}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps({className:'fw-bolder text-muted'})}>
                        {headerGroup.headers.map(column => (
                            // @ts-ignore
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column?.className}>
                                {column.render('Header')}
                                <span>
                                    {/*
                                    @ts-ignore */}
                                    {column.isSorted?(column.isSortedDesc?<FontAwesomeIcon icon="fa-duotone fa-caret-down" className='ms-2' />:<FontAwesomeIcon icon="fa-solid fa-caret-up" className='ms-2' />):''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    //@ts-ignore
                    return (<TableContent prepareRow={prepareRow} row={row} rawdata={rawdata} reloadTrigger={reloadTrigger} />)
                })}
                </tbody>
            </table>
            <div className='d-flex my-5 pt-5'>
                <span className='d-flex align-items-center'>{intl.formatMessage({id: 'TRANSACTIONS.SHOWINGXOFX', defaultMessage: 'Showing {num} {type} of {num2}'},{num: state.pageSize,num2:data.length, type: tableType})}</span>
                <Pagination  gotoPage={gotoPage} previousPage={previousPage} canPreviousPage={canPreviousPage} nextPage={nextPage} canNextPage={canNextPage} pageIndex={state.pageIndex} pageCount={pageCount} pageOptions={pageOptions} />
            </div>
        </>
    )
}

export const PrTableSelect: React.FC<TPrTable> = ({columns,rawdata, globalFilter, localFilter, pageSize, TableContent, tableType, rowSelect=false, reloadTrigger, selectedRows}) => {
    const intl = useIntl();
    const data = useMemo(() => rawdata,[rawdata]);
    const filterOptions = { filteredIds: [] };
    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilter,
            rangeDate: rangeDateFilter,
            text: dumbTextFilter,
            filterSearch: dataFilter
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        setFilter,
        setGlobalFilter,
        selectedFlatRows
    } = useTable({
            columns,
            data,
            // @ts-ignore
            initialState: { globalFilter, pageSize:pageSize },
            globalFilter: (rows: any[], columnIds: any[], filterValue: any) => defaultGlobalFilter(rows, columnIds, filterValue,filterOptions),
            filterTypes
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            if(rowSelect) {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        // @ts-ignore
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <div>
                                {/*
                                @ts-ignore */}
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }) as PaginationTableInstance
    useEffect(() => {
        setGlobalFilter(globalFilter || undefined);
        if(localFilter.columnId!==null){
            setFilter(localFilter.columnId,localFilter.filter)
        }
        setPageSize(pageSize);
        setTimeout(() => MenuComponent.reinitialization(),200)
    }, [globalFilter,localFilter,pageSize]);
    useEffect(() => {
        if(typeof selectedFlatRows === "undefined" || typeof selectedRows === "undefined")
            return;
        selectedRows(selectedFlatRows.map(row => row.original.id))
    }, [selectedFlatRows]);

    return (
        <>
            <table {...getTableProps({className:'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'})}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps({className:'fw-bolder text-muted'})}>
                        {headerGroup.headers.map(column => (
                            // @ts-ignore
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column?.className}>
                                {column.render('Header')}
                                <span>
                                    {/*
                                    @ts-ignore */}
                                    {column.isSorted?(column.isSortedDesc?<FontAwesomeIcon icon="fa-duotone fa-caret-down" className='ms-2' />:<FontAwesomeIcon icon="fa-solid fa-caret-up" className='ms-2' />):''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    //@ts-ignore
                    return (<TableContent prepareRow={prepareRow} row={row} rawdata={rawdata} key={`prow${row.id}`} reloadTrigger={reloadTrigger} />)
                })}
                </tbody>
            </table>
            <div className='d-flex my-5 pt-5'>
                <span className='d-flex align-items-center'>{intl.formatMessage({id: 'TRANSACTIONS.SHOWINGXOFX', defaultMessage: 'Showing {num} {type} of {num2}'},{num: state.pageSize,num2:data.length, type: tableType})}</span>
                <Pagination  gotoPage={gotoPage} previousPage={previousPage} canPreviousPage={canPreviousPage} nextPage={nextPage} canNextPage={canNextPage} pageIndex={state.pageIndex} pageCount={pageCount} pageOptions={pageOptions} />
            </div>
        </>
    )
}