import React, {FC} from "react";
import {PersonalLink, Products} from "../../../../modules/auth";
import {hexToTextColor} from "../../RequestHelpers";
import Skeleton from "react-loading-skeleton";
import {PaymentLink} from "../../../../modules/requests/core/_models";
import {CurrencyFormat} from "../../../../modules/currency/components/format";
import {IntlShape, useIntl} from "react-intl";
import {useRequest} from "../../RequestWrapper";

type Props = {
    product: Products|undefined
    linkData: PersonalLink|PaymentLink|undefined
    clickable?: boolean
    active?: boolean
}


export const getCycleFormat = (intl: IntlShape, cycle?:string) => {
    switch (cycle) {
        case 'P1W':
            return intl.formatMessage({id: 'PRODUCTS.CYCLE1W', defaultMessage: 'week'})
        case 'P1M':
            return intl.formatMessage({id: 'PRODUCTS.CYCLE1M', defaultMessage: 'month'})
        case 'P2M':
        case 'P3M':
        case 'P4M':
        case 'P5M':
        case 'P6M':
        case 'P7M':
        case 'P8M':
        case 'P9M':
            return intl.formatMessage({id: 'PRODUCTS.CYCLEXM', defaultMessage: '{count} months'}, {count: cycle.charAt(1)})
        case 'P10M':
        case 'P11M':
        case 'P12M':
            return intl.formatMessage({id: 'PRODUCTS.CYCLEXM', defaultMessage: '{count} months'}, {count: cycle.substr(1, 2)})
        /*case 'P1Y':
            return intl.formatMessage({id: 'PRODUCTS.CYCLE1Y', defaultMessage: 'year'})*/
        case 'P2Y':
            return intl.formatMessage({id: 'PRODUCTS.CYCLE2Y', defaultMessage: '2 years'})
        default:
            return intl.formatMessage({id: 'PRODUCTS.CYCLE1Y', defaultMessage: 'year'})
    }
}

export const cycleUntilFormat = (intl: IntlShape, cycle?:string, until?:string|number) => {
    return intl.formatMessage({id: 'PRODUCTS.CYCLEUNTIL', defaultMessage: 'Every {cycle} {until}'}, {cycle: getCycleFormat(intl,cycle),until: getUntilFormat(intl,until)})
}

export const getUntilFormat = (intl: IntlShape, until?:string|number) => {
    if(until === 0 || until === -1 || until === "" || until == null || until === "0" || until === "-1")
        return intl.formatMessage({id: 'PRODUCTS.INDEFINITELY', defaultMessage: 'indefinitely'} )
    return intl.formatMessage({id: 'PRODUCTS.UNTIL', defaultMessage: 'for {until}'}, {until: getCycleFormat(intl,until.toString())})
}

const ProductBar: FC<Props> = ({product,linkData, clickable=false, active=false}) => {
    const intl = useIntl()
    const {setProduct,setVariables} = useRequest();
    if(typeof linkData === "undefined"||typeof product === "undefined")
        return <></>
    const cycle = product?.metaValues?.find(x => x.slug === "cycle")?.value
    const fee = product?.metaValues?.find(x => x.slug === "starting_fee")?.value ?? product?.metaValues?.find(x => x.slug === "fee")?.value
    return <div className={`productbar d-flex flex-column flex-md-row justify-content-between ${clickable&&'cursor-pointer rounded'} ${active&&'is-active shadow-lg'} overflow-hidden`} style={{
        ['--color' as any]: `${hexToTextColor(linkData?.user?.background1 ?? '000000')}`,
        ['--coloractive' as any]: `#${linkData?.user?.background1 ?? '000000'}`,
        background: `linear-gradient(121deg, #${linkData?.user?.background1 ?? '000000'} 0%, #${linkData?.user?.background2 ?? '2d2a2e'} 100%)`,
    }} onClick={()=>{
        if(!clickable)
            return
        setProduct(product)
        setVariables(old=>({...old,page:'single'}))
    }}>
        <div className="d-flex flex-row gap-7">
            {product?.metaValues?.find(x => x.slug==="main_image")&&<img src={`${process.env.REACT_APP_API_URL}/uploads/avatar/${product?.metaValues?.find(x => x.slug==="main_image")?.value}`} alt={product?.name} className="img-fluid h-125px" />}
            <div className={`d-flex flex-column flex-grow-1 align-self-center align-items-start`}>
                <h2 className={`text-start text-uppercase pe-3 pe-md-10 py-3 py-md-6 mb-0 align-self-start align-self-md-center ${clickable&&'fs-5'} flex-grow-1 ${typeof product?.metaValues?.find(x => x.slug==="main_image")==="undefined"?'ps-3 ps-md-10':''}`} style={{'color':'var(--color)'}}>{product?.name ?? <Skeleton width="100%" height={35} />}</h2>
                <div className="product-pricing text-start text-uppercase pe-3 pb-3 mb-0 d-flex d-md-none flex-column align-self-center w-100 flex-grow-1" style={{'color':'var(--color)'}}>
                    <span className="align-self-start">{typeof cycle !== 'undefined'?`Every ${getCycleFormat(intl,cycle??'P1M')}`:``}</span>
                    <span className="fs-3 me-0">
                        <CurrencyFormat currencyClassName="pe-2" amount={product?.price??0} className="pricing" currency={product?.currency??linkData?.user?.currency} formatAmount={true}/>
                    </span>
                    {typeof fee !== "undefined"&&<span style={{opacity:.8}}>{typeof cycle !== 'undefined'?'Initial startup fee':'Additional fee'}: <CurrencyFormat currencyClassName="pe-2" amount={parseFloat(fee)??0} className="pricing" currency={product?.currency??linkData?.user?.currency}/></span>}
                </div>
            </div>
        </div>
        <div className="product-pricing text-end text-uppercase px-10 py-6 mb-0 d-none d-md-flex flex-row flex-md-column align-self-center w-100 flex-grow-1 w-md-auto" style={{'color':'var(--color)'}}>
            <span className="align-self-center align-self-md-end">{typeof cycle !== 'undefined'?`Every ${getCycleFormat(intl,cycle??'P1M')}`:''}</span>
            <span className="fs-3 me-auto me-md-0">
                <CurrencyFormat currencyClassName="pe-2" amount={product?.price??0} className="pricing" currency={product?.currency??linkData?.user?.currency} formatAmount={true}/>
            </span>
            {typeof fee !== "undefined"&&<span>{typeof cycle !== 'undefined'?'Initial startup fee':(linkData?.user?.feeTitle??'Additional fee')}: <CurrencyFormat currencyClassName="pe-2" amount={parseFloat(fee)??0} className="pricing" currency={product?.currency??linkData?.user?.currency}/></span>}
        </div>
    </div>
}

export {ProductBar}