import React, {
  FC,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react'
import {toAbsoluteUrl} from '../helpers'
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import de from 'javascript-time-ago/locale/de.json'
import nl from 'javascript-time-ago/locale/nl.json'
import es from 'javascript-time-ago/locale/es.json'
import fr from 'javascript-time-ago/locale/fr.json'
import ja from 'javascript-time-ago/locale/ja.json'
import zh from 'javascript-time-ago/locale/zh.json'
import it from 'javascript-time-ago/locale/it.json'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'nl' | 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh' | 'it'
  setSelectedLang: Dispatch<SetStateAction<'nl' | 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh' | 'it'>>
}
const initialState: Props = {
  selectedLang: 'en',
  setSelectedLang: (
    prevState: SetStateAction<'nl' | 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh' | 'it'>
  ) => prevState,
}

export const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'nl',
    name: 'Dutch',
    flag: toAbsoluteUrl('/media/flags/netherlands.svg'),
  },
  {
    lang: 'zh',
    name: 'Mandarin',
    flag: toAbsoluteUrl('/media/flags/china.svg'),
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
  {
    lang: 'ja',
    name: 'Japanese',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  },
  {
    lang: 'de',
    name: 'German',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  {
    lang: 'fr',
    name: 'French',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
  {
    lang: 'it',
    name: 'Italian',
    flag: toAbsoluteUrl('/media/flags/italy.svg'),
  },
]

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

export const useLanguage = () => useContext(I18nContext)

const timeAgoLangs = {
  en: en,
  de: de,
  nl: nl,
  es: es,
  fr: fr,
  ja: ja,
  zh: zh,
  it: it,
}
TimeAgo.addDefaultLocale(en)
const MetronicI18nProvider: FC = ({children}) => {
  const lang = getConfig()
  const [selectedLang, setSelectedLang] = useState(lang.selectedLang || 'en')
  useEffect(() => {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: selectedLang}))
  }, [selectedLang])
  try {
    TimeAgo.addLocale(timeAgoLangs[lang.selectedLang])
  } catch (e) {
    //TimeAgo.addDefaultLocale(en)
  }
  return (
    <I18nContext.Provider
      value={{
        selectedLang,
        setSelectedLang,
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}

export {MetronicI18nProvider, useLang}
