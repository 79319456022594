import React, {FC, useCallback, useEffect, useState} from "react";
import {FileRejection, useDropzone} from 'react-dropzone'
import {CustomField} from "../../../../modules/auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CSSTransition} from "react-transition-group";
import { v4 as uuidv4 } from "uuid";
import {fileUpload} from "../../../../modules/requests/core/_requests";
import {useRequest} from "../../RequestWrapper";

type itemProps = {
    item: CustomField
}

const getAccept = (name:string):{[key:string]:any} => {
    if(name==="image upload") {
        return {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/gif': ['.gif']
        }
    }else if(name==="video upload"){
        return {
            'video/*': []
        }
    }else if(name==="file upload") {
        return {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/gif': ['.gif'],
            'video/*': [],

            'application/pdf': ['.pdf'],
            'application/msword': ['.doc', '.docx'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-excel': ['.xls', '.xlsx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/plain': ['.txt'],
            'text/csv': ['.csv'],
            'application/zip': ['.zip'],
            'application/x-rar-compressed': ['.rar'],
            'application/x-7z-compressed': ['.7z'],
            'application/x-tar': ['.tar'],
            'application/gzip': ['.gz'],
            'application/x-bzip2': ['.bz2'],
            'application/x-bzip': ['.bz'],
            'application/x-iso9660-image': ['.iso'],
            'application/x-msdownload': ['.exe'],
            'application/x-msi': ['.msi'],
        }
    }
    return {'*':[]}
}

const Media:FC<itemProps> = ({item}) => {
    const {setExtraFields} = useRequest();
    const index = (item?.label?.text ?? item?.name) + ' - ' + item?.id;
    const accept = getAccept(item?.name.toLowerCase())
    const chunkSize = 1048576
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [errorTimeout, setErrorTimeout] = useState<NodeJS.Timeout>()
    const [uploadFile, setUploadFile] = useState<any>("")
    const [uploading, setUploading] = useState(false)
    const [counter, setCounter] = useState(0)
    const [imgUrl, setImgUrl] = useState<string>("");
    const [chunks, setChunks] = useState(0)
    const [chunkStart, setChunkStart] = useState(0)
    const [chunkEnd, setChunkEnd] = useState(1048576 )
    const [fileType, setFileType] = useState("")
    const [fileGuid, setFileGuid] = useState("")
    const [fileName, setFileName] = useState("")
    const [fileSize, setFileSize] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState<Array<{size:number,name:string,url:string}>>([]);
    const onDrop = useCallback((acceptedFiles:File[], fileRejections:FileRejection[]) => {
        try{
            clearTimeout(errorTimeout)
        }catch (e) {}
        setErrorMessage('')
        acceptedFiles.forEach((file) => {
            /*setCounter(0)
            setFileName(file.name)
            setFileGuid(uuidv4())
            setChunks(Math.ceil(file.size / chunkSize))
            setFileType(file.type)*/
            setImgUrl('')
            setChunkStart(0)
            setChunkEnd(1048576)
            setFileName(file.name)
            setFileGuid(uuidv4())
            setCounter(0)
            setFileSize(file.size)
            setChunks(Math.ceil(file.size / chunkSize))
            setFileType(file.type)
            setUploadFile(file)
            /*const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const binaryStr = reader.result
                console.log(binaryStr)
                if(typeof binaryStr === 'string')
                    setUploadFile(binaryStr)
            }
            reader.readAsText(file)*/
        })
        if(fileRejections.length>0){
            fileRejections.forEach((fileRejection:FileRejection) => {
                if(fileRejection?.errors?.[0]?.code==="file-invalid-type") {
                    if(item?.name.toLowerCase()==="image upload")
                        setErrorMessage(`File is not an (accepted) image format.`)
                    else if(item?.name.toLowerCase()==="video upload")
                        setErrorMessage(`File is not an (accepted) video format.`)
                    else
                        setErrorMessage(`File is not accepted.`)
                    setErrorTimeout(setTimeout(() => { setErrorMessage('') },5000))
                }
            })
        }

    }, [])
    const { /*acceptedFiles, fileRejections,*/
        getRootProps, getInputProps,
        isDragActive, isDragAccept,
        isDragReject } = useDropzone({
        useFsAccessApi: false,
        onDrop,
        accept
    })
    /*const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
        </li>
    ));
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));*/
    const filetype = item?.name.toLowerCase().replace(" upload","")

    useEffect(() => {
        if(counter<chunks) {
            setUploading(true)
            processUpload()
        }else if(counter>=1) {
            setUploading(false)
            setCounter(0)
            setChunks(0)
            if (imgUrl!=='') {
                setUploadedFiles([...uploadedFiles, {size:fileSize, name:fileName, url: imgUrl}])
                setExtraFields(oldFields=>({...oldFields, [index]: [...oldFields?.[index], {size:fileSize, name:fileName, url: imgUrl}]}))
            }
        }
    }, [counter,uploadFile]);
    const processUpload = () => {
        fileUpload(fileName,fileGuid,uploadFile.slice(chunkStart,chunkEnd), fileType).then(response => {
            if(typeof response !== "boolean")
                setImgUrl(response.url)
            setChunkStart(chunkStart + chunkSize)
            setChunkEnd(chunkEnd + chunkSize)
            setCounter(counter+1)
        })
    }
    const removeFile = (url:string) => {
        setUploadedFiles(uploadedFiles.filter((file) => file.url!==url))
        setExtraFields(oldFields=>({...oldFields, [index]: uploadedFiles.filter((file) => file.url!==url)}))
    }
    return <>
        <div {...getRootProps({className:'d-flex justify-content-center align-items-center border-1 border-gray-300 border-dotted bg-light rounded-2'})}>
            <input {...getInputProps()} />
            {!uploading?
                <div className={`d-flex flex-row p-5 opacity-75 flex-wrap justify-content-center`}>
                    {uploadedFiles.length > 0 &&
                        <div className="d-flex flex-column w-100 flex-grow-1 align-items-center filehandler bg-opacity-50 bg-secondary rounded-2 p-0 mb-3 border border-gray-400">
                            {uploadedFiles.map((file, index) => <div className="d-flex flex-row w-100 align-items-center">
                                <div className={"d-flex flex-column flex-grow-1 py-2 ps-5"}>
                                    {/*
                                    @ts-ignore */}
                                    <div className="fs-5 fs-bold"><FontAwesomeIcon icon={`fa-duotone fa-file-${filetype==='file'?'lines':filetype}`} className={"me-2"} />{file.name}</div>
                                    <div className="fs-8 ms-5">{file.size} bytes</div>
                                </div>
                                <button className="btn btn-light-danger h-100 rounded-0" onClick={(e)=>{e.preventDefault();removeFile(file.url)}}>
                                    {/*
                                    @ts-ignore */}
                                    <FontAwesomeIcon icon="fa-duotone fa-trash" />
                                </button>
                            </div>)}
                        </div>}
                    {/*
                        @ts-ignore */}
                    {isDragAccept &&  <> <FontAwesomeIcon icon="fa-duotone fa-binary-circle-check" className={`me-3 fs-${uploadedFiles.length > 0?'2':'1'}`} style={{color:'var(--background1)'}} />
                        <span className={`fs-${uploadedFiles.length > 0?'5':'4'}`}>Release your file to start upload</span></>}
                    {/*
                        @ts-ignore */}
                    {isDragReject &&  <> <FontAwesomeIcon icon="fa-duotone fa-cloud-xmark" className={`me-3 fs-${uploadedFiles.length > 0?'2':'1'} text-warning`} />
                        <span className={`fs-${uploadedFiles.length > 0?'5':'4'}`}>This file is not accepted</span></>}
                    {/*
                        @ts-ignore */}
                    {!isDragActive &&  <> <FontAwesomeIcon icon="fa-duotone fa-cloud-arrow-up" className={`me-3 fs-${uploadedFiles.length > 0?'2':'1'}`} style={{color:'var(--background1)'}} />
                        <span className={`fs-${uploadedFiles.length > 0?'5':'4'}`}>Click here or drop a {filetype} to upload...</span></>}
                </div> :<>
                <div className="progress flex-grow-1 m-5 h-auto">
                    {/*
                            @ts-ignore */}
                    <div className="progress-bar p-3" role="progressbar"
                         aria-valuenow={counter/chunks*100}
                         aria-valuemin="0" aria-valuemax="100"
                         style={{
                             width: `${counter/chunks*100}%`,
                             background: `linear-gradient(121deg, var(--background1) 0%, var(--background2) 100%)`
                         }}>
                        <div className='flex-nowrap'>
                            Uploading...
                        </div>
                    </div>
                </div>
            </>}
        </div>
        <CSSTransition
            in={errorMessage!==''}
            timeout={600}
            classNames="pr__stepper"
            unmountOnExit
        >
            <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
            </div>
        </CSSTransition>
    </>
}

export {Media}