import React, {FC} from "react";
import {PageTitle} from "../../../_metronic/layout/core";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {Footer} from "../../../_metronic/layout/components/Footer";

const RequestNotFound: FC = () => {

    return (
        <>
            <PageTitle breadcrumbs={[]}>Request could not be found!</PageTitle>
            <div className='d-flex flex-column flex-root'>
                <div
                    className='d-flex flex-column flex-column-fluid '
                >
                    <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
                        <a href='https://dashboard.payrequest.io' className='mb-10 pt-lg-20'>
                            <img
                                alt='Logo'
                                src={toAbsoluteUrl('/media/logo/logo.svg')}
                                className='h-50px mb-5'
                            />
                        </a>
                        <div className='pt-lg-10 mb-10'>
                            <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Woops! Not Found</h1>

                            <div className='fw-bold fs-3 text-gray-400 mb-15'>
                                The request you're looking for, <br />can't be found!
                            </div>
                            <div className='text-center'>
                                <a href='https://payrequest.io' className='btn btn-lg btn-primary fw-bolder'>
                                    Go to our homepage
                                </a>
                            </div>
                        </div>
                        <div
                            className='
                              d-flex
                              flex-row-auto
                              bgi-no-repeat
                              bgi-position-x-center
                              bgi-size-contain
                              bgi-position-y-center
                              min-h-100px min-h-lg-350px
                            '
                            style={{
                                backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/404_graphic.png')}')`,
                            }}
                        ></div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export {RequestNotFound}