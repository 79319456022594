import {FC} from "react";
import {Step1} from "./giropay/Step1";
import {useRequest} from "../../../pages/requests/RequestWrapper";

export type GiropayProps = {
    setError: Function
}

const Giropay: FC<GiropayProps> = ({setError}) => {
    const {step} = useRequest()

    switch (step) {
        case 1:
        case 2:
        default:
            return <Step1 setError={setError} />
    }
}

export {Giropay}