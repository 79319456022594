import ReactDOM from 'react-dom'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import { IntercomProvider } from 'react-use-intercom';

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
setupAxios(axios)

Chart.register(...registerables)

const INTERCOM_APP_ID = 'hnql4ol0';

ReactDOM.render(
    <IntercomProvider appId={INTERCOM_APP_ID}>
        <MetronicI18nProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </MetronicI18nProvider>
    </IntercomProvider>,
  document.getElementById('root')
)
