import {useDashboard} from "../DashboardWrapper";

const NONCE_LOCAL_STORAGE_KEY = 'pr-nonce-react-v'
const getNonceLocal = (): string | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(NONCE_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const nonce: string = lsValue
        if (nonce) {
        return nonce
        }
    } catch (error) {
        console.error('NONCE LOCAL STORAGE PARSE ERROR', error)
    }
}

const setNonceLocal = (nonce: string) => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.setItem(NONCE_LOCAL_STORAGE_KEY, nonce)
    } catch (error) {
        console.error('NONCE LOCAL STORAGE SAVE ERROR', error)
    }
}

const removeNonceLocal = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(NONCE_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('NONCE LOCAL STORAGE REMOVE ERROR', error)
    }
}

const VIEWID_LOCAL_STORAGE_KEY = 'pr-viewid-react-v'
const getViewIdLocal = (): string | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(VIEWID_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const viewId: string = lsValue
        if (viewId) {
        return viewId
        }
    } catch (error) {
        console.error('VIEWID LOCAL STORAGE PARSE ERROR', error)
    }
}
const setViewIdLocal = (viewId: string) => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.setItem(VIEWID_LOCAL_STORAGE_KEY, viewId)
    } catch (error) {
        console.error('VIEWID LOCAL STORAGE SAVE ERROR', error)
    }
}
const removeViewIdLocal = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(VIEWID_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('VIEWID LOCAL STORAGE REMOVE ERROR', error)
    }
}

export function setupAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config: {headers: {Authorization: string,View: string}}) => {
            const {loggedin} = useDashboard()
            if (loggedin) {
                config.headers.Authorization = `Bearer ${getNonceLocal()} - ${getViewIdLocal()}`
            }
            return config
        },
        (error: any) => {
            Promise.reject(error)
        }
    )
}

export {getNonceLocal, setNonceLocal, removeNonceLocal, getViewIdLocal, setViewIdLocal, removeViewIdLocal}