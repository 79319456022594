import {Route, Routes} from 'react-router-dom'
import {SingleRequest} from "../pages/requests/SingleRequest";
import {RequestNotFound} from "../pages/requests/RequestNotFound";
import {IframeLayout} from "../../_metronic/layout/IframeLayout";

const IframeRoutes = () => {
    return (
        <Routes>
            <Route element={<IframeLayout />}>
                <Route path=':linkid' element={<SingleRequest />} />
            </Route>
            <Route path='not-found' element={<RequestNotFound />} />
        </Routes>
    )
}

export {IframeRoutes}
