import React from "react";

const defaultGlobalFilter = (rows: any[], columnIds: any[], filterValue: string | null | undefined, options?: { filteredIds: any[]; }) => {
    console.log('global searchfilter')
    if (filterValue === "" || filterValue === null || filterValue === undefined)
        return rows;

    if (typeof filterValue !== "string")
        return [];
    const textSearchValues = filterValue.trim().toLocaleLowerCase();
    const arraySearchValues = textSearchValues.split(" ");

    const textContent = (elem: React.ReactElement | string):string => {
        if (!elem)
            return '';
        else if (typeof elem === 'string')
            return elem;
        const children = elem.props && elem.props.children;
        if (children instanceof Array)
            return children.map(textContent).join('');
        return textContent(children);
    }
    return rows.filter((row) => {
        const { values } = row;
        const textValues = columnIds
            .map((col) => textContent(values[col]))
            .join(" ")
            .split(" ") // Delete extra spaces between words
            .filter((v) => v !== "")
            .join(" ")
            .toLocaleLowerCase();

        return arraySearchValues.every((str) => textValues.includes(str));
    });
};

export default defaultGlobalFilter