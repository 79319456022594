import React, {FC, useEffect, useState} from 'react'
import {CurrencyFormat} from '../../../../modules/currency/components/format'
import {hexToTextColor} from '../../RequestHelpers'
import {useRequest} from '../../RequestWrapper'
import {useIntl} from 'react-intl'

const amountsFallback = ['5', '10', '15', '25', '50', '100']

type Props = {
  amounts?: string[]
  currency?: string
  background1?: string
  background2?: string
}

const PriceBar: FC<Props> = ({amounts, currency, background1, background2}) => {
  const intl = useIntl()
  const {amount, setAmount} = useRequest()
  const [customAmount, setCustomAmount] = useState<string>('')
  const getAmounts = (amounts: string[] | undefined) => {
    if (typeof amounts === 'undefined' || amounts === null) return amountsFallback
    return amounts
  }

  useEffect(() => {
    if (typeof amount === 'string' && amount.startsWith('custom-'))
      setAmount(`custom-${customAmount}`)
  }, [customAmount])

  return (
    <div
      className='d-grid personal__pricing border rounded-3 w-100'
      style={{
        background: `linear-gradient(121deg, #${background1 ?? '000000'} 0%, #${
          background2 ?? '2d2a2e'
        } 100%)`,
        ['--color' as any]: `${hexToTextColor(background1 ?? '000000')}`,
      }}
    >
      {getAmounts(amounts).map((a) => (
        <div
          className={`pr__definedamounts d-flex justify-content-center flex-row fs-4 py-4 ${
            amount === a ? 'active' : ''
          }`}
          onClick={() => setAmount(a)}
        >
          <CurrencyFormat
            formatAmount={true}
            styleCents={true}
            currencyClassName='pe-2'
            amount={Number(a)}
            className='pricing fw-normal'
            currency={currency}
          />
        </div>
      ))}
      <div
        className={`pr__fullwidth ${
          typeof amount === 'string' && amount.startsWith('custom-') ? 'active' : ''
        }`}
      >
        <input
          className='w-100 fs-5 border-0 text-center py-4 fw-normal'
          type='number'
          min={1}
          step={0.01}
          placeholder={intl.formatMessage({
            id: 'LINK.AMOUNT',
            defaultMessage: 'Or enter your own amount',
          })}
          onClick={() => setAmount(`custom-${customAmount}`)}
          onChange={(e) => setCustomAmount(e?.target?.value ?? '')}
        />
      </div>
    </div>
  )
}

export {PriceBar}
