import React, {FC, ReactNode, useState} from 'react'
import {useIntl} from 'react-intl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {GiphyFetch} from '@giphy/js-fetch-api'
import {IGif} from '@giphy/js-types'
import {Gif} from '@giphy/react-components'

type PaidProps = {children?: ReactNode; gif?: string}

const Paid: FC<PaidProps> = (props) => {
  const [paidgif, setPaidgif] = useState<IGif | undefined>(undefined)
  if (typeof props.gif !== 'undefined' && props.gif.length > 0) {
    const gf = new GiphyFetch('3nqHFo1i8VgFtTTYZHJANlSqVcL1eK8g')
    gf.gif(props.gif).then((gif) => {
      setPaidgif(gif['data'])
    })
  }
  const intl = useIntl()
  return (
    <div className='px-10  rounded-bottom rounded-3'>
      {paidgif && (
        <div className={'giphy'}>
          <Gif gif={paidgif} width={400} />{' '}
        </div>
      )}

      <div className='alert bg-light-success d-flex flex-column flex-sm-row p-5 mt-10'>
        <span className='svg-icon svg-icon-2hx svg-icon-success me-4 mb-5 mb-sm-0'>
          {/*
                    @ts-ignore */}
          <FontAwesomeIcon icon='fa-duotone fa-face-smile-beam' />
        </span>
        <div className='d-flex flex-column text-success pe-0'>
          <h5 className='mb-1'>
            {intl.formatMessage({id: 'LINK.PAID', defaultMessage: 'This request is paid'})}
          </h5>
          <span>
            {intl.formatMessage({
              id: 'LINK.PAID.DESC',
              defaultMessage: 'This request has been paid already.',
            })}
          </span>
        </div>
      </div>
    </div>
  )
}

export {Paid}
