import React, {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useRequest} from "../../pages/requests/RequestWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {KTSVG} from "../../../_metronic/helpers";

const PaypalVerify: FC = () => {
    const intl = useIntl()
    const {variables} = useRequest()
    const [isPaid, setIsPaid] = useState<boolean|undefined>();
    const [error, setError] = useState<string>();
    const [failed, setFailed] = useState<string>();
    useEffect(() => {
        if(typeof variables === "undefined" || typeof variables?.paymentStatus === "undefined")
            return

        if(variables?.paymentStatus!=='success')
            setFailed(intl.formatMessage({id: 'GENERAL.FAILED', defaultMessage: 'Your payment is failed, please try again!'}))
        else
            setIsPaid(true)

    }, [variables]);

    if(typeof error !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{error}</div>
        </div>
    }
    if(typeof failed !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning">
            <KTSVG path="/media/icons/duotune/general/gen045.svg" className="svg-icon-3x svg-icon-warning me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{failed}</div>
        </div>
    }
    return <>
        {typeof isPaid !== "boolean" && <div className="d-flex flex-column w-100 py-15">
            <h3 data-kt-indicator="on" className="d-flex card-title align-items-center flex-column justify-content-center mb-3">
        <span className="indicator-label">
            {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}
        </span>
                <span className="card-label fw-bolder fs-3 indicator-progress text-center">
            {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}<br/>
            <span className="spinner-border align-middle mt-10"></span>
        </span>
            </h3>
        </div>}
        {isPaid && <div className="px-10">
            <div className="alert bg-light-success d-flex flex-column flex-sm-row p-5 mt-10">
                <span className="svg-icon svg-icon-2hx svg-icon-success me-4 mb-5 mb-sm-0">
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-face-smile-beam" />
                </span>
                <div className="d-flex flex-column text-success pe-0">
                    <h5 className="mb-1">Thank you for your payment</h5>
                    <span>Your payment has been registered and the owner has been notified.</span>
                </div>
            </div>
        </div>}
    </>
}
export {PaypalVerify}