import axios from 'axios'
import {RedirectResponse, SuccessResponse} from "../../stripe/core/_models";
import {ErrorMessage} from "../../../modules/requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const CREATE_PAYMENT = `${API_URL}/api/msp/create`
export const VERIFY_PAYMENT = `${API_URL}/api/msp/verify`

export function createPayment(data:object): Promise<RedirectResponse|ErrorMessage> {
    return axios.post(
        `${CREATE_PAYMENT}`,
        data).then(response => response.data as unknown as RedirectResponse)
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}

export function verifyPayment(id:string,mid:string): Promise<SuccessResponse|RedirectResponse|ErrorMessage> {
    return axios.post(
        `${VERIFY_PAYMENT}`,
        {id,mid}).then(response => {
            if("redirect" in response.data)
                return response.data as unknown as RedirectResponse
            else
                return response.data as unknown as SuccessResponse
        })
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}