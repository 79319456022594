import React, {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {useDashboard} from "./DashboardWrapper";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {KTSVG} from "../../../_metronic/helpers";
import {
    check,
    getSubscriptionPayments,
    getSubscriptionProduct,
    setSubscriptionCancel,
    setSubscriptionPause, setSubscriptionResume
} from "../../modules/auth/core/_requests";
import {useParams} from "react-router";
import {CurrencyFormat} from "../../modules/currency/components/format";
import {SubscriptionStatus as SubStatus} from "./components/SubscriptionStatus";
import Swal from "sweetalert2";
import {Products, Subscription, SubscriptionStatus, Transactions} from "../../modules/auth";
import Skeleton from "react-loading-skeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getNonceLocal, getViewIdLocal, setNonceLocal, setViewIdLocal} from "./components/NonceHelper";
import withReactContent from "sweetalert2-react-content";
import {cycleUntilFormat} from "./components/subscriptionsHelper";

const viewBreadCrumbs: Array<PageLink> = [
    {
        title: 'PayRequest',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Subscription Management',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    }
]

const getCustomFields = (transaction:Transactions): {[index:string]:any} => {
    let customFields = transaction.customFields;
    if(!customFields) return {};
    if('address' in customFields && typeof customFields.address !== "undefined") {
        //const address = customFields.address;
        delete customFields.address;
    }
    return customFields
}

const View: FC = () => {
    const intl = useIntl()
    const navigator = useNavigate()
    const {sid} = useParams()
    const {setLoggedin,customer,setCustomer,setNonce,setCid,setSubscription,subscription} = useDashboard()
    const [title, setTitle] = useState("Loading...");

    const MySwal = withReactContent(Swal)
    const [isBusy] = useState<boolean>(false);
    const [payments, setPayments] = useState<Array<Transactions>>();
    const [product, setProduct] = useState<Products>()
    const errorSwal = (title?:string,descr?:string) => MySwal.fire({
        title: <p>{title??'Woops'}</p>,
        icon: 'error',
        html: <p>{descr??'Something went wrong. Please try again later.'}</p>,
        didOpen: () => MySwal.showLoading(),
        timer: 3000
    })

    useEffect(() => {
        const nonce = getNonceLocal()
        const viewId = getViewIdLocal()
        console.log(nonce,viewId)
        if(typeof nonce === 'undefined'||typeof viewId === 'undefined'||typeof sid === 'undefined')
            return
        check(nonce,viewId).then((res) => {
            if("error" in res){
                navigator('/dashboard')
                return
            }
            if(typeof customer === "undefined")
                setCustomer(res.customer)
            setCid(res.cid)
            setNonce(res.nonce)
            setNonceLocal(res.nonce)
            setViewIdLocal(res.cid)
            setLoggedin(true)
            setSubscription(res.customer?.subscriptions?.find((s) => s.id === parseInt(sid)))
            getSubscriptionProduct(sid).then(prod=>{
                if("error" in prod){
                    console.error(prod.error)
                    return
                }
                setProduct(prod)
            })
            getSubscriptionPayments(sid).then(subPayments=>{
                if("error" in subPayments){
                    console.error(subPayments.error)
                    return
                }
                setPayments(subPayments)
            })
        })
    }, [sid]);
    useEffect(() => {
        setTitle(`Subscription #${subscription?.id} - '${subscription?.name}'`)
    }, [subscription]);

    const pauseSubscription = () => {
        if(typeof subscription === "undefined") {
            errorSwal('Still loading...','The subscription is still loading, please wait...')
            return
        }
        let psp:string = getPsp(subscription?.psp??0)
        if(psp===''){
            errorSwal('No PSP provided','It seems like the payment provider isn\'t supported or given.')
            return
        }
        MySwal.fire({
            title: intl.formatMessage({id: 'SUBSCRIPTIONS.CONFIRMPAUSE', defaultMessage: 'Do you want to pause the subscription?'}),
            html: <p>Remember! the maximum pause duration is 12 months.</p>,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                setSubscriptionPause(subscription?.id.toString(), psp).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.PAUSED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISPAUSED', defaultMessage: 'Subscription is paused for a max of 12 months'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }
        })
    }
    const getPsp = (pspid:number) => {
        switch (pspid) {
            case 1:
                return 'mollie'
            case 2:
                return 'stripe'
            case 3:
                return 'multisafepay'
            case 4:
                return 'paypal'
            default:
                return '';
        }
    }
    const resumeSubscription = () => {
        if(typeof subscription === "undefined") {
            errorSwal('Still loading...','The subscription is still loading, please wait...')
            return
        }
        let psp:string = getPsp(subscription?.psp??0)
        if(psp===''){
            errorSwal('No PSP provided','It seems like the payment provider isn\'t supported or given.')
            return
        }
        MySwal.fire({
            title: intl.formatMessage({id: 'SUBSCRIPTIONS.CONFIRMRESUME', defaultMessage: 'Do you want to resume the subscription?'}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'GLOBAL.YES',defaultMessage: 'Yes'}),
            cancelButtonText: intl.formatMessage({id: 'GLOBAL.NO',defaultMessage: 'No'}),
            reverseButtons: true,
            preConfirm(inputValue: any) {
                setSubscriptionResume(subscription?.id.toString(), psp).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.ENABLED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISRESUMED', defaultMessage: 'Subscription is resumed'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }
        })
    }
    const cancelSubscription = () => {
        if(typeof subscription === "undefined") {
            errorSwal('Still loading...','The subscription is still loading, please wait...')
            return
        }
        let psp:string = getPsp(subscription?.psp??0)
        if(psp===''){
            errorSwal('No PSP provided','It seems like the payment provider isn\'t supported or given.')
            return
        }
        MySwal.fire({
            title: intl.formatMessage({id: 'SUBSCRIPTIONS.CONFIRMCANCEL', defaultMessage: 'Are you sure you want to cancel your subscription?'}),
            icon: "warning",
            input: 'textarea',
            inputLabel: 'Reason to cancel',
            inputPlaceholder: 'No reason? Just leave this empty.',
            inputAttributes: {
                'aria-label': 'No reason? Just leave this empty.'
            },
            inputValue: '',
            buttonsStyling: false,
            confirmButtonText: "Yes, as of now",
            cancelButtonText: "Never mind",
            denyButtonText: "Yes, at the end of this period",
            showDenyButton: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            showLoaderOnDeny: true,
            customClass: {
                confirmButton: "btn fw-bold btn-primary",
                cancelButton: "btn fw-bold btn-secondary",
                denyButton: "btn fw-bold btn-primary",
            },
            preConfirm(inputValue: any) {
                setSubscriptionCancel(subscription?.id.toString(), psp, 'direct', inputValue).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.CANCELLED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISCANCELLED', defaultMessage: 'Subscription is cancelled'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            },
            preDeny(inputValue: any){
                setSubscriptionCancel(subscription?.id.toString(), psp, 'end', inputValue).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.PENDING_CANCEL}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISCANCELLED', defaultMessage: 'Subscription will be cancelled at the end of the period'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }
        })
        /*
        preConfirm(inputValue: any) {
                setSubscriptionResume(subscription?.id.toString(), psp).then(message=>{
                    if(message.status==='success'){
                        setSubscription(oldSub=>({...oldSub, status: SubscriptionStatus.ENABLED}) as Subscription)
                        MySwal.fire({
                            title: intl.formatMessage({id: 'SUBSCRIPTIONS.ISRESUMED', defaultMessage: 'Subscription is resumed'}),
                            icon: 'success',
                            showConfirmButton: false,
                            didOpen: () => MySwal.showLoading(),
                            timer: 3000
                        })
                    }else if("error" in message)
                        errorSwal(intl.formatMessage({id: 'SUBSCRIPTIONS.ERROR', defaultMessage: 'Something went wrong!'}),message.error)
                })
            }
        * */
    }

    return <>
        <PageTitle breadcrumbs={viewBreadCrumbs}>{title}</PageTitle>
        <div className='page d-flex flex-row justify-content-center'>
            <div className='row g-5 container'>
                <div className="col-md-8 col-12">
                    <div className="col-12 d-flex mb-5 justify-content-between">
                        <Link to={'/dashboard/overview'} className='btn btn-secondary btn-sm'>
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr063.svg'
                                className='svg-icon-4 me-3'
                            /> Back
                            {/*
                            @ts-ignore */}
                            {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                        </Link>
                        {(sid!=='manage'&&typeof subscription?.status !== "undefined" && !([SubscriptionStatus.PAUSED,SubscriptionStatus.PENDING_CANCEL,SubscriptionStatus.CANCELLED].includes(subscription?.status))) &&
                            <button className='btn btn-light-warning btn-sm' disabled={isBusy} onClick={()=>pauseSubscription()}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-pause" className='me-3'/>
                                Pause Subscription
                                {/*
                                @ts-ignore */}
                                {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"}/>}
                            </button>
                        }
                        {(typeof subscription?.status !== "undefined" && ([SubscriptionStatus.PAUSED,].includes(subscription?.status))) &&
                            <button className='btn btn-light-warning btn-sm' disabled={isBusy} onClick={()=>resumeSubscription()}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-play-pause" className='me-3'/>
                                Resume Subscription
                                {/*
                                @ts-ignore */}
                                {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"}/>}
                            </button>
                        }

                        {(typeof subscription?.status !== "undefined" && !([SubscriptionStatus.CANCELLED,SubscriptionStatus.PENDING_CANCEL].includes(subscription?.status))) &&
                            <button className='btn btn-light-danger btn-sm' disabled={isBusy} onClick={() => cancelSubscription()}>
                                {/*
                    @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-trash-can" className='me-3' />
                                Cancel Subscription
                                {/*
                    @ts-ignore */}
                                {isBusy && <FontAwesomeIcon icon={"fa-duotone fa-spinner"} className={"ms-2 fa-spin"} />}
                            </button>}
                    </div>
                    <div className='card mb-10 mb-xl-10'>
                        <div className='card-header border-0 py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Subscription Details</span>
                                <span className='text-muted fw-bold fs-7'>General information about your subscription</span>
                            </h3>
                        </div>
                        <div className='card-body py-3'>
                            {/* fill 2 columns with multiple rows of information [title]: [value] */}
                            <div className='row'>
                                <div className='col-md-6 col-12'>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.NAME', defaultMessage: 'Name'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>{subscription?.name ?? <Skeleton width={80} height={14}/> }</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.CYCLE', defaultMessage: 'Cycle'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>{product?.metaValues?cycleUntilFormat(intl, product.metaValues.find(value => value.slug==='cycle')?.value ?? 'P1M', product.metaValues.find(value => value.slug==='until')?.value ?? -1):<Skeleton width={80} height={14}/>}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12'>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.CREATED', defaultMessage: 'Created At'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                <span>
                                                    {intl.formatDate(subscription?.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                                </span>
                                                <span className='text-muted d-block fs-7'>
                                                    {intl.formatTime(subscription?.createdAt,{hour12:false})}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.CURRENCY', defaultMessage: 'Currency'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                {typeof subscription?.currency !== "undefined"? subscription?.currency : <Skeleton width={40} height={14}/>}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <label className='form-label'>{intl.formatMessage({id: 'SUBSCRIPTIONS.PRICE', defaultMessage: 'Price'})}</label>
                                        </div>
                                        <div className='col-md-8 col-12'>
                                            <span className='form-control-plaintext pt-0 mb-2'>
                                                {typeof subscription?.price !== "undefined"? <CurrencyFormat className='ms-1' currency={subscription?.currency} amount={subscription?.price}/>:<Skeleton width={70} height={14}/> }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card mb-10 mb-xl-10'>
                        <div className='card-header border-0 py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Payments</span>
                            </h3>
                        </div>
                        <div className='card-body pt-3 pb-10'>
                            {typeof payments !== "undefined"?(
                                (payments == null || payments.length < 1)?
                                    <div className="alert alert-info d-flex align-items-center p-5 mb-10">
                                        <span className="svg-icon svg-icon-2hx svg-icon-info me-3">
                                            {/*
                                            @ts-ignore*/}
                                            <FontAwesomeIcon icon="fa-duotone fa-face-sad-cry" />
                                        </span>
                                        <div className="d-flex flex-column"><h5 className="mb-1">No payments found!</h5>
                                            <span>Sorry to bring the bad news, you don't have any payments yet on this request.</span>
                                        </div>
                                    </div> :
                                    <div className="table-responsive">
                                        <table className="table table-striped gy-3 gs-3">
                                            <thead>
                                            <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                                <th className="min-w-100px">Invoice #</th>
                                                <th className="min-w-100px">Amount</th>
                                                <th className="min-w-150px">Paid on</th>
                                                <th className="min-w-80px">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {payments.map((transaction, index) => (
                                                <>
                                                    <tr key={`trans-${index}`}>
                                                        <td className="align-middle">
                                                            <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                                                #{transaction?.invoice?.id ?? ''}
                                                            </span>
                                                        </td>
                                                        <td className="align-middle">
                                                            <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                                              <CurrencyFormat currency={transaction.currency} amount={transaction.amount}/>
                                                            </span>
                                                        </td>
                                                        <td className="align-middle">
                                                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                                            {intl.formatDate(transaction.createdAt,{year:'numeric',month:'long',day:'numeric'})}
                                                        </span>
                                                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                                                            {intl.formatTime(transaction.createdAt,{hour12:false})}
                                                        </span>
                                                        </td>
                                                        <td className="align-middle">
                                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                                {transaction?.invoice && (<a
                                                                    href={`${process.env.REACT_APP_API_URL}/uploads/invoices/${transaction?.invoice?.file}`} target="_blank"
                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' >
                                                                    {/*
                                                                @ts-ignore*/}
                                                                    <FontAwesomeIcon icon="fa-duotone fa-file-invoice" className='fs-3' />
                                                                </a>)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {Object.keys(getCustomFields(transaction)).length>0 && (<tr>
                                                        <td colSpan={4}>
                                                            <h5 className='fw-bold fs-6'>
                                                                Custom Fields:
                                                            </h5>
                                                            <div>
                                                                {Object.values(Object.fromEntries(Object.entries(getCustomFields(transaction))
                                                                    .map(([customFieldKey, customField]) => [
                                                                        customFieldKey,
                                                                        (typeof customField === "object" && customField !== null)?
                                                                            Object.values(Object.fromEntries(Object.entries(customField).map(([subFieldKey, subField]) => [
                                                                                subFieldKey,
                                                                                (<div className='py-2'>
                                                                                    <strong>
                                                                                        {subFieldKey[0].toUpperCase() + subFieldKey.substring(1).toLowerCase()}:
                                                                                    </strong>
                                                                                    {subField}
                                                                                </div>)
                                                                            ])))
                                                                            :
                                                                            (<div className='py-2'>
                                                                                <strong>
                                                                                    {customFieldKey[0].toUpperCase() + customFieldKey.substring(1).toLowerCase()}:
                                                                                </strong>
                                                                                {customField}
                                                                            </div>)
                                                                    ]))
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>)}
                                                </>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                            ):(
                                <Skeleton count={5} height={40} width={"100%"}/>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12">
                    <div data-kt-sticky="true" data-kt-sticky-width={true}>
                        <SubStatus expanded={true} status={subscription?.status}/>

                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-header border-0 py-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Customer</span>
                                    <span className='text-muted fw-bold fs-7'>Who did you send it to?</span>
                                </h3>
                            </div>
                            <div className='card-body pt-3 pb-6'>
                                {/* show avatar on the left, name and mail next to it*/}
                                <div className='row'>
                                    <div className='col-md-3 col-12'>
                                        <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                                            {customer?.avatar?<img src={customer?.avatar} alt={customer?.name} />:<Skeleton width={40} height={40} />}
                                        </div>
                                    </div>
                                    <div className='col-md-9 col-12'>
                                        {customer?.name ?? <Skeleton width={100} height={18} />}
                                        <span className='d-block text-muted fw-bold fs-7'>{customer?.email ?? <Skeleton width={120} height={14}/> }</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export {View}