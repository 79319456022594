import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import React from "react";
import {Row} from "react-table";
import {useIntl} from "react-intl";

interface iContent {
    prepareRow: Function,
    row: Row,
    rawdata: any[]
}
export const Subscriptions:React.FC<iContent> = ({prepareRow, row, rawdata}) => {
    const intl = useIntl()
    prepareRow(row)
    return (
        <>
            <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                    if(cell.column.Header==="Actions"){
                        return (<td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                                <OverlayTrigger
                                    key={"tooltip-show-"+row.id+"-"+cell.column.id}
                                    placement='left'
                                    overlay={(props)=><Tooltip id={"tooltip-show-item-"+row.id+"-"+cell.column.id} {...props}>
                                        {intl.formatMessage({id: 'TRANSACTIONS.OPENLINK', defaultMessage: 'Open payment or request'})}
                                    </Tooltip>}
                                >
                                    <Link
                                        //@ts-ignore
                                        to={`/dashboard/${row.original?.id}`}
                                        className='btn btn-icon btn-bg-white btn-text-gray-500 btn-active-color-primary btn-sm me-1'>
                                        {/*
                                        @ts-ignore*/}
                                        <FontAwesomeIcon icon="fa-duotone fa-eye" className='fs-3' />
                                    </Link>
                                </OverlayTrigger>
                            </div>
                        </td>)
                    }
                    return <td {...cell.getCellProps()} key={`cell${row.id}-${cell.column.id}`}>{cell.render('Cell')}</td>
                })}
            </tr>
        </>
    )
};