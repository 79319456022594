import {FC, ReactNode} from "react";
import {CustomField} from "../../../modules/auth";
import {Text} from "./types/Text";
import {DateTime} from "./types/DateTime";
import {Multi} from "./types/Multi";
import {Media} from "./types/Media";

type itemProps = {
    field: CustomField
}

type ContainerProps = {
    children: ReactNode
    styling: {
        background?: string;
        border?: boolean;
        borderColor?: string;
        borderSize?: number;
        borderRadius?: string;
    }
    label: {
        visible?: boolean;
        text?: string;
        color?: string;
    }
    required: boolean
    width: number|undefined
    height?: string|boolean
}

const ContainerExtraField: FC<ContainerProps> = ({children, styling, required,label, width, height=false}) => {
    const personalStyling: {[key:string]:any} = {}
    if (styling.background) personalStyling['background'] = styling.background
    if (styling.border) personalStyling['border'] = `${styling.borderSize}px solid ${styling.borderColor}`
    if (styling.borderRadius) personalStyling['borderRadius'] = styling.borderRadius
    if (height) personalStyling['height'] = `${height??100}px`
    const labelStyling: {[key:string]:any} = {}
    if (label.color) labelStyling['color'] = label?.color
    return <div className={`col-${width??12} form-group rounded position-relative`}
                style={personalStyling} >
        {label?.visible&&<label className={`form-label ${required?'required':''}`} style={labelStyling}>{label?.text}</label>}
        {children}
    </div>
}

const ExtraField: FC<itemProps> = ({field}) => {
    if(!field?.label?.text)
        field.label = {...field.label,text:field?.name}
    switch (field?.name?.toLowerCase()) {
        case "spacer":
            return <ContainerExtraField label={field?.label} required={field?.general?.required??false} styling={field?.container??{}} width={field?.general?.width??12}>Nbsp;</ContainerExtraField>;
        case "plain text":
            return <ContainerExtraField label={field?.label} required={field?.general?.required??false} styling={field?.container??{}} width={field?.general?.width??12}>
                <div className="form-text" dangerouslySetInnerHTML={{__html:field?.html??''}}></div>
            </ContainerExtraField>;
        case 'single line':
        case 'number':
        case 'multi line':
        case 'email':
        case 'telephone':
        case 'wysiwyg':
            return <ContainerExtraField label={field?.label} required={field?.general?.required??false} styling={field?.container??{}} width={field?.general?.width??12}>
                <Text item={field}/>
            </ContainerExtraField>;
        case 'date':
        case 'time':
        case 'date and time':
            return <ContainerExtraField label={field?.label} required={field?.general?.required??false} styling={field?.container??{}} width={field?.general?.width??12}>
                <DateTime item={field}/>
            </ContainerExtraField>;
        case 'yes/no':
        case 'yesno':
        case 'dropdown':
        case 'checkbox':
        case 'radiobutton':
            return <ContainerExtraField label={field?.label} required={field?.general?.required??false} styling={field?.container??{}} width={field?.general?.width??12}>
                <Multi item={field}/>
            </ContainerExtraField>;
        case 'image upload':
        case 'video upload':
        case 'file upload':
            return <ContainerExtraField label={field?.label} required={field?.general?.required??false} styling={field?.container??{}} width={field?.general?.width??12}>
                <Media item={field}/>
            </ContainerExtraField>;
    }
    return <ContainerExtraField label={field?.label} required={field?.general?.required??false} styling={field?.container??{}} width={field?.general?.width??12}>Can't load due to unexpected field</ContainerExtraField>;
}

export {ExtraField}