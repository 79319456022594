import React, {FC} from "react";
import {paymentMethodIcons} from "../../../modules/requests/core/_assets";
import {useRequest} from "../RequestWrapper";

type Props = {
    psp: string
    methodName: string
    setError: Function
}

const Method: FC<Props> = ({psp, methodName, setError}) => {

    const {type,method,setMethod, setPsp, variables,product} = useRequest()

    if(['link','us_bank_account_ach','afterpay_clearpay','affirm','acss_debit','banktransfer','blik','belfius'].includes(methodName))
        return <></>
    const getIcon = (method:string) => {
        if(typeof paymentMethodIcons[method.toLowerCase()] !== "undefined")
            return paymentMethodIcons[method]
        return "ideal-logo.svg";
    }

    const updateMethod = (method:string, psp:string) => {
        //if(product?.metaValues?.find(x => x.slug === "cycle")?.value !== undefined && product?.metaValues?.find(x => x.slug === "cycle")?.value !== "") {
        if ((typeof variables?.name === "undefined" || typeof variables.name === "boolean" || variables.name.length === 0)) {
            setError("Please enter your name in the form above.")
            return
        }
        if ( (typeof variables?.mail === "undefined" || typeof variables.mail === "boolean" || variables.mail.length === 0)) {
            setError("Please enter your email in the form above.")
            return
        }
        if (typeof variables.mail === "string" && ((new RegExp('^(.+)@(.+)$', '')).exec(variables?.mail) === null)) {
            setError("Please enter a valid email in the form above.")
            return
        }
        //}
        setError(undefined)
        setPsp(psp)
        setMethod(method)
    }
    if(methodName === 'creditcard') {
        return (
            <>
                <button key={`${methodName}-visa`} onClick={(e)=>{
                    e.preventDefault()
                    updateMethod(`${methodName}-visa`, psp)
                }}
                   className={`d-inline-flex flex-column justify-content-center align-items-center btn ${method===`${methodName}-visa`?'btn-bg-secondary':(type==='personal'?'btn-bg-white':'btn-bg-light')} btn-hover-scale  btn-active-secondary`}>
                    <img src={`https://media.payrequest.nl/images/payment-icons/${paymentMethodIcons['visa']}`} className="w-100"
                         alt="VISA"/>
                </button>
                <button key={`${methodName}-mastercard`} onClick={(e)=>{
                    e.preventDefault()
                    updateMethod(`${methodName}-mastercard`, psp)
                }}
                    className={`d-inline-flex flex-column justify-content-center align-items-center btn ${method===`${methodName}-mastercard`?'btn-bg-secondary':(type==='personal'?'btn-bg-white':'btn-bg-light')} btn-hover-scale  btn-active-secondary`}>
                    <img src={`https://media.payrequest.nl/images/payment-icons/${paymentMethodIcons['mastercard']}`} className="w-100"
                         alt="Mastercard"/>
                </button>
                {/*<button key={`${methodName}-maestro`} onClick={(e)=>{
                    e.preventDefault()
                    updateMethod(`${methodName}-maestro`, psp)
                }}
                    className={`d-inline-flex flex-column justify-content-center align-items-center btn ${method===`${methodName}-maestro`?'btn-bg-secondary':(type==='personal'?'btn-bg-white':'btn-bg-light')} btn-hover-scale  btn-active-secondary`}>
                    <img src={`https://media.payrequest.nl/images/payment-icons/${paymentMethodIcons['maestro']}`} className="w-100"
                         alt="Maestro"/>
                </button>*/}
                <button key={`${methodName}-amex`} onClick={(e)=>{
                    e.preventDefault()
                    updateMethod(`${methodName}-amex`, psp)
                }}
                    className={`d-inline-flex flex-column justify-content-center align-items-center btn ${method===`${methodName}-amex`?'btn-bg-secondary':(type==='personal'?'btn-bg-white':'btn-bg-light')} btn-hover-scale  btn-active-secondary`}>
                    <img src={`https://media.payrequest.nl/images/payment-icons/${paymentMethodIcons['americanexpress']}`} className="w-100"
                         alt="American Express"/>
                </button>
            </>
        )
    }else {
        return (
            <button key={`${methodName}`} onClick={(e)=>{
                e.preventDefault()
                updateMethod(methodName, psp)
            }}
                    className={`d-inline-flex flex-column justify-content-center align-items-center btn ${method===methodName?'btn-bg-secondary':(type==='personal'?'btn-bg-white':'btn-bg-light')} btn-hover-scale  btn-active-secondary`}>
                <img src={`https://media.payrequest.nl/images/payment-icons/${getIcon(methodName)}`} className="w-100"
                     alt={methodName}/>
            </button>
        )
    }
}

export {Method}