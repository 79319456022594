import React, {FC, useEffect, useState} from "react";
import PhoneInput from "react-phone-number-input";
import {E164Number} from "libphonenumber-js/core";
import TextEditor from "../../../../../_metronic/partials/widgets/texteditor/TextEditor";
import {CustomField} from "../../../../modules/auth";
import {useRequest} from "../../RequestWrapper";

type itemProps = {
    item: CustomField
}

const Telephone: FC<itemProps> = ({item}) => {
    const {setExtraFields} = useRequest();
    const index = item?.id;
    const [phoneNumber, setPhoneNumber] = useState<E164Number|string>();
    useEffect(() => {
        setExtraFields(oldFields=>({...oldFields, [index]: phoneNumber}))
    }, [phoneNumber]);
    return <PhoneInput
        placeholder={item?.general?.placeholder??''}
        value={phoneNumber}
        className="input-group  mb-1"
        numberInputProps={{
            className: "form-control",
            name: `telephone__${item?.id}`
        }}
        // @ts-ignore
        onChange={setPhoneNumber}/>
}

const Text:FC<itemProps> = ({item}) => {

    const {setExtraFields} = useRequest();
    const index = (item?.label?.text ?? item?.name) + ' - ' + item?.id;
    const personalStyling: {[key:string]:any} = {}
    if (item?.field?.background) personalStyling['background'] = item?.field?.background
    if (item?.field?.border) personalStyling['border'] = `${item?.field?.borderSize}px solid ${item?.field?.borderColor}`
    if (item?.field?.borderRadius) personalStyling['borderRadius'] = item?.field?.borderRadius

    switch (item?.name.toLowerCase()) {
        default:
        case "text":
            return <input type="text" className='form-control mb-1' onChange={e=>setExtraFields(oldFields=>({...oldFields,[index]:e?.target?.value}))} placeholder={item?.general?.placeholder??''} required={item?.general?.required??false} />
        case "number":
            return <input type="number" className='form-control mb-1' onChange={e=>setExtraFields(oldFields=>({...oldFields,[index]:e?.target?.value}))} min={item?.field?.min??0} max={item?.field?.max??Infinity} step={item?.field?.step??1} placeholder={item?.general?.placeholder??''} required={item?.general?.required??false} />
        case "email":
            return <input type="email" className='form-control mb-1' onChange={e=>setExtraFields(oldFields=>({...oldFields,[index]:e?.target?.value}))} placeholder={item?.general?.placeholder??''} required={item?.general?.required??false} />
        case "telephone":
            return <Telephone item={item}/>
        case "multi line":
            return <textarea className='form-control mb-1' onChange={e=>setExtraFields(oldFields=>({...oldFields,[index]:e?.target?.value}))} placeholder={item?.general?.placeholder??''} required={item?.general?.required??false}></textarea>
        case "wysiwyg":
            return <TextEditor className='tiptapSmall mb-1' save={(description:string) => setExtraFields(oldFields=>({...oldFields,[index]:description}))} liveSave={false} content={''} />
    }
}

export {Text}