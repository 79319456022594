import {Outlet} from 'react-router-dom'
import {PageDataProvider} from './core'
import {RequestProvider} from "../../app/pages/requests/RequestWrapper";

const IframeLayout = () => {
  document.body.classList.add('iframe-body')
  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid justify-content-center request-page' style={{background:'transparent !important'}}>
        <RequestProvider>
          <Outlet />
        </RequestProvider>
      </div>
    </PageDataProvider>
  )
}

export {IframeLayout}
