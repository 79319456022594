import {Outlet} from 'react-router-dom'
import {ScrollTop} from './components/ScrollTop'
import {PageDataProvider} from './core'
import {DashboardProvider} from "../../app/pages/dashboard/DashboardWrapper";
import {Header} from "./components/Header";

const DashboardLayout = () => {
    return (
    <PageDataProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
            <DashboardProvider>
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                    <div
                        id='kt_header'
                        className={'header align-items-stretch px-10'}>
                        <div className={"d-flex align-items-stretch justify-content-between w-100"}>
                            <Header/>
                        </div>
                    </div>

                    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                        <Outlet />
                    </div>
                </div>
            </DashboardProvider>
        </div>
        <ScrollTop />
    </PageDataProvider>
  )
}

export {DashboardLayout}
