import {mergeAttributes, nodeInputRule} from "@tiptap/core";
import {ReactNodeViewRenderer} from "@tiptap/react";
import ImageResizeComponent from "./ImageResizeComponent";
import Image from '@tiptap/extension-image'
import React, {Component, FC, ReactElement} from "react";

export interface ImageOptions {
    inline: boolean,
    allowBase64: boolean,
    HTMLAttributes: Record<string, any>,
    resizeIcon: FC|Component|ReactElement,
    useFigure: boolean
}
declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        imageResize: {
            setImage: (options: { src: string, alt?: string, title?: string, width?: string|number, height?: string|number, isDraggable?: boolean }) => ReturnType,
        }
    }
}
export const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/
export const ImageResize = Image.extend<ImageOptions>({
    name: "imageResize",
    addOptions() {
        return {
            inline: false,
            allowBase64: false,
            HTMLAttributes: {},
            resizeIcon: <>⊙</>,
            useFigure: false
        }
    },
    addAttributes() {
        return {
            src: {
                default: null,
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },
            width: {
                default: '100%',
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width
                    };
                }
            },
            height: {
                default: 'auto',
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height
                    };
                }
            },
            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            }
        };
    },
    parseHTML() {
        return [
            {
                tag: 'img',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes(this.options.HTMLAttributes,HTMLAttributes)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageResizeComponent, {as: 'span'})
    },
    addInputRules() {
        return [
            nodeInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: match => {
                    const [,, alt, src, title, height, width, isDraggable] = match
                    return { src, alt, title, height, width, isDraggable }
                },
            }),
        ]
    },
})
