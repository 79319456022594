import {useDashboard} from "../../../app/pages/dashboard/DashboardWrapper";
import {Link, useNavigate} from "react-router-dom";
import {DefaultTitle} from "./DefaultTitle";
import {removeNonceLocal, removeViewIdLocal} from "../../../app/pages/dashboard/components/NonceHelper";

const Header = () => {

    const {loggedin} = useDashboard()
    const navigator = useNavigate()

    return <>
        <div className={"d-flex align-items-stretch flex-shrink-0"}>
            <Link  to={'/'} className="logo d-flex align-items-center me-10 pe-10 border-end">
                <img alt="Logo" src="/media/logo/logo.svg" className="logo-default mh-50px w-100"/>
            </Link>
            <div className={"d-flex align-items-center justify-content-start"}>
                <DefaultTitle />
            </div>
        </div>
        {loggedin&&<button className={"btn btn-light-primary my-3"} onClick={e=>{ e.preventDefault(); removeNonceLocal(); removeViewIdLocal(); navigator('/dashboard') }}>
            Logout
        </button>}
        </>
}
export {Header}