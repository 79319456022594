import React, {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Method} from "./Method";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useRequest} from "../RequestWrapper";
import clsx from "clsx";
import {CSSTransition} from "react-transition-group";
import {paymentMethodsByPsp} from "../../../modules/requests/core/_models";
import {StripeLoader} from "../../../integrations/stripe/StripeLoader";
import Skeleton from "react-loading-skeleton";
import {KTSVG} from "../../../../_metronic/helpers";
import {MollieLoader} from "../../../integrations/mollie/MollieLoader";
import {PaypalLoader} from "../../../integrations/paypal/PaypalLoader";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {useAuth} from "../../../modules/auth";
import {MspLoader} from "../../../integrations/msp/MspLoader";

type Props = {
    methods: paymentMethodsByPsp
}

const Pending: FC<Props> = ({methods}) => {
    const {type, step, setStep, method, setMethod, psp, showPrev, showNext, variables, integrations,product} = useRequest()
    const intl = useIntl()
    const { currentUser } = useAuth()

    const [error, setError] = useState<string>();

    useEffect(() => {
        if(typeof method !== "undefined" && typeof psp !== "undefined") {
            setStep(1)
        }
    }, [method]);

    return (
        <div className={clsx(`px-10  rounded-bottom rounded-3`,type==='personal'?'bg-light py-6':'')}>
            <CSSTransition
                in={typeof error !== "undefined"}
                timeout={300}
                classNames="pr__stepper"
                unmountOnExit
            >
                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mb-10">
                    <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
                    <div className="text-gray-700 fw-bold fs-6">{error}</div>
                </div>
            </CSSTransition>
            <CSSTransition
                in={step===0}
                timeout={300}
                classNames="pr__stepper"
                unmountOnExit
            >
                <>
                    <h3 className="fs-6 text-center mb-0">
                        {/*
                    @ts-ignore */}
                        <FontAwesomeIcon icon="fa-duotone fa-money-bill-wave" className='me-3' />
                        {intl.formatMessage({id: 'LINK.CHOOSE', defaultMessage: 'Choose a payment method'})}
                    </h3>
                    {/* show logos in a grid with 3 next to each other*/}
                    <div className="d-grid payment__icons pt-6">
                        {Object.entries(methods).map(([pspKey, _methods]) => Object.values(_methods).map(method=><Method setError={setError} psp={pspKey} methodName={method}/>))}
                    </div>
                </>
            </CSSTransition>
            <CSSTransition
                in={step===1||step===2||step===3}
                timeout={300}
                classNames="pr__stepper"
                unmountOnExit
            >
                <div className='d-flex flex-column w-100'>
                    {typeof psp === "undefined" && <Skeleton height={200} width={'100%'}/>}
                    {psp==='stripe' && <StripeLoader/>}
                    {psp==='mollie' && <MollieLoader/>}
                    {psp==='msp' && <MspLoader/>}
                    {psp==='paypal'&&<PayPalScriptProvider
                        options={{
                            "client-id": process?.env?.REACT_APP_PAYPAL_CLIENT_ID??'test',
                            "merchant-id": integrations?.paypal?.refreshToken,
                            "currency": typeof variables?.currency === "string"?variables?.currency:(currentUser?.currency ?? 'EUR'),
                            "components": "buttons",
                            "vault": (typeof product !=="undefined" && product?.metaValues?.find(x => x.slug === "cycle") && product?.metaValues?.find(x => x.slug === "cycle")?.value !== ""),
                            "intent": (typeof product !=="undefined" && product?.metaValues?.find(x => x.slug === "cycle") && product?.metaValues?.find(x => x.slug === "cycle")?.value !== "")?"subscription":"capture",
                        }}>
                        <PaypalLoader/>
                    </PayPalScriptProvider>}
                    <div className='d-flex flex-row gap-5 align-items-center w-100 mt-5'>
                        <CSSTransition
                            in={showPrev}
                            timeout={300}
                            classNames="pr__stepper"
                            unmountOnExit
                        >
                            <button className="btn btn-sm btn-secondary w-100 pr__prev" onClick={e=>{e.preventDefault();setMethod(undefined);setStep(prevStep=>prevStep-1)}}>
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-arrow-left" className='me-3' />
                                {intl.formatMessage({id: 'LINK.BACK', defaultMessage: 'Back'})}
                            </button>
                        </CSSTransition>
                        <CSSTransition
                            in={showNext}
                            timeout={300}
                            classNames="pr__stepper"
                            unmountOnExit
                        >
                            <button className={`btn btn-sm btn-primary w-100 pr__next`} onClick={e=>{e.preventDefault();
                                setStep(prevStep=>prevStep+1)}}>
                                {intl.formatMessage({id: 'LINK.NEXT', defaultMessage: 'Next'})}
                                {/*
                                @ts-ignore */}
                                <FontAwesomeIcon icon="fa-duotone fa-arrow-right" className='ms-3' />
                            </button>
                        </CSSTransition>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export {Pending}