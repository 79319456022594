import React, {FC} from 'react'
import {PaymentLink} from '../../../modules/requests/core/_models'
import {PersonalLink} from '../../../modules/auth'
import {hexToTextColor} from '../RequestHelpers'
import {InlineShareButtons} from 'sharethis-reactjs'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {languages, useLanguage} from '../../../../_metronic/i18n/Metronici18n'
import Select, {components, DropdownIndicatorProps} from 'react-select'

type Props = {
  linkData: PaymentLink | PersonalLink | undefined
}

const TopButtons: FC<Props> = ({linkData}) => {
  const {selectedLang, setSelectedLang} = useLanguage()
  const DropdownIndicator = (props: DropdownIndicatorProps<true>) => {
    return (
      <components.DropdownIndicator {...props}>
        {/*@ts-ignore */}
        <FontAwesomeIcon //@ts-ignore
          icon='fa-duotone fa-caret-down'
          style={{
            fontSize: '1.2rem',
          }}
        />
      </components.DropdownIndicator>
    )
  }
  if (typeof linkData === 'undefined' || document.body.classList.contains('iframe-body'))
    return <></>
  return (
    <>
      <div
        className={'d-flex position-absolute top-10 start-10 flex-row gap-5'}
        style={{
          ['--color' as any]: `${hexToTextColor(linkData?.user?.background1 ?? '000000')}`,
          ['--background' as any]: `#${linkData?.user?.background1 ?? '000000'}`,
        }}
      >
        <Select
          //@ts-ignore
          defaultValue={''}
          //@ts-ignore
          value={
            languages
              .map((lng) => ({value: lng.lang, label: lng.name}))
              ?.find((lng) => lng.value === selectedLang) ?? ''
          }
          //@ts-ignore
          options={Object.values(
            languages.map((lng) => ({
              value: lng.lang,
              label: (
                <>
                  <img src={lng.flag} style={{width: '30px', height: '12px'}} /> {lng.name}
                </>
              ),
            }))
          )}
          isLoading={false}
          isSearchable={true}
          components={{DropdownIndicator}}
          name='lng_select2'
          onChange={(event) => {
            if (event !== null) {
              // @ts-ignore
              setSelectedLang(event.value)
            }
          }}
          styles={{
            option: (provided) => ({
              ...provided,
            }),
            control: (provided) => ({
              ...provided,
              background: 'var(--background)',
              borderColor: 'transparent !important',
              color: 'var(--color)',
              transition: 'color 0.2s ease, background-color 0.2s ease',
              borderRadius: '0.325rem',
              minHeight: 0,
              padding: '0',
              '&:hover': {
                background: '#181c32 ',
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: 'var(--color)',
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: '0.4rem 1.25rem',
              fontSize: '1rem',
              width: '120px',
              color: 'var(--color)',
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              padding: '0',
            }),
            input: (provided) => ({
              ...provided,
              margin: 0,
              padding: 0,
            }),
          }}
        />
      </div>
      <div
        className='d-flex position-absolute top-10 end-10 flex-row justify-content-end gap-5'
        style={{
          ['--color' as any]: `${hexToTextColor(linkData?.user?.background1 ?? '000000')}`,
          ['--background' as any]: `#${linkData?.user?.background1 ?? '000000'}`,
        }}
      >
        <InlineShareButtons
          config={{
            alignment: 'left',
            color: `white`,
            enabled: true,
            show_total: false,
            font_size: 16,
            labels: 'cta',
            language: 'en',
            networks: ['sharethis'],
            padding: 2,
            radius: 0,
            size: 40,
          }}
        />
        <a
          href={`mailto:${linkData?.user?.companyMail ?? linkData?.user?.email ?? ''}`}
          className='py-2 px-5 flex-grow-1 btn btn-sm btn-custom btn-active-dark'
          style={{
            background: `#${linkData?.user?.background1 ?? '000000'}`,
            color: hexToTextColor(linkData?.user?.background1 ?? '#000000'),
          }}
        >
          {/*
                     @ts-ignore */}
          <FontAwesomeIcon icon='fa-duotone fa-envelope' className='me-2' />
          Contact Seller
        </a>
      </div>
    </>
  )
}

export {TopButtons}
