import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {useIntercom} from "react-use-intercom";
import {useNavigate} from "react-router-dom";

type AuthContextProps = {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  loading: true,
  setLoading: () => {},
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
      setLoading(false)
    }else
      authHelper.removeAuth()
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setLoading(false)
  }

  return (
    <AuthContext.Provider value={{loading, setLoading, auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {setLoading, auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const { boot } = useIntercom()
  const navigate = useNavigate()

  useEffect(() => {
    const requestUser = async (auth: AuthModel) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(auth)
          if (data) {
            setCurrentUser(data)
            setLoading(false)

            if(data.onboarding === 0 && !window.location.pathname.includes('integration/'))
                navigate('/welcome')
            // @ts-ignore
            window.webpushr('fetch_id',sid=>console.log(sid===false?'no webpushr id yet..':`webpushr subscriber id: ${sid}`));
            boot({
              email: data.email,
              userId: data.id.toString(),
              name: data.name,
              customAttributes: {
                psp: data.psp,
                slug: data.slug,
                company_name: data.companyName,
                company_mail: data.companyMail
              }
            })
          }else{
            console.log('logout, no valid response right?')
            logout()
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current)
          logout()
      }
      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser(auth)
    } else {
      logout()
    }
    // eslint-disable-next-line
  }, [])

  return <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
