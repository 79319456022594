/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {createContext, useContext, useMemo, useState, Dispatch, SetStateAction} from 'react'
import {Customers, Products, Subscription} from "../../modules/auth";

interface DashboardContextProps {
    customer: Customers|undefined
    setCustomer: Dispatch<SetStateAction<Customers|undefined>>
    nonce: string|undefined
    setNonce: Dispatch<SetStateAction<string|undefined>>
    cid: string|undefined
    setCid: Dispatch<SetStateAction<string|undefined>>
    loggedin: boolean
    setLoggedin: Dispatch<SetStateAction<boolean>>
    subscription: Subscription|undefined
    setSubscription: Dispatch<SetStateAction<Subscription|undefined>>
    product: Products|undefined
    setProduct: Dispatch<SetStateAction<Products|undefined>>

}

const DashboardContext = createContext<DashboardContextProps>({
    customer: undefined,
    setCustomer: (prevState: SetStateAction<Customers|undefined>) => prevState,
    nonce: undefined,
    setNonce: (prevState: SetStateAction<string|undefined>) => prevState,
    cid: undefined,
    setCid: (prevState: SetStateAction<string|undefined>) => prevState,
    loggedin: false,
    setLoggedin: (prevState: SetStateAction<boolean>) => prevState,
    subscription: undefined,
    setSubscription: (prevState: SetStateAction<Subscription|undefined>) => prevState,
    product: undefined,
    setProduct: (prevState: SetStateAction<Products|undefined>) => prevState,
})

interface DashboardProviderProps {
    children: React.ReactNode
}

function DashboardProvider({ children }: DashboardProviderProps): JSX.Element {
    const [customer, setCustomer] = useState<Customers|undefined>(undefined)
    const [nonce, setNonce] = useState<string|undefined>(undefined)
    const [cid, setCid] = useState<string|undefined>(undefined)
    const [loggedin, setLoggedin] = useState<boolean>(false)
    const [subscription, setSubscription] = useState<Subscription|undefined>(undefined)
    const [product, setProduct] = useState<Products|undefined>(undefined)

    const memo = useMemo(() => ({ customer, setCustomer, nonce, setNonce, cid, setCid, loggedin, setLoggedin, subscription, setSubscription, product, setProduct }),
        [customer, setCustomer, nonce, setNonce, cid, setCid, loggedin, setLoggedin, subscription, setSubscription, product, setProduct])

    return <DashboardContext.Provider value={memo}>{children}</DashboardContext.Provider>
}

const DashboardConsumer = DashboardContext.Consumer

const useDashboard = () => useContext(DashboardContext)

export { DashboardProvider, DashboardConsumer, useDashboard }
