import React, {FC, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {CustomField} from "../../../../modules/auth";
import {useRequest} from "../../RequestWrapper";

type itemProps = {
    item: CustomField
}

const DateTime:FC<itemProps> = ({item}) => {
    const {setExtraFields} = useRequest();
    const index = (item?.label?.text ?? item?.name) + ' - ' + item?.id;
    const [chosenDate, setChosenDate] = useState<Date>(new Date());
    useEffect(() => {
        setExtraFields(oldFields=>({...oldFields, [index]: chosenDate.toUTCString()}))
    }, [chosenDate]);

    switch (item?.name.toLowerCase()) {
        default:
        case "date and time":
            return <DatePicker
                className={'form-control mb-1'}
                selected={chosenDate}
                onChange={(date:Date) => setChosenDate(date)}
                locale={item?.datetime?.locale??'en'}
                showTimeSelect
                timeFormat={item?.datetime?.timeFormat??'p'}
                timeIntervals={item?.datetime?.timeInterval??15}
                dateFormat={item?.datetime?.dateFormat??'Pp'}
            />
        case "date":
            return <DatePicker
                className={'form-control mb-1'}
                selected={chosenDate}
                onChange={(date:Date) => setChosenDate(date)}
                locale={item?.date?.locale??'en'}
                dateFormat={item?.date?.dateFormat??'Pp'}
            />
        case "time":
            return <DatePicker
                className={'form-control mb-1'}
                selected={chosenDate}
                onChange={(date:Date) => setChosenDate(date)}
                locale={item?.date?.locale??'en'}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={item?.time?.timeInterval??15}
                timeCaption={item?.time?.caption??'Time'}
                dateFormat={item?.time?.timeFormat??'h:mm aa'}
            />
    }
}

export {DateTime}