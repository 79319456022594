import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {FC} from "react";

type Props = {
    pro: boolean
}
const Badge: FC<Props> = ({pro}) => {
    if(pro||document.body.classList.contains('iframe-body'))
        return <></>
    return <a href="https://www.payrequest.io/" style={{textDecoration: 'none'}}
              className="secured__badge payrequest__badge">
                <div className="secured__badge__container">
                    <div className="secured__badge__text">
                        <img style={{maxWidth:'100px'}} alt="PayRequest Badge" className="mb-1"
                            src="https://payrequest.io/assets/logos/payrequest-logo-white.png" />
                        <div className="secured__badge__icon fw-normal">
                            {/*
                            @ts-ignore */}
                            <FontAwesomeIcon icon="fa-duotone fa-lock-keyhole" className="me-2" />
                            secure payment
                        </div>
                    </div>
                </div>
            </a>
}

export {Badge}