import React, {FC, useEffect, useState} from "react";
import Select, {components, DropdownIndicatorProps} from "react-select";
import {KTSVG} from "../../../../../_metronic/helpers";
import {CustomField} from "../../../../modules/auth";
import {useRequest} from "../../RequestWrapper";

type itemProps = {
    item: CustomField
}

const Check:FC<itemProps> = ({item}) => {
    const {setExtraFields} = useRequest();
    const index = (item?.label?.text ?? item?.name) + ' - ' + item?.id;
    const [check, setCheck] = useState<boolean>(item?.field?.checked??false);
    useEffect(() => setCheck(item?.field?.checked??false), [item?.field?.checked]);
    return <div className="form-check form-switch form-check-custom form-check-solid">
        <label className="form-check-label me-3" htmlFor={`boolean${index}`}>
            {item?.options?.[0]?.label??'No'}
        </label>
        <input className="form-check-input" type="checkbox" value="1" id={`boolean${index}`}
               checked={check} onChange={()=>{setCheck(!check);setExtraFields(oldFields=>({...oldFields,[index]:check?(item?.options?.[0]?.label??'No'):(item?.options?.[1]?.label??'Yes')}))}}/>
        <label className="form-check-label" htmlFor={`boolean${index}`}>
            {item?.options?.[1]?.label??'Yes'}
        </label>
    </div>
}

const Dropdown:FC<itemProps> = ({item}) => {
    const {setExtraFields} = useRequest();
    const index = (item?.label?.text ?? item?.name) + ' - ' + item?.id;
    const [options, setOptions] = useState<any[]|undefined>(item?.options?.map(option => ({value: option.value, label: option.label})));
    useEffect(() => {
        setOptions(undefined)
        setOptions(item?.options?.map(option => ({value: option.value, label: option.label}))??[])
    }, [item?.options]);
    useEffect(() => {
        if(typeof options==="undefined"&&typeof item?.options === "undefined")
            setOptions([{label: 'Dropdown item 1', value: '1'}, {label: 'Dropdown item 2', value: '2'}])
    }, []);
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };
    return typeof options !== 'undefined'?<Select
        options={options}
        isLoading={false}
        isSearchable={item?.field?.searchable??false}
        components={{ DropdownIndicator }}
        //@ts-ignore
        onChange={event => { setExtraFields(oldFields=>({...oldFields, [index]: event?.value})) }}
        styles={{
            control: (provided) => ({
                ...provided,
                background: '#f5f8fa',
                borderColor: '#f5f8fa',
                color: '#5e6278',
                transition: 'color 0.2s ease, background-color 0.2s ease',
                borderRadius: '0.3rem'
            }),
            valueContainer: (provided) => ({
                ...provided,
                minHeight: 'calc(1.5em + 1rem + 2px)',
                padding: '0.5rem 1rem',
                fontSize: '1.25rem',
            })
        }}
    />:<></>
}

const Checkbox:FC<itemProps> = ({item}) => {
    const {setExtraFields} = useRequest();
    const index = (item?.label?.text ?? item?.name) + ' - ' + item?.id;
    const [fakeOptions, setFakeOptions] = useState<CustomField['options']>(item?.options);
    useEffect(() => {
        setFakeOptions(item?.options)
    }, [item?.options]);

    useEffect(() => {
        if((fakeOptions?.length===0||typeof fakeOptions==="undefined")&&typeof item?.options === "undefined")
            setFakeOptions([{label: 'My Checkbox', value: 'my-checkbox', description:'Example checkbox, please change them in the right screen', active: true}, {label: 'My Second Checkbox', value: 'my-checkbox-2', active: false}])
    }, []);

    return <>
        {fakeOptions?.map((option, i) =>
            <label key={`${index}-checkbox${i}`} className="form-check form-check-custom form-check-solid align-items-start">
                <input className="form-check-input me-3" type="checkbox"
                       defaultChecked={option?.active??false}
                       onClick={e=>{
                           let newFakes = [...fakeOptions];
                           newFakes[i].active = !(option?.active??false);
                           setFakeOptions(newFakes);
                            setExtraFields(oldFields=>({...oldFields, [index]: newFakes[i].value}))
                       }} />
                <span className="form-check-label d-flex flex-column align-items-start">
                <span className="fw-bolder fs-5 mb-0">{option?.label??'My Checkbox'}</span>
                    {typeof option?.description!=="undefined"&&<span className="text-muted fs-7">{option?.description}</span>}
            </span>
            </label>)}
    </>
}

const Radio:FC<itemProps> = ({item}) => {
    const {setExtraFields} = useRequest();
    const index = (item?.label?.text ?? item?.name) + ' - ' + item?.id;
    const [fakeOptions, setFakeOptions] = useState<CustomField['options']>(item?.options);
    useEffect(() => {
        setFakeOptions(item?.options)
    }, [item?.options]);
    useEffect(() => {
        if((fakeOptions?.length===0||typeof fakeOptions==="undefined")&&typeof item?.options === "undefined")
            setFakeOptions([{label: 'My Radio', value: 'my-radio', active: true},{label: 'My Second Radio', value: 'my-second-radio'}])
    }, []);
    return <>
        {fakeOptions?.map((option, i) =><div key={`radiowrapper${i}`}>
            <input
                type='radio'
                className='btn-check'
                name={`radio${index}`}
                value={option?.value??''}
                id={`radio${index}`}
                onChange={(e) => {
                    let newFakes = [...fakeOptions];
                    newFakes.map(fake => ({...fake, active: false}));
                    newFakes[i].active = true;
                    setFakeOptions(newFakes);
                    setExtraFields(oldFields=>({...oldFields, [index]: newFakes[i].value}))
                }}
                defaultChecked={option?.active??false}
            />
            <label
                className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-90px ${
                    (option?.active ?? false) ? 'active' : ''
                }`}
                htmlFor={`radio${index}`}
            >
                <span className='text-gray-800 fw-bold'>{option?.label}</span>
            </label>
            </div>)}
    </>
}

const Multi:FC<itemProps> = ({item}) => {
    switch (item?.name.toLowerCase()) {
        default:
        case "yes/no":
            return <Check item={item} />
        case "dropdown":
            return <Dropdown item={item} />
        case "checkbox":
            return <Checkbox item={item}/>
        case "radiobutton":
            return <Radio item={item} />
    }
}

export {Multi}