import axios from 'axios'
import {PaypalButtonResponse, RedirectResponse, StatusResponse, SuccessResponse} from "../../stripe/core/_models";
import {ErrorMessage} from "../../../modules/requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATIONS_GET = `${API_URL}/api/integrations`
export const PAYPAL_CREATE = `${API_URL}/api/paypal`
export const PAYPAL_VERIFY = `${API_URL}/api/paypal/verify`

export function getPaypalLink(): Promise<string|boolean> {
    return axios.get<any>(`${INTEGRATIONS_GET}/paypal`)
        .then(response => response.data?.action_url ?? false)
        .catch(err => false)
}

export function checkIntegrationPaypal(data:object): Promise<StatusResponse|ErrorMessage> {
    return axios.post(
        `${INTEGRATIONS_GET}/paypal/check`,
        data).then(response => response.data as unknown as StatusResponse)
        .catch(err => ({success:false, error: "Unknown error"}))
}

export function createPayment(data:object): Promise<PaypalButtonResponse|ErrorMessage> {
    return axios.post(
        `${PAYPAL_CREATE}`,
        data).then(response => response.data as unknown as PaypalButtonResponse)
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}

export function verifyPayment(data:object): Promise<SuccessResponse|RedirectResponse|ErrorMessage> {
    return axios.post(
        `${PAYPAL_VERIFY}`,
            data).then(response => {
            if("redirect" in response.data)
                return response.data as unknown as RedirectResponse
            else
                return response.data as unknown as SuccessResponse
        }).catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}