import React, {FC, useEffect, useMemo, useState} from "react";
import {useRequest} from "../../pages/requests/RequestWrapper";

import {KTSVG} from "../../../_metronic/helpers";
import {PaymentLinkTypes} from "../../modules/auth";
import {useIntl} from "react-intl";
import Select from "react-select";
import countryList from "react-select-country-list";
import {PayPalButtons} from "@paypal/react-paypal-js";
import {createPayment, verifyPayment} from "./core/_requests";

/*
* We have multiple steps..
* 1. Show bank choice, card or just a continue button
* 2. Handle in app payment or redirect to external payment
* 3. Return to our app or JS response, handle errors or success
* 4. Show verification
*/

type PaypalData = {[index: string]:any}

const PaypalLoader: FC = () => {
    const intl = useIntl()
    const {amount, type, step,setStep, method,extraFields, variables, setVariables, setShowNext, setShowPrev, product} = useRequest()
    const [error, setError] = useState<string>();
    const [failed, setFailed] = useState<string>();
    const [sending, setSending] = useState(false);
    const countryOptions = useMemo(() => countryList().getData(), [])
    const [errors, setErrors] = useState<{[index:string]:string}|undefined>(undefined);
    const [showStep2, setShowStep2] = useState(false);
    const [data, setData] = useState<PaypalData>({});
    useEffect(() => {
        if(step===2) {
            setFailed(undefined)
            setErrors(undefined)
            setShowStep2(true)

            if(typeof variables?.shipping !== "undefined" && variables?.shipping) {
                if (typeof variables?.street === "undefined")
                    setErrors(oldErrors => ({
                        ...(typeof oldErrors === "undefined" ? ({}) : oldErrors),
                        street: intl.formatMessage({
                            id: "ERRORS.STREET",
                            defaultMessage: "Please enter your street and number"
                        })
                    }))

                if (typeof variables?.postal === "undefined")
                    setErrors(oldErrors => ({
                        ...(typeof oldErrors === "undefined" ? ({}) : oldErrors),
                        postal: intl.formatMessage({
                            id: "ERRORS.POSTAL",
                            defaultMessage: "Please enter your postalcode"
                        })
                    }))
                if (typeof variables?.city === "undefined")
                    setErrors(oldErrors => ({
                        ...(typeof oldErrors === "undefined" ? ({}) : oldErrors),
                        city: intl.formatMessage({id: "ERRORS.CITY", defaultMessage: "Please enter your city"})
                    }))
                if (typeof variables?.country === "undefined")
                    setErrors(oldErrors => ({
                        ...(typeof oldErrors === "undefined" ? ({}) : oldErrors),
                        country: intl.formatMessage({id: "ERRORS.COUNTRY", defaultMessage: "Please select a country"})
                    }))
            }
            if (typeof errors !== "undefined") {
                setFailed(undefined)
                /*setSending(false)*/
            }else {
                const splitName = (typeof variables?.name === 'string' ? variables?.name : '').split(' ')
                if(splitName.length<2) {
                    setFailed(undefined)
                    setError("Please enter your first and last name")
                    setShowNext(false)
                    setShowPrev(true)
                    setSending(false)
                    return
                }
                setShowNext(false)
                setShowPrev(true)
                setData({
                    id: typeof product === "undefined" ? variables.linkid : variables?.personalId,
                    currency: variables.currency,
                    type,
                    name: {
                        given_name: splitName.shift(),
                        surname: splitName.join(' ')
                    },
                    email_address: variables?.mail,
                    note: variables?.note,
                    method: method?.startsWith('creditcard') ? method.split('-')[0] : method,
                })
                if(typeof variables?.street === "string" && variables?.street.length>0){
                    setData(oldData=>({...oldData, address: {
                            address_line_1: variables?.street,
                            postal_code: variables?.postal,
                            admin_area_2: variables?.city,
                            country_code: variables?.country
                        }}))
                }
                if (typeof product !== "undefined") {
                    if (product?.metaValues?.find(x => x.slug === "cycle") && product?.metaValues?.find(x => x.slug === "cycle")?.value !== "")
                        setData(oldData=>({...oldData, subscription: product?.id ?? '0'}))
                    else
                        setData(oldData=>({...oldData, onetime: product?.id ?? '0'}))
                } else {
                    setData(oldData => ({
                        ...oldData,
                        amount: typeof amount === 'undefined' ? 100 : parseFloat(typeof amount === "string"?amount.replace('custom-', ''):amount),
                    }))
                }
                if(typeof extraFields !== "undefined" && Array.isArray(extraFields) && extraFields.length > 0)
                    setData(oldData=>({...oldData, paypalMetadata: extraFields.map(x => ['number','string','E164Number','boolean'].includes(typeof x)?x:JSON.stringify(x))}))
                else if(typeof extraFields !== "undefined" && extraFields)
                    setData(oldData=>({...oldData, paypalMetadata: extraFields}))
            }
        }else if(typeof variables?.shipping == "undefined" || !variables?.shipping){
            setStep(2)
        }
    }, [step]);

    useEffect(() => {
        setShowNext(true)
    }, []);


    if(typeof error !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{error}</div>
        </div>
    }
    if(typeof failed !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning">
            <KTSVG path="/media/icons/duotune/general/gen045.svg" className="svg-icon-3x svg-icon-warning me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{failed}</div>
        </div>
    }
    if(sending) {
        return <div className="d-flex flex-column w-100 py-15">
            <h3 data-kt-indicator="on"
                className="d-flex card-title align-items-center flex-column justify-content-center mb-3">
        <span className="indicator-label">
            {intl.formatMessage({id: 'GENERAL.LOADINGMOLLIE', defaultMessage: 'Creating payment...'})}
        </span>
                <span className="card-label fw-bolder fs-3 indicator-progress text-center">
            {intl.formatMessage({id: 'GENERAL.LOADINGMOLLIE', defaultMessage: 'Creating payment...'})}<br/>
            <span className="spinner-border align-middle mt-10"></span>
        </span>
            </h3>
        </div>
    }
    //streetAndNumber, postalCode, city, country
    return showStep2?<div
        className="d-flex justify-content-center flex-column px-10 pt-0 pb-6 align-items-center border-gray-200 border border-start-0 border-top-0 border-end-0 gap-6 mt-0">
        {(typeof product !=="undefined" && product?.metaValues?.find(x => x.slug === "cycle") && product?.metaValues?.find(x => x.slug === "cycle")?.value !== "")?
            <PayPalButtons
                createSubscription={() => {
                    setSending(false)
                    setFailed(undefined)
                    setErrors(undefined)
                    return createPayment(data).then(res => {
                        if ("error" in res) {
                            setSending(false)
                            setShowNext(false)
                            setShowPrev(true)
                            setFailed(undefined)
                            setError(res.error)
                            return ''
                        }
                        return typeof res?.id === "string" ? res?.id : ''
                    })
                }}
                onError={(err) => {
                    setSending(false)
                    setShowNext(false)
                    setShowPrev(true)
                    setFailed(undefined)
                    setError(err.toString())
                }}
                /* @ts-ignore */
                onApprove={(approvedData, actions) => {
                    if ((typeof product !== "undefined" && product?.type?.id === 3) || variables?.type === PaymentLinkTypes.PREAUTH) {
                        const authorize = actions?.order?.authorize()
                        if (typeof authorize !== "undefined") {
                            authorize.then(authorization => {
                                const authId = authorization?.purchase_units?.[0]?.payments?.authorizations?.[0]?.id;
                                if (authId) {
                                    return verifyPayment({
                                        order: approvedData,
                                        data: data,
                                        authorization: authId
                                    }).then(res => {
                                        if ("error" in res) {
                                            if (res.error === 'INSTRUMENT_DECLINED') {
                                                actions.restart()
                                            } else {
                                                setSending(false)
                                                setShowNext(false)
                                                setShowPrev(true)
                                                setFailed(undefined)
                                                setError(res.error)
                                            }
                                        } else
                                            setVariables(oldVars => ({
                                                ...oldVars,
                                                page: 'verification',
                                                paymentStatus: res?.status
                                            }))
                                    }).catch(err => {
                                        setSending(false)
                                        setShowNext(false)
                                        setShowPrev(true)
                                        setFailed(undefined)
                                        setError(err.toString())
                                    })
                                }
                            }).catch(err => {
                                setSending(false)
                                setShowNext(false)
                                setShowPrev(true)
                                setFailed(undefined)
                                setError(err.toString())
                            })
                        }
                    } else {
                        return verifyPayment({order: approvedData, data: data}).then(res => {
                            if ("error" in res) {
                                if (res.error === 'INSTRUMENT_DECLINED') {
                                    actions.restart()
                                } else {
                                    setSending(false)
                                    setShowNext(false)
                                    setShowPrev(true)
                                    setFailed(undefined)
                                    setError(res.error)
                                }
                            } else
                                setVariables(oldVars => ({
                                    ...oldVars,
                                    page: 'verification',
                                    paymentStatus: res?.status
                                }))
                        }).catch(err => {
                            setSending(false)
                            setShowNext(false)
                            setShowPrev(true)
                            setFailed(undefined)
                            setError(err.toString())
                        })
                    }
                }}
            />:
            <PayPalButtons
            createOrder={() => {
                setSending(false)
                setFailed(undefined)
                setErrors(undefined)
                return createPayment(data).then(res => {
                    if ("error" in res) {
                        setSending(false)
                        setShowNext(false)
                        setShowPrev(true)
                        setFailed(undefined)
                        setError(res.error)
                        return ''
                    }
                    return typeof res?.id === "string" ? res?.id : ''
                })
            }}
            onError={(err) => {
                setSending(false)
                setShowNext(false)
                setShowPrev(true)
                setFailed(undefined)
                setError(err.toString())
            }}
            /*onCancel={() => {
            }}*/
            /* @ts-ignore */
            onApprove={(approvedData, actions) => {
                if ((typeof product !== "undefined" && product?.type?.id === 3) || variables?.type === PaymentLinkTypes.PREAUTH) {
                    const authorize = actions?.order?.authorize()
                    if (typeof authorize !== "undefined") {
                        authorize.then(authorization => {
                            const authId = authorization?.purchase_units?.[0]?.payments?.authorizations?.[0]?.id;
                            if (authId) {
                                return verifyPayment({
                                    order: approvedData,
                                    data: data,
                                    authorization: authId
                                }).then(res => {
                                    if ("error" in res) {
                                        if (res.error === 'INSTRUMENT_DECLINED') {
                                            actions.restart()
                                        } else {
                                            setSending(false)
                                            setShowNext(false)
                                            setShowPrev(true)
                                            setFailed(undefined)
                                            setError(res.error)
                                        }
                                    } else
                                        setVariables(oldVars => ({
                                            ...oldVars,
                                            page: 'verification',
                                            paymentStatus: res?.status
                                        }))
                                }).catch(err => {
                                    setSending(false)
                                    setShowNext(false)
                                    setShowPrev(true)
                                    setFailed(undefined)
                                    setError(err.toString())
                                })
                            }
                        }).catch(err => {
                            setSending(false)
                            setShowNext(false)
                            setShowPrev(true)
                            setFailed(undefined)
                            setError(err.toString())
                        })
                    }
                } else {
                    return verifyPayment({order: approvedData, data: data}).then(res => {
                        if ("error" in res) {
                            if (res.error === 'INSTRUMENT_DECLINED') {
                                actions.restart()
                            } else {
                                setSending(false)
                                setShowNext(false)
                                setShowPrev(true)
                                setFailed(undefined)
                                setError(res.error)
                            }
                        } else
                            setVariables(oldVars => ({
                                ...oldVars,
                                page: 'verification',
                                paymentStatus: res?.status
                            }))
                    }).catch(err => {
                        setSending(false)
                        setShowNext(false)
                        setShowPrev(true)
                        setFailed(undefined)
                        setError(err.toString())
                    })
                }
            }}
        />}
    </div>:
        <div className="d-flex justify-content-between flex-column px-10 pt-0 pb-6 align-items-center border-gray-200 border border-start-0 border-top-0 border-end-0 gap-6 mt-0 pr__customer_fields">
            {(typeof variables?.shipping !== "undefined" && variables?.shipping)&&<>
                {/*
             @ts-ignore */}
                <input type="text" className="form-control shadow-sm fw-normal fs-7" value={variables?.street??''} onChange={e=>setVariables({...variables,street:e?.target?.value})} placeholder="Street and number" />
                {typeof errors?.street !== "undefined"&&<div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mt-5 mb-10">
                    <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
                    <div className="text-gray-700 fw-bold fs-6">{errors?.street}</div>
                </div>}
                {/*
             @ts-ignore */}
                <input type="text" className="form-control shadow-sm fw-normal fs-7" value={variables?.postal??''} onChange={e=>setVariables({...variables,postal:e?.target?.value})} placeholder="Postalcode" />
                {typeof errors?.postal !== "undefined"&&<div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mt-5 mb-10">
                    <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
                    <div className="text-gray-700 fw-bold fs-6">{errors?.postal}</div>
                </div>}
                {/*
             @ts-ignore */}
                <input type="text" className="form-control shadow-sm fw-normal fs-7" value={variables?.city??''} onChange={e=>setVariables({...variables,city:e?.target?.value})} placeholder="City" />
                {typeof errors?.city !== "undefined"&&<div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mt-5 mb-10">
                    <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
                    <div className="text-gray-700 fw-bold fs-6">{errors?.city}</div>
                </div>}

                <Select
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            background: '#ffffff',
                            padding: '0.75rem 1rem',
                            fontSize: '1.1rem',
                            color: '#3f4254',
                            width: '100%',
                            border: '1px solid var(--btn-background1) !important'
                        })
                    }}
                    // @ts-ignore
                    options={countryOptions}
                    defaultValue={countryOptions.find(_country => _country.value === (typeof variables?.country === "string"?variables?.country:'').toUpperCase())??undefined}
                    placeholder={intl.formatMessage({id: 'GENERAL.SELECTCOUNTRY', defaultMessage: 'Select country'})}
                    onChange={value => setVariables({...variables,country:value?.value??''})}
                />
                {typeof errors?.country !== "undefined"&&<div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mt-5 mb-10">
                    <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
                    <div className="text-gray-700 fw-bold fs-6">{errors?.country}</div>
                </div>}
            </>}
        </div>
}

export {PaypalLoader}