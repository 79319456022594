export const paymentMethodIcons: {[index: string]:any} = {
    "visa": "Visa-Logo.png",
    "mastercard": "mastercard-logo.png",
    "maestro": "Maestro_2016.svg",
    "americanexpress": "amex.svg",
    "ideal": "ideal-logo.svg",
    "bancontact": "bancontact-new.svg",
    "paypal": "paypal-new.svg",
    "eps": "eps-new.svg",
    "giropay": "giropay-logo.png",
    "kbc": "kbc-new.png",
    "p24": "p24-new.svg",
    "przelewy24": "p24-new.svg",
    "sofort": "sofort-new.svg",
    "multibanco": "mb-new.svg",
    "applepay": "apple-pay-new.svg",
    "alipay":"alipay-new.svg",
    "trustly": "trustly-new.svg",
    "klarna": "klarna-new.svg",
    "wechat": "wechat-pay-logo.png",
    "sepa_debit": "sepa-new.svg",
    "banktransfer": "sepa-new.svg"
};