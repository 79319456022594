/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, Fragment} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";

interface IIcon {
    currency: string|undefined|null
}

const currencyOptions = {
    'EUR': {
        'unicode': '&euro;',
        'sign': '€',
        'icon': 'euro',
        'text': 'euro',
        'plural': 'euros'
    },
    'USD': {
        'unicode': '&dollar;',
        'sign': '$',
        'icon': 'dollar',
        'text': 'dollar',
        'plural': 'dollars'
    },
    'JPY': {
        'unicode': '&yen;',
        'sign': '¥',
        'icon': 'yen',
        'text': 'yen',
        'plural': 'yen',
        'zeroCurrency': true
    },
    'DKK': {
        'unicode': 'DKK',
        'sign': 'kr',
        'preicon': '<i>KR</i>',
        'icon': null,
        'text': 'Danish krone',
        'plural': 'Danish krone'
    },
    'TRY': {
        'unicode': 'TRY',
        'sign': '₺',
        'icon': 'turkish-lira',
        'text': 'The Turkish lira',
        'plural': 'The Turkish lira'
    },
    'ZAR': {
        'unicode': 'R',
        'sign': 'R',
        'icon': 'rupee',
        'text': 'South African rand',
        'plural': 'South African rand'
    },
    'GBP': {
        'unicode': '&pound;',
        'sign': '£',
        'icon': 'sterling',
        'text': 'pound',
        'plural': 'pounds'
    },
    'CHF': {
        'unicode': 'Fr',
        'sign': 'Fr',
        'icon': 'franc',
        'text': 'frank',
        'plural': 'franken'
    },
    'CAD': {
        'unicode': 'C&dollar;',
        'sign': 'C$',
        'preicon': '<i>C</i>',
        'icon': 'dollar',
        'text': 'canadian dollar',
        'plural': 'canadian dollars'
    },
    'AUD': {
        'unicode': 'A&dollar;',
        'sign': 'A$',
        'preicon': '<i>A</i>',
        'icon': 'dollar',
        'text': 'australian dollar',
        'plural': 'australian dollars'
    },
    'NZD': {
        'unicode': 'NZ&dollar;',
        'sign': 'NZ$',
        'preicon': '<i>NZ</i>',
        'icon': 'dollar',
        'text': 'new zealand dollar',
        'plural': 'new zealand dollars'
    },
    'BND': {
        'unicode': 'BN&dollar;',
        'sign': 'BN$',
        'preicon': '<i>BN</i>',
        'icon': 'dollar',
        'text': 'brunei dollar',
        'plural': 'brunei dollars'
    },
    'SEK': {
        'unicode': 'kr',
        'sign': 'kr',
        'preicon': '<i>kr</i>',
        'icon': null,
        'text': 'svensk krona',
        'plural': 'svensk kronar'
    },
    'RON': {
        'unicode': 'lei',
        'sign': 'lei',
        'preicon': '<i>lei</i>',
        'icon': null,
        'text': 'Romanian leu',
        'plural': 'Romanian lei'
    },
    'AED': {
        'unicode': '&#1583;.&#1573;',
        'sign': 'د.إ',
        'preicon': '<i>د.إ</i>',
        'icon': null,
        'text': 'Emirati dirham',
        'plural': 'Emirati dirham'
    },
    'AFN': {
        'unicode': '&#1547;',
        'sign': 'Af',
        'preicon': '<i>؋</i>',
        'icon': null,
        'text': 'Afghani',
        'plural': 'Afghanis',
        'zeroCurrency': true
    },
    'ALL': {
        'unicode': 'ALL',
        'sign': 'ALL',
        'preicon': '<i>ALL</i>',
        'icon': null,
        'text': 'Lek',
        'plural': 'Lek'
    },
    'AMD': {
        'unicode': 'AMD',
        'sign': 'AMD',
        'preicon': '<i>AMD</i>',
        'icon': null,
        'text': 'Dram',
        'plural': 'Dram'
    },
    'ANG': {
        'unicode': '&fnof;',
        'sign': 'ƒ',
        'icon': 'florin',
        'text': 'Netherlands Antillean Guilder',
        'plural': 'Netherlands Antillean Guilder'
    },
    'AOA': {
        'unicode': 'AOA',
        'sign': 'AOA',
        'preicon': '<i>AOA</i>',
        'icon': null,
        'text': 'Kwanza',
        'plural': 'Kwanza',
        'zeroCurrency': true
    },
    'HKD': {
        'unicode': 'HKD',
        'sign': 'HKD',
        'preicon': '<i>HKD</i>',
        'icon': null,
        'text': 'Hong Kong Dollar',
        'plural': 'Hong Kong Dollar',
        'zeroCurrency': true
    },
    'ARS': {
        'unicode': 'AR&dollar;',
        'sign': 'AR$',
        'preicon': '<i>AR</i>',
        'icon': 'dollar',
        'text': 'Argentine Peso',
        'plural': 'Argentine Peso',
        'zeroCurrency': true
    },
    'AWG': {
        'unicode': '&#402;',
        'sign': 'ƒ',
        'icon': 'florin',
        'text': 'Florin',
        'plural': 'Florin'
    },
    'AZN': {
        'unicode': '&#1084;&#1072;&#1085;',
        'sign': 'ман',
        'icon': 'manat',
        'text': 'Manat',
        'plural': 'Manat'
    },
    'BAM': {
        'unicode': '&#75;&#77;',
        'sign': 'KM',
        'preicon': '<i>KM</i>',
        'icon': null,
        'text': 'Convertible Mark',
        'plural': 'Convertible Mark'
    },
    'BBD': {
        'unicode': 'Bds&dollar;',
        'sign': 'Bds$',
        'preicon': '<i>Bds</i>',
        'icon': 'dollar',
        'text': 'Barbadian Dollar',
        'plural': 'Barbadian Dollar'
    },
    'BDT': {
        'unicode': '&#2547;',
        'sign': '৳',
        'icon': 'bangladeshi-taka',
        'text': 'Taka',
        'plural': 'Taka'
    },
    'BGN': {
        'unicode': '&#1083;&#1074;',
        'sign': 'лв',
        'preicon': '<i>лв</i>',
        'icon': null,
        'text': 'Bulgarian Lev',
        'plural': 'Bulgarian Lev'
    },
    'BIF': {
        'unicode': 'FBu',
        'sign': 'FBu',
        'preicon': '<i>FBu</i>',
        'icon': null,
        'text': 'Burundi Franc',
        'plural': 'Burundi Franc'
    },
    'BMD': {
        'unicode': 'Bd&dollar;',
        'sign': 'Bd$',
        'preicon': '<i>Bd</i>',
        'icon': 'dollar',
        'text': 'Bermudian Dollar',
        'plural': 'Bermudian Dollar'
    },
    'BOB': {
        'unicode': '&dollar;b',
        'sign': '$b',
        'icon': 'dollar',
        'subicon': '<i>B</i>',
        'text': 'Boliviano',
        'plural': 'Boliviano'
    },
    'BRL': {
        'unicode': 'R&dollar;',
        'sign': 'R$',
        'icon': '<i class="fa-solid fa-brazilian-real-sign"></i>',
        'text': 'Real',
        'plural': 'Real'
    },
    'BSD': {
        'unicode': 'B&dollar;',
        'sign': 'B$',
        'preicon': '<i>B</i>',
        'icon': 'dollar',
        'text': 'Bahamian Dollar',
        'plural': 'Bahamian Dollar'
    },
    'INR': {
        'unicode': '&#8377;',
        'sign': '₹',
        'icon': 'indian-rupee',
        'text': 'Indian Rupee',
        'plural': 'Indian Rupees'
    },
    'PKR': {
        'unicode': 'Rs.',
        'sign': 'Rs.',
        'preicon': '<i>Rs.</i>',
        'icon': null,
        'text': 'Pakistani Rupee',
        'plural': 'Pakistani Rupees'
    },
    'ILS': {
        'unicode': '&#8362;',
        'sign': '₪',
        'preicon': '<i>₪</i>',
        'icon': null,
        'text': 'Israeli Shekel',
        'plural': 'Israeli Shekels'
    },
}

const CurrencyIcon: FC<IIcon> = ({currency}) => {
    const curr = currency?.toUpperCase() ?? 'USD';
    const icon = currencyOptions[curr as keyof typeof currencyOptions];
    if(typeof currency === "undefined")
        return <></>;
    return (<>
        {'preicon' in icon?(<span dangerouslySetInnerHTML={{__html: icon.preicon}}></span>):("")}
        {icon.icon!==null?(<>
            {/*
            // @ts-ignore */}
            <FontAwesomeIcon icon={clsx(
                'fa-duotone',
                'fa-' + icon.icon + '-sign'
            )}/>
        </>):("")}
        {'subicon' in icon?(<span dangerouslySetInnerHTML={{__html: icon.subicon}}></span>):("")}
    </>);
}

export {currencyOptions,CurrencyIcon};
