import {Route, Routes} from 'react-router-dom'
import {RequestNotFound} from "../pages/requests/RequestNotFound";
import {DashboardLayout} from "../../_metronic/layout/DashboardLayout";
import { Login } from '../pages/dashboard/Login';
import {Overview} from "../pages/dashboard/Overview";
import {View} from "../pages/dashboard/View";

const DashboardRoutes = () => {
    return (
        <Routes>
            <Route element={<DashboardLayout />}>
                <Route index element={<Login />} />
                <Route path='overview' element={<Overview />}/>
                <Route path=':sid' element={<View />}/>
                <Route path=':viewid/:nid' element={<Overview />}/>
                {/*<Route path='' element={<Overview />} />
                <Route path=':sid' element={<SubscriptionView />} />*/}
            </Route>
            <Route path='not-found' element={<RequestNotFound />} />
        </Routes>
    )
}
export {DashboardRoutes}
