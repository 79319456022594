import React, {FC, useEffect, useState} from "react";
import {useRequest} from "../../pages/requests/RequestWrapper";
import {Ideal} from "./components/Ideal";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {createIntent} from "./core/_requests";
import {KTSVG} from "../../../_metronic/helpers";
import {Bancontact} from "./components/Bancontact";
import {Eps} from "./components/Eps";
import {Giropay} from "./components/Giropay";
import {P24} from "./components/P24";
import {Sofort} from "./components/Sofort";
import {Creditcard} from "./components/Creditcard";
import {SepaDebit} from "./components/SepaDebit";

/*
* We have multiple steps..
* 1. Show bank choice, card or just a continue button
* 2. Handle in app payment or redirect to external payment
* 3. Return to our app or JS response, handle errors or success
* 4. Show verification
*/

const StripeLoader: FC = () => {
    const {amount, type, method, integrations,extraFields, variables, setVariables, setShowNext, setShowPrev, product} = useRequest()
    const testmode = typeof variables?.testmode !== "undefined" ? variables.testmode : false
    const stripePromise = loadStripe(testmode?'pk_test_WowwYcQ8iIZYLCKyGwNmgbII00xW5GRSm3':'pk_live_fFvemYNB33ION8m2jwJGOe1V00tGie5yKv',{stripeAccount: integrations?.stripe?.profileId})
    const [error, setError] = useState<string>();
    const [failed, setFailed] = useState<string>();

    useEffect(() => {

        let data:{[index: string]:any} = {
            id: variables.linkid,
            currency: variables.currency,
            type,
            billing_details: {
                name: variables?.name,
                email: variables?.mail,
                note: variables?.note
            },
            method: method?.startsWith('creditcard')?method.split('-')[0]:method,
            amount: typeof amount === "undefined"?100:(typeof amount !== "string"?amount:parseFloat(amount.replace('custom-',''))) * 100,
        };
        if (typeof product !== "undefined") {
            if (product?.metaValues?.find(x => x.slug === "cycle") && product?.metaValues?.find(x => x.slug === "cycle")?.value !== "")
                data = {...data, subscription: product?.id ?? '0'}
            else
                data = {...data, onetime: product?.id ?? '0'}
        }
        if(typeof extraFields !== "undefined" && Array.isArray(extraFields) && extraFields.length > 0)
            data = {...data, metadata: extraFields.map(x => ['number','string','E164Number','boolean'].includes(typeof x)?x:JSON.stringify(x))}
        else if(typeof extraFields !== "undefined" && extraFields)
            data = {...data, metadata: extraFields}
        createIntent(data).then(resp => {
            if("error" in resp){
                setShowNext(false)
                setShowPrev(true)
                setError(resp.error)
                return;
            }
            setShowNext(true)
            setError(undefined)
            setVariables(oldVars=>({...oldVars, client_secret: resp.client_secret}))
        })
    }, [variables?.page]);

    if(typeof error !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{error}</div>
        </div>
    }
    if(typeof failed !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning">
            <KTSVG path="/media/icons/duotune/general/gen045.svg" className="svg-icon-3x svg-icon-warning me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{failed}</div>
        </div>
    }

    switch (method?.startsWith('creditcard')?method.split('-')[0]:method) {
        case "ideal":
            return <Elements stripe={stripePromise}><Ideal setError={setError} /></Elements>
        case "eps":
            return <Elements stripe={stripePromise}><Eps setError={setError} /></Elements>
        case "bancontact":
            return <Elements stripe={stripePromise}><Bancontact setError={setError} /></Elements>
        case "giropay":
            return <Elements stripe={stripePromise}><Giropay setError={setError} /></Elements>
        case "p24":
            return <Elements stripe={stripePromise}><P24 setError={setError} /></Elements>
        case "sofort":
            return <Elements stripe={stripePromise}><Sofort setError={setError} /></Elements>
        case "creditcard":
            return <Elements stripe={stripePromise}><Creditcard setError={setError} /></Elements>
        case "sepa-debit":
            return <Elements stripe={stripePromise}><SepaDebit setError={setError} /></Elements>
        default:
            return <>Missing Method!</>
    }
}

export {StripeLoader}