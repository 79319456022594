import axios from 'axios'
import {
  AuthModel,
  Currency,
  ErrorMessage,
  ISearchResults,
  managementCheck,
  Products,
  StatusMessage,
  Transactions,
  UserModel
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const REFRESH_TOKEN = `${API_URL}/token/refresh`
export const LOGIN_URL = `${API_URL}/magic-link`
export const VALIDATE_URL = `${API_URL}/validate`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const UPDATE_URL = `${API_URL}/update/user`
export const USER_TESTMODE = `${API_URL}/dashboard/settings/settestmode`
export const SEARCH_API = `${API_URL}/api/search`
export const CURRENCY_LIST = `${API_URL}/api/currencylist`
export const SUB_LOGIN = `${API_URL}/management/login`
export const SUB_CHECK = `${API_URL}/management/check`
export const SUBSCRIPTION_API = `${API_URL}/management`
export const UPLOAD_URLS = {
  logo: `${API_URL}/api/logoupload`,
  background: `${API_URL}/api/backgroundupload`,
  email: `${API_URL}/api/emailupload`,
  content: `${API_URL}/api/contentupload`
}
export const DESCRIPTION_SAVE = `${API_URL}/api/savedescription`

export function login(email: string) {
  return axios.post(SUB_LOGIN, {
    email
  }).then(response=>response.data)
      .catch(err=>({status:'error', error: err?.response?.data?.error}))
}

export function check(nonce: string, cid: string): Promise<managementCheck|ErrorMessage> {
  return axios.post(SUB_CHECK, {nonce,cid}).then(response=>response.data as unknown as managementCheck)
      .catch(err=>({status:'error', error: err?.response?.data?.error}) as ErrorMessage)
}

export function currencyList() {
  return axios.get<Array<Currency>>(CURRENCY_LIST).then(response => response).catch(err => false)
}

export function refreshToken(auth: AuthModel){
  return axios.post<AuthModel>(REFRESH_TOKEN, {
    refresh_token: auth.refresh_token
  }).then(response => response).catch(err => false);
}

export function getUserByToken(auth: AuthModel) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {  })
}

export function updateTestmode(testmode: boolean) {
  return axios.post(USER_TESTMODE,{ testmode: testmode }).then(response => response).catch(err => false)
}

export function updateUserField(field: string, value: string) {
  return axios.post(UPDATE_URL,{ field,value }).then(response => response).catch(err => false)
}

export function sendSearch(search: string, preferences: string[]){
  return axios.post<ISearchResults>(SEARCH_API, {
    search: search,
    preferences: JSON.stringify(preferences)
  }).then(response => typeof response !== "boolean" && typeof response.data !== "boolean"?response.data:false)
      .then(data => {
        if(data!==false) {
          data.transactions.map(transaction => {
            transaction.createdAt = new Date(transaction.createdAt);
            return transaction;
          });
        }
        return data
      }).catch(err => false);
}

export function fileUpload(url: string, filename: string, token: string, chunck: string, type: string) {
  /*let formData = new FormData()
  formData.append("file", new Blob([chunck], {type: type}), filename)
  formData.append("imageName", filename)
  formData.append("token", token)*/
  return axios.post(url, chunck, {
    params: {
      token: token,
      imageName: filename
    },
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(response => response?.data).catch(err => false)
}

export function saveDescription(description:string) {
  return axios.post(UPDATE_URL, {
    field: "description", value: description
  }).then(response => response).catch(err => false)
}
export function getSubscriptionProduct(id:string): Promise<Products | ErrorMessage> {
  return axios.get(`${SUBSCRIPTION_API}/${id}/product`)
      .then(response => response.data as unknown as Products)
      .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function getSubscriptionPayments(id:string): Promise<Array<Transactions> | ErrorMessage> {
  return axios.get(`${SUBSCRIPTION_API}/${id}/payments`)
      .then(response => response.data as unknown as Array<Transactions>)
      .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function setSubscriptionPause(id:string,psp:string): Promise<StatusMessage | ErrorMessage> {
  return axios.get(`${SUBSCRIPTION_API}/${id}/${psp}/pause`)
      .then(response => response.data as unknown as StatusMessage)
      .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function setSubscriptionResume(id:string,psp:string): Promise<StatusMessage | ErrorMessage> {
  return axios.get(`${SUBSCRIPTION_API}/${id}/${psp}/resume`)
      .then(response => response.data as unknown as StatusMessage)
      .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}
export function setSubscriptionCancel(id:string,psp:string,when:string,reason:string): Promise<StatusMessage | ErrorMessage> {
  return axios.post(`${SUBSCRIPTION_API}/${id}/${psp}/cancel`,{when,reason})
      .then(response => response.data as unknown as StatusMessage)
      .catch(err => ({status: 'error', error: err?.response?.data?.error??'Unknown error'}) as ErrorMessage)
}