import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PaymentLinkTypes, PersonalLink, TaxRates, Transactions} from '../../modules/auth'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router'
import {
  getCountry,
  getLinkOrPersonal,
  getPaymentMethods,
} from '../../modules/requests/core/_requests'
import {PaymentLink, paymentMethodsByPsp} from '../../modules/requests/core/_models'
import Skeleton from 'react-loading-skeleton'
import {CurrencyFormat} from '../../modules/currency/components/format'
import {Link, useNavigate} from 'react-router-dom'
import {Pending} from './singlerequest/Pending'
import {Paid} from './singlerequest/Paid'
import {hexToTextColor, requestSolver} from './RequestHelpers'
import {HeaderSub} from './singlerequest/link/HeaderSub'
import {PriceBar} from './singlerequest/personal/PriceBar'
import clsx from 'clsx'
import {useRequest} from './RequestWrapper'
import {getIntegrationKeys} from '../../integrations/core/_requests'
import {ContentFluid} from '../../../_metronic/layout/components/ContentFluid'
import {StripeVerify} from '../../integrations/stripe/StripeVerify'
import {ProductBar} from './singlerequest/personal/ProductBar'
import {MollieVerify} from '../../integrations/mollie/MollieVerify'
import {PaypalVerify} from '../../integrations/paypal/PaypalVerify'
import {TopButtons} from './singlerequest/TopButtons'
import {Badge} from './singlerequest/Badge'
import ReactTimeAgo from 'react-time-ago'
import {ExtraField} from './extrafields/ExtraField'
import showdown from 'showdown'
import {MspVerify} from '../../integrations/msp/MspVerify'
import {useLanguage} from '../../../_metronic/i18n/Metronici18n'

const SingleRequest: FC = () => {
  const {linkid, var1, var2, var3, var4, var5, var6} = useParams()

  const intl = useIntl()
  const navigator = useNavigate()
  const {
    type,
    setType,
    variables,
    setVariables,
    setIntegrations,
    bg,
    setBg,
    psp,
    setPsp,
    product,
    setProduct,
    amount,
    setAmount,
    setExtraFields,
  } = useRequest()
  const [pageTitle, setPageTitle] = useState('Payreque.st')
  const [linkData, setLinkData] = useState<PaymentLink | PersonalLink>()
  const [reqType, setReqType] = useState(type)
  const [methods, setMethods] = useState<paymentMethodsByPsp>()
  const [backgroundImage, setBackgroundImage] = useState(
    'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?auto=format&fit=crop&w=1600&q=80'
  )

  useEffect(() => {
    if (typeof bg === 'undefined')
      setBackgroundImage(
        'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?auto=format&fit=crop&w=1600&q=80'
      )
    else setBackgroundImage(bg)
  }, [bg])

  useEffect(() => {
    setVariables((oldVars) => ({
      ...oldVars,
      ...requestSolver(linkid, var1, var2, var3, var4, var5, var6),
    }))
    if (variables?.page === 'shop') setProduct(undefined)
  }, [window.location.href])

  const parseParams = () => {
    const regex = /([a-zA-Z0-9_-]+)(?:\[([a-zA-Z0-9_-]+)\])?\=([^&]+)/g
    let parameters
    while ((parameters = regex.exec(window.location.search.substring(1))) !== null) {
      if (parameters.index === regex.lastIndex) regex.lastIndex++
      let key = parameters[1]
      let subKey = parameters[2]
      let value: string = ''
      try {
        value = decodeURIComponent(parameters[3])
      } catch (e) {
        value = parameters[3]
      }
      if (subKey) {
        setExtraFields((oldVars) => ({...oldVars, [subKey]: value}))
      } else {
        if (key === 'email') key = 'mail'
        setVariables((oldVars) => ({...oldVars, [key]: value}))
      }
    }
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('country') === null)
      getCountry().then((country) => setVariables((oldVars) => ({...oldVars, country: country})))
  }

  useEffect(() => {
    if (typeof linkid !== 'undefined') {
      setVariables(requestSolver(linkid, var1, var2, var3, var4, var5, var6))
      parseParams()
      getLinkOrPersonal(linkid)
        .then((res) => {
          if ('error' in res) {
            navigator('/not-found')
            return
          }

          setVariables((oldVars) => ({
            ...oldVars,
            currency: oldVars.currency ?? ('currency' in res ? res.currency : res?.user?.currency),
            testmode: res?.user?.testmode ?? false,
            personalId: res?.user?.personalLink?.slug ?? '',
          }))
          if (res?.user?.backgroundImage !== '') setBg(res?.user?.backgroundImage)
          let slug: string = ''
          let _type: string = ''
          if ('urlpath' in res && 'type' in res) {
            switch (res.type) {
              case PaymentLinkTypes.NORMAL:
              case PaymentLinkTypes.PRODUCT:
              default:
                _type = 'link'
                break
              case PaymentLinkTypes.PREAUTH:
                _type = 'preauth'
                break
              case PaymentLinkTypes.PERSONAL:
                _type = 'personal'
                break
              case PaymentLinkTypes.DONATION:
                _type = 'donation'
                break
            }
          } else _type = 'personal'

          if ('product' in res && !('urlpath' in res)) {
            setLinkData({...res?.user?.personalLink, user: res?.user} as PersonalLink)
            setVariables((oldVars) => ({
              ...oldVars,
              product: res?.product?.id.toString() ?? '',
            }))
            setProduct(res?.product ?? undefined)
            slug = res?.product?.slug ?? ''
            if (typeof product !== 'undefined' && 'price' in product)
              setAmount(product.price.toString())
            setPageTitle(`${res?.product?.name} | PayReque.st`)

          } else {
            setLinkData(res)
            if ('type' in res) setVariables((oldVars) => ({...oldVars, type: res?.type}))
            /* @ts-ignore */
            slug = 'slug' in res ? res?.slug : res?.urlpath
            /* @ts-ignore */
            setPageTitle(
              `${
                !('title' in res) ? res?.user?.companyName ?? res?.user?.name : res?.title
              } | PayReque.st`
            )
          }
          getPaymentMethods(slug, 'product' in res ? 'product' : _type).then((res) => {
            if ('error' in res) {
              navigator('/not-found')
              return
            }
            setMethods(res)
          })
          getIntegrationKeys(res?.user?.id).then((res) => {
            if ('error' in res) {
              return
            }
            setIntegrations(res)
          })
          if ('shipping' in res)
            setVariables((oldVars) => ({...oldVars, shipping: res?.shipping ?? false}))
          if('product' in res && res?.product?.type?.id === 3)
            setType('preauth')
          else if(typeof product === "undefined")
            setType(_type)
          else
            setType(_type)
          setReqType(_type)
        })
        .catch((err) => console.log(err))
    }
  }, [linkid])

  useEffect(() => {
    if (
      typeof variables?.page === 'undefined' ||
      typeof type === 'undefined' ||
      typeof linkData === 'undefined'
    )
      return
    if (typeof variables?.price !== 'undefined' && typeof variables?.price !== 'boolean')
      setAmount(variables?.price)
    if (['link', 'preauth'].includes(type) && 'customer' in linkData) {
      setVariables((oldVars) => ({
        ...oldVars,
        name: linkData?.customer?.name ?? '',
        mail: linkData?.customer?.email ?? '',
        street: linkData?.customer?.street ?? '',
        postal: linkData?.customer?.postal ?? '',
        city: linkData?.customer?.city ?? '',
        country: linkData?.customer?.country ?? oldVars?.country ?? '',
      }))
    }
    if (variables?.page === 'verification') {
      const urlParams = new URLSearchParams(window.location.search)
      if (
        urlParams.get('payment_intent_client_secret') !== null ||
        urlParams.get('setup_intent_client_secret') !== null ||
        variables?.setup_intent ||
        variables?.payment_intent
      )
        setPsp('stripe')
      else if (urlParams.get('uid') !== null) setPsp('mollie')
      else if (urlParams.get('mid') !== null) setPsp('msp')
      else if (typeof variables?.paymentStatus !== 'undefined') setPsp('paypal')
    }
  }, [variables?.page, type])

  const isLDP = (reqType: string | undefined, exclude?: string[]) => {
    if (typeof reqType === 'undefined') return false
    const items = ['link', 'donation', 'preauth']
    if (typeof exclude !== 'undefined') items.filter((item) => !exclude.includes(item))
    return items.includes(reqType)
  }
  const getVat = () => {
    if (
      typeof linkData === 'undefined' ||
      !('tax' in linkData) ||
      ('tax' in linkData && !linkData?.tax?.status) ||
      'product' in linkData ||
      typeof variables?.country === 'undefined' ||
      variables?.country === ''
    )
      return undefined
    let fee: TaxRates | undefined = undefined
    if ('country' in variables && typeof variables?.country !== 'boolean') {
      //@ts-ignore
      fee = linkData?.tax?.filter(
        //@ts-ignore
        (taxrate) => taxrate?.country?.toLowerCase() === variables?.country?.toLowerCase()
      )
    }
    return fee
  }

  const markdownToHtml = new showdown.Converter()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{pageTitle}</PageTitle>
      <div
        className='page d-flex flex-row flex-column-fluid justify-content-center request-page'
        style={{
          backgroundImage: !document.body.classList.contains('iframe-body')
            ? `url("${backgroundImage}")`
            : 'none',
        }}
      >
        <ContentFluid>
          {typeof linkData?.user?.css !== 'undefined' && linkData?.user?.css !== '' && (
            <style dangerouslySetInnerHTML={{__html: linkData?.user?.css}}></style>
          )}
          <TopButtons linkData={linkData} />
          <div className='d-flex flex-column-fluid flex-column justify-content-center align-items-start mt-15 flex-md-row gap-10 w-100'>
            <div
              className={`card card-custom shadow rounded-3 ${
                document.body.classList.contains('iframe-body')
                  ? 'w-100'
                  : 'w-md-550px mw-100 w-100 mw-md-550px'
              }`}
            >
              <div className={clsx('card-body px-0 pt-6', reqType === 'personal' ? 'pb-0' : '')}>
                {linkData?.user?.id !== 4557 ? (
                  <>
                    <div className='card-top px-10 d-flex justify-content-between flex-row flex-wrap align-items-stretch'>
                      {typeof linkData?.user?.CompanyLogo !== 'undefined' ? (
                        <img
                          src={
                            linkData?.user?.CompanyLogo !== ''
                              ? linkData?.user?.CompanyLogo
                              : linkData?.user?.avatar
                          }
                          alt={linkData?.user?.name}
                        />
                      ) : (
                        <Skeleton width={35} height={35} />
                      )}
                      <div className='d-flex flex-column justify-content-center'>
                        <h1
                          className={`fs-2 ${
                            isLDP(reqType) &&
                            typeof linkData !== 'undefined' &&
                            'status' in linkData
                              ? 'mt-2'
                              : !isLDP(type)
                              ? 'mb-0'
                              : ''
                          }`}
                        >
                          {linkData?.user?.companyName ?? linkData?.user?.name ?? (
                            <Skeleton width={140} height={35} />
                          )}
                        </h1>
                        <HeaderSub
                          type={reqType}
                          status={
                            typeof linkData !== 'undefined'
                              ? 'status' in linkData
                                ? linkData?.status
                                : ''
                              : undefined
                          }
                        />
                      </div>
                    </div>
                    <div className={`separator mt-6 mb-0`}></div>
                  </>
                ) : (
                  <>
                    <div className='card-top px-10 pb-5 d-flex justify-content-center flex-row flex-wrap align-items-stretch'>
                      <h2 className={'fs-2'}>Payment Link</h2>
                    </div>
                    <div className='card-top px-10 py-5 d-flex justify-content-start flex-row flex-wrap align-items-stretch border-top border-bottom bg-light border-gray-300'>
                      {typeof linkData?.user?.CompanyLogo !== 'undefined' ? (
                        <img
                          src={
                            linkData?.user?.CompanyLogo !== ''
                              ? linkData?.user?.CompanyLogo
                              : linkData?.user?.avatar
                          }
                          className={'rounded-circle'}
                          alt={linkData?.user?.name}
                        />
                      ) : (
                        <Skeleton width={35} height={35} />
                      )}
                      <div className='d-flex flex-column justify-content-center ms-5'>
                        <h1
                          className={`fs-3 mt-2 fw-light ${
                            isLDP(reqType) &&
                            typeof linkData !== 'undefined' &&
                            'status' in linkData
                              ? 'mt-2'
                              : !isLDP(type)
                              ? 'mb-0'
                              : ''
                          }`}
                        >
                          {linkData?.user?.companyName ?? <Skeleton width={140} height={35} />}
                        </h1>
                        <HeaderSub
                          type={reqType}
                          status={
                            typeof linkData !== 'undefined'
                              ? 'status' in linkData
                                ? linkData?.status
                                : ''
                              : undefined
                          }
                        />
                      </div>
                    </div>
                    {isLDP(reqType) && typeof linkData !== 'undefined' && 'customer' in linkData && (
                      <div className={'d-flex flex-column px-10 py-5'}>
                        <h3 className={'fs-2 fw-bold'}>Hi {linkData?.customer?.name},</h3>
                        <p className={'text-muted fs-4 fw-light'}>{linkData?.title}</p>
                        <div className={`separator mt-6 mb-0`}></div>
                        <p
                          className={'text-muted'}
                          dangerouslySetInnerHTML={{__html: linkData?.description}}
                        ></p>
                        <div className={`separator mt-6 mb-0`}></div>
                      </div>
                    )}
                  </>
                )}

                {typeof reqType === 'undefined' && (
                  <>
                    <Skeleton width='100%' height={35} className='px-10' />
                    <Skeleton width='100%' height={35} />
                  </>
                )}
                {linkData?.user?.id !== 4557 &&
                  isLDP(reqType) &&
                  typeof linkData !== 'undefined' &&
                  'title' in linkData && (
                    <>
                      <h2
                        className='text-start text-uppercase px-10 py-6 mb-0'
                        style={{
                          background: `linear-gradient(121deg, #${
                            linkData?.user?.background1 ?? '000000'
                          } 0%, #${linkData?.user?.background2 ?? '2d2a2e'} 100%)`,
                          color: `${hexToTextColor(linkData?.user?.background1 ?? '000000')}`,
                        }}
                      >
                        {linkData?.title ?? <Skeleton width='100%' height={35} />}
                      </h2>
                      <div className={`separator mt-0 mb-6`}></div>
                      {document.body.classList.contains('iframe-body') && (
                        <div className='px-10'>
                          {typeof linkData?.description !== 'undefined' ? (
                            <div dangerouslySetInnerHTML={{__html: linkData.description}}></div>
                          ) : (
                            <Skeleton width='100%' height={35} />
                          )}
                        </div>
                      )}
                    </>
                  )}
                {reqType === 'personal' &&
                  (typeof product === 'undefined' || product?.slug !== 'payrequest-pro') && (
                    <>
                      <div
                        className='d-flex w-100 flex-row justify-content-between gap-0 personal__menu'
                        style={{
                          ['--color' as any]: `black`,
                          ['--background' as any]: `#d0d0d0`,
                        }}
                      >
                        <Link
                          to={`/${linkData?.user?.personalLink?.slug}`}
                          onClick={(e) => {
                            e.preventDefault()
                            setVariables((old) => ({...old, page: 'personal'}))
                            setProduct(undefined)
                            navigator(`/${linkData?.user?.personalLink?.slug}`)
                          }}
                          className={`p-2 p-md-5 py-5 flex-grow-1 btn btn-sm btn-custom border-end secondary border-none rounded-0 ${
                            typeof product === 'undefined' && variables?.page !== 'shop' && 'active'
                          }`}
                        >
                          {/*
                                                    @ts-ignore */}
                          <FontAwesomeIcon //@ts-ignore
                            icon='fa-duotone fa-home'
                            className='me-5 d-none d-md-inline-block'
                          />
                          Payment page
                        </Link>
                        <Link
                          to={`/${linkData?.user?.personalLink?.slug}/shop`}
                          onClick={(e) => {
                            e.preventDefault()
                            //setProduct(linkData?.user?.products?.[0])
                            setVariables((old) => ({...old, page: 'shop'}))
                            navigator(`/${linkData?.user?.personalLink?.slug}/shop`)
                          }}
                          className={`p-2 p-md-5 py-5 flex-grow-1 btn btn-sm btn-custom border-end secondary border-none rounded-0 ${
                            (typeof product !== 'undefined' || variables?.page === 'shop') &&
                            'active'
                          }`}
                        >
                          {/*
                                                    @ts-ignore */}
                          <FontAwesomeIcon //@ts-ignore
                            icon='fa-duotone fa-boxes-stacked'
                            className='me-5 d-none d-md-inline-block'
                          />
                          Shop
                        </Link>
                        <Link
                          to={'/dashboard'}
                          className='p-2 p-md-5 py-5 flex-grow-1 btn btn-sm btn-custom secondary border-none rounded-0'
                        >
                          {/*
                                                    @ts-ignore */}
                          <FontAwesomeIcon //@ts-ignore
                            icon='fa-duotone fa-home'
                            className='me-5 d-none d-md-inline-block'
                          />
                          Manage your subscription
                        </Link>
                      </div>
                    </>
                  )}
                {variables?.page !== 'shop' && <ProductBar product={product} linkData={linkData} />}
                {variables?.page === 'verification' && (
                  <>
                    {typeof psp === 'undefined' && <Skeleton height={200} width={'100%'} />}
                    {psp === 'stripe' && <StripeVerify />}
                    {psp === 'mollie' && <MollieVerify />}
                    {psp === 'msp' && <MspVerify />}
                    {psp === 'paypal' && <PaypalVerify />}
                  </>
                )}
                {variables?.page !== 'verification' && (
                  <>
                    <div
                      className={`d-flex justify-content-between flex-${
                        reqType === 'personal' &&
                        typeof product === 'undefined' &&
                        typeof variables?.price === 'undefined'
                          ? 'column'
                          : 'row flex-wrap'
                      } bg-light ${reqType === 'donation' ? 'flex-wrap ' : ''}px-10 py-6 mt-${
                        reqType === 'personal' && typeof variables?.price === 'undefined' ? 0 : 6
                      } ${
                        variables?.page === 'shop' ? 'rounded-bottom' : 'mb-6'
                      } align-items-center border-gray-200 border border-start-0 border-end-0`}
                    >
                      {variables?.page === 'shop' ? (
                        <div className='d-flex flex-column gap-5 w-100 px-0 py-4 productsgroup'>
                          {linkData?.user?.products?.map((p, index) => (
                            <ProductBar
                              product={p}
                              linkData={linkData}
                              clickable={true}
                              active={p.id === product?.id}
                            />
                          ))}
                        </div>
                      ) : (
                        <>
                          {typeof product === 'undefined' && (
                            <h3 className='fs-6 text-start mb-0'>
                              {typeof reqType === 'undefined' && (
                                <Skeleton width='100%' height={26} />
                              )}
                              {(reqType === 'link' || typeof variables?.price !== 'undefined') &&
                                intl.formatMessage({
                                  id: 'LINK.TOTAL',
                                  defaultMessage: 'Amount to be paid',
                                })}
                              {reqType === 'preauth' &&
                                intl.formatMessage({
                                  id: 'LINK.PREAUTH',
                                  defaultMessage: 'Pre-authorization',
                                })}
                              {reqType === 'donation' &&
                                intl.formatMessage({id: 'LINK.GOAL', defaultMessage: 'Goal'})}
                              {reqType === 'personal' && typeof variables?.price === 'undefined' && (
                                <>
                                  {/*
                                                                @ts-ignore */}
                                  <FontAwesomeIcon icon='fa-duotone fa-wallet' className='me-3' />
                                  {intl.formatMessage({
                                    id: 'LINK.CHOOSEPERSONAL',
                                    defaultMessage:
                                      'Choose the right amount or add the desired amount',
                                  })}
                                </>
                              )}
                            </h3>
                          )}
                          <div
                            className={clsx(
                              'pricing-container d-flex flex-row justify-content-start align-items-center ml-auto',
                              reqType === 'personal' &&
                                typeof product === 'undefined' &&
                                typeof variables?.price === 'undefined'
                                ? 'w-100 pt-7 pb-4 personal-container'
                                : '',
                              typeof product !== 'undefined' && 'w-100 py-0 personal-container'
                            )}
                          >
                            {typeof linkData === 'undefined' && (
                              <Skeleton width={100} height={40} />
                            )}
                            {typeof product === 'undefined' && (
                              <>
                                {/*
                                                            @ts-ignore */}
                                {linkData &&
                                  ('amount' in linkData ||
                                    typeof variables?.price !== 'undefined') && //@ts-ignore
                                  (linkData?.amount || variables?.price ? (
                                    <CurrencyFormat
                                      currencyClassName='pe-2' //@ts-ignore
                                      amount={variables?.price ?? linkData?.amount}
                                      className='pricing'
                                      formatAmount={true} //@ts-ignore
                                      currency={variables.currency}
                                    />
                                  ) : (
                                    <Skeleton width={100} height={40} />
                                  ))}
                                {/*
                                                            @ts-ignore */}
                                {linkData &&
                                  'amounts' in linkData &&
                                  reqType !== 'donation' &&
                                  typeof variables?.price === 'undefined' && (
                                    <PriceBar
                                      amounts={linkData?.amounts} //@ts-ignore
                                      currency={variables.currency ?? linkData?.user?.currency}
                                      background1={linkData?.user?.background1}
                                      background2={linkData?.user?.background2}
                                    />
                                  )}
                              </>
                            )}
                            {typeof product !== 'undefined' && (
                              <div
                                className='fs-7 fw-normal'
                                dangerouslySetInnerHTML={{__html: product?.description}}
                              ></div>
                            )}
                          </div>
                          {((variables?.page === 'normal' &&
                            typeof variables?.product === 'undefined') ||
                            variables?.page !== 'normal') && (
                            <>
                              {variables?.page !== 'single' &&
                                typeof linkData !== 'undefined' &&
                                'fee' in linkData &&
                                linkData?.fee > 0 && (
                                  <span
                                    style={{opacity: 0.8, flexBasis: '100%'}}
                                    className='d-flex flex-grow flex-row justify-content-end align-items-center'
                                  >
                                    <span style={{fontSize: '0.9em'}}>
                                      {linkData?.user?.feeTitle ?? 'Additional Fee:'}
                                    </span>
                                    {/*
                                                            @ts-ignore */}
                                    {linkData?.feeType === 'Fixed' ? (
                                      <CurrencyFormat
                                        currencyClassName='px-2 '
                                        amount={linkData?.fee}
                                        className='pricing' //@ts-ignore
                                        currency={variables.currency ?? linkData?.user?.currency}
                                      />
                                    ) : (
                                      <span className='px-2'>{linkData?.fee}%</span>
                                    )}
                                  </span>
                                )}
                            </>
                          )}
                          {/*
                                                        @ts-ignore */}
                          {variables?.page !== 'single' &&
                            typeof linkData !== 'undefined' &&
                            'tax' in linkData &&
                            linkData?.tax !== null && (
                              <span
                                style={{opacity: 0.8, flexBasis: '100%'}}
                                className='d-flex flex-grow flex-row justify-content-end align-items-center'
                              >
                                <span style={{fontSize: '0.9em'}}>
                                  VAT ({getVat()?.rate ?? 0}%):
                                </span>
                                {/*
                                                        @ts-ignore */}
                                <CurrencyFormat
                                  currencyClassName='px-2' //@ts-ignore
                                  amount={(amount / 100) * (getVat()?.rate ?? 0)}
                                  className='pricing' //@ts-ignore
                                  currency={variables.currency ?? linkData?.user?.currency}
                                />
                              </span>
                            )}
                        </>
                      )}

                      {linkData &&
                        reqType === 'donation' &&
                        'donated' in linkData &&
                        typeof variables.currency !== 'boolean' && (
                          <div className='d-flex flex-column gap-5 w-100 my-4'>
                            <PriceBar
                              amounts={linkData?.amounts ?? []}
                              currency={variables.currency ?? linkData?.user?.currency}
                              background1={linkData?.user?.background1}
                              background2={linkData?.user?.background2}
                            />
                            <div className='d-flex flex-column gap-5 w-100 my-4 donationloader'>
                              <div className='progress'>
                                {/*
                                                                            @ts-ignore */}
                                <div
                                  className='progress-bar'
                                  role='progressbar'
                                  aria-valuenow={
                                    ((linkData?.donated ?? 0) / linkData?.amount) * 100
                                  }
                                  aria-valuemin='0'
                                  aria-valuemax='100'
                                  style={{
                                    width: `${
                                      ((linkData?.donated ?? 0) / linkData?.amount) * 100
                                    }%`,
                                    background: `linear-gradient(121deg, #${
                                      linkData?.user?.background1 ?? '000000'
                                    } 0%, #${linkData?.user?.background2 ?? '2d2a2e'} 100%)`,
                                    ['--bs-progress-bar-color' as any]: `${hexToTextColor(
                                      linkData?.user?.background1 ?? '000000'
                                    )}`,
                                  }}
                                >
                                  <div className='flex-nowrap'>
                                    <CurrencyFormat
                                      currencyClassName='pe-2'
                                      amount={linkData?.donated ?? 0}
                                      className='pricing'
                                      formatAmount={true}
                                      currency={variables.currency}
                                    />{' '}
                                    donated of{' '}
                                    <CurrencyFormat
                                      currencyClassName='pe-2'
                                      amount={linkData?.amount}
                                      className='pricing'
                                      formatAmount={true}
                                      currency={variables.currency}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    {variables?.page !== 'shop' && (
                      <>
                        {(reqType === 'personal' || reqType === 'donation') && (
                          <div
                            className='d-flex justify-content-between flex-column px-10 pt-0 pb-6 align-items-center border-gray-200 border border-start-0 border-top-0 border-end-0 gap-6 mt-0 pr__customer_fields'
                            style={{
                              ['--field-border' as any]: `#${
                                linkData?.user?.background1 ?? '000000'
                              }`,
                            }}
                          >
                            {typeof product === 'undefined' ||
                            product?.slug !== 'payrequest-pro' ? (
                              <>
                                {/*
                                                                @ts-ignore */}
                                <input
                                  type='text'
                                  className='form-control shadow-sm fw-normal fs-7' //@ts-ignore
                                  value={variables?.name ?? ''}
                                  onChange={(e) =>
                                    setVariables({...variables, name: e?.target?.value})
                                  }
                                  placeholder={intl.formatMessage({
                                    id: 'FIELDS.NAME',
                                    defaultMessage: 'First and last name',
                                  })}
                                />
                                {/*
                                                                @ts-ignore */}
                                <input
                                  type='email'
                                  className='form-control shadow-sm fw-normal fs-7' //@ts-ignore
                                  value={variables?.mail ?? ''}
                                  onChange={(e) =>
                                    setVariables({...variables, mail: e?.target?.value})
                                  }
                                  placeholder={intl.formatMessage({
                                    id: 'FIELDS.EMAIL',
                                    defaultMessage: 'Email',
                                  })}
                                />
                                <textarea
                                  className='form-control shadow-sm fw-normal fs-7'
                                  cols={3}
                                  placeholder={intl.formatMessage({
                                    id: 'FIELDS.NOTE',
                                    defaultMessage: 'Note',
                                  })}
                                  onChange={(e) =>
                                    setVariables({...variables, note: e?.target?.value})
                                  }
                                  defaultValue={
                                    typeof variables?.note === 'string' ? variables?.note : ''
                                  }
                                />
                              </>
                            ) : (
                              <div className={'d-flex flex-column align-items-center'}>
                                <span className={'fs-5 fw-bold'}>{variables?.name}</span>
                                <span className={'fs-7 fw-normal text-gray-400'}>
                                  {' '}
                                  ({variables?.mail})
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        {typeof linkData !== 'undefined' &&
                          linkData?.customFields?.enabled &&
                          linkData?.customFields?.fields?.length > 0 && (
                            <div
                              style={{
                                ['--background1' as any]: `#${
                                  linkData?.user?.background1 ?? '000000'
                                }`,
                                ['--background2' as any]: `#${
                                  linkData?.user?.background2 ?? '2d2a2e'
                                }`,
                                ['--bs-progress-bar-color' as any]: `${hexToTextColor(
                                  linkData?.user?.background1 ?? '000000'
                                )}`,
                              }}
                              className='row mx-10 px-0 pt-0 pb-6 mb-6 align-items-center border-gray-200 border border-start-0 border-top-0 border-end-0 mt-0 pr__customer_fields g-3'
                            >
                              {linkData?.customFields?.fields?.map((field, index) => (
                                <ExtraField key={`extra__field__${index}`} field={field} />
                              ))}
                            </div>
                          )}
                        {typeof product !== 'undefined' &&
                          typeof product?.customFields !== 'undefined' &&
                          product?.customFields !== null &&
                          product?.customFields?.enabled &&
                          product?.customFields?.fields?.length > 0 && (
                            <div
                              style={{
                                ['--background1' as any]: `#${
                                  linkData?.user?.background1 ?? '000000'
                                }`,
                                ['--background2' as any]: `#${
                                  linkData?.user?.background2 ?? '2d2a2e'
                                }`,
                                ['--bs-progress-bar-color' as any]: `${hexToTextColor(
                                  linkData?.user?.background1 ?? '000000'
                                )}`,
                              }}
                              className='row mx-10 px-0 pt-0 pb-6 mb-6 align-items-center border-gray-200 border border-start-0 border-top-0 border-end-0 mt-0 pr__customer_fields g-3'
                            >
                              {product?.customFields?.fields?.map((field, index) => (
                                <ExtraField key={`extra__field__${index}`} field={field} />
                              ))}
                            </div>
                          )}
                        <div
                          style={{
                            ['--btn-background1' as any]: `#${
                              linkData?.user?.background1 ?? '000000'
                            }`,
                            ['--btn-background2' as any]: `#${
                              linkData?.user?.background2 ?? '2d2a2e'
                            }`,
                          }}
                        >
                          {(typeof reqType === 'undefined' ||
                            typeof methods === 'undefined' ||
                            typeof linkData === 'undefined') && (
                            <p className='px-10  rounded-bottom rounded-3'>
                              <Skeleton width='100%' height={100} />
                            </p>
                          )}
                          {typeof linkData !== 'undefined' &&
                            typeof methods !== 'undefined' &&
                            (isLDP(reqType) && typeof product === 'undefined') &&
                            'status' in linkData && (
                              <>
                                {linkData?.status === 'PENDING' && <Pending methods={methods} />}
                                {linkData?.status === 'PAID' && <Paid gif={linkData?.gif ?? ''} />}
                              </>
                            )}
                          {reqType === 'personal' &&
                            (typeof methods !== 'undefined' ? (
                              <Pending methods={methods} />
                            ) : (
                              <p className='px-10  rounded-bottom rounded-3'>
                                <Skeleton width='100%' height={100} />
                              </p>
                            ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {!document.body.classList.contains('iframe-body') && (
              <div className='card card-custom card-extra overflow-hidden shadow rounded-3 w-md-350px mw-100 w-100 mw-md-350px'>
                <div className='card-body p-0'>
                  {reqType === 'donation' && (
                    <div
                      className={`d-flex flex-row flex-wrap px-0 pt-6 mt-0 gap-3 donationfeed timeline timeline-3 ${
                        typeof linkData?.description !== 'undefined' && linkData?.description !== ''
                          ? 'bg-light'
                          : ''
                      }`}
                    >
                      <h3 className={'fs-3 py-3 px-10'}>Donation Feed</h3>
                      <div
                        className={`d-flex flex-column align-items-start justify-content-start p-3 overflow-auto mh-300px w-100 flex-grow donationscroller timeline-items`}
                        style={{
                          ['--img-border' as any]: `#${linkData?.user?.background1 ?? '000000'}`,
                        }}
                      >
                        {linkData &&
                          'transactions' in linkData &&
                          linkData?.transactions !== null &&
                          linkData?.transactions.length > 0 &&
                          linkData?.transactions
                            ?.sort((a, b) => {
                              if (
                                new Date(a?.createdAt).getTime() ===
                                new Date(b?.createdAt).getTime()
                              )
                                return 0
                              return new Date(a?.createdAt).getTime() <
                                new Date(b?.createdAt).getTime()
                                ? 1
                                : -1
                            })
                            .map((transaction: Transactions, index: number) => (
                              <>
                                <div className='timeline-item'>
                                  <div className='timeline-media'>
                                    <img
                                      src={transaction?.customer?.avatar}
                                      alt={transaction?.name}
                                      className='w-40px h-40px rounded-circle'
                                    />
                                  </div>
                                  <div className='timeline-content p-4'>
                                    <div className='d-flex flex-row align-items-center justify-content-between mb-3'>
                                      <div className='d-flex flex-column gap-1'>
                                        <div className='text-dark-75 text-hover-primary fw-bold fs-7'>
                                          {transaction?.customer?.name}
                                        </div>
                                        <span className='text-muted fs-7'>
                                          <ReactTimeAgo date={new Date(transaction?.createdAt)} />
                                        </span>
                                      </div>
                                      <div className='d-flex donation-pricing ml-auto fs-3'>
                                        {/*
                                                            @ts-ignore */}
                                        <CurrencyFormat
                                          currencyClassName='pe-2'
                                          amount={transaction?.amount}
                                          className='pricing'
                                          formatAmount={true} //@ts-ignore
                                          currency={variables.currency}
                                        />
                                      </div>
                                    </div>
                                    <p className='p-0 m-0'>{transaction?.note}</p>
                                  </div>
                                </div>
                              </>
                            ))}
                      </div>
                    </div>
                  )}
                  {typeof linkData?.description === 'undefined' && (
                    <div className='d-flex justify-content-start flex-column px-10 py-6 align-items-center border-gray-200 border border-start-0 border-top-0 border-end-0 gap-6 mt-0'>
                      <Skeleton width={'100%'} height={20} count={4} />
                    </div>
                  )}
                  {typeof linkData?.description !== 'undefined' &&
                    (linkData?.description !== '' || linkData?.user?.Description !== '') && (
                      <div
                        className='d-flex justify-content-start flex-column px-10 py-6 align-items-center border-gray-200 border border-start-0 border-top-0 border-end-0 gap-6 mt-0'
                        dangerouslySetInnerHTML={{
                          __html:
                            (linkData?.description === ''
                              ? markdownToHtml.makeHtml(linkData?.user?.Description ?? '')
                              : linkData?.description ??
                                markdownToHtml.makeHtml(linkData?.user?.Description ?? '')) ?? '',
                        }}
                      ></div>
                    )}
                  <div
                    className='d-flex justify-content-start flex-row bg-light px-10 py-10 mt-0 mb-0 flex-wrap align-items-center border-gray-200 border border-start-0 border-end-0'
                    style={{
                      ['--gap' as any]: '12px',
                      gap: 'var(--gap)',
                      ['--btn-background1' as any]: `#${linkData?.user?.background1 ?? '000000'}`,
                      ['--btn-background2' as any]: `#${linkData?.user?.background2 ?? '2d2a2e'}`,
                    }}
                  >
                    {typeof linkData?.user?.socials === 'undefined' && (
                      <Skeleton width={'25%'} style={{aspectRatio: '1'}} count={6} />
                    )}
                    {typeof linkData?.user?.socials !== 'undefined' &&
                      Object.entries(linkData?.user?.socials).map((social: Array<string>) => (
                        <a
                          href={social[1]}
                          target='_blank'
                          rel='noreferrer'
                          className='d-inline-flex btn btn-bg-white btn-hover-scale align-items-center p-5 justify-content-center socialicon'
                          style={{
                            aspectRatio: '1',
                            width: 'calc( 25% - calc( var(--gap) * 0.75 ) )',
                          }}
                        >
                          {/*
                                                @ts-ignore*/}
                          <FontAwesomeIcon //@ts-ignore
                            icon={`${
                              ['phone', 'mobile', 'envelope', 'at', 'globe'].includes(social[0])
                                ? 'fa-duotone'
                                : 'fa-brands'
                            } fa-${social[0] === 'wikipedia' ? `${social[0]}-w` : social[0]}`}
                            className='text-gray-700 fs-2x'
                          />
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <Badge pro={linkData?.user?.Pro === 1} />
        </ContentFluid>
      </div>
    </>
  )
}

export {SingleRequest}
