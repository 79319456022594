import axios from 'axios'
import {RedirectResponse, StatusResponse, SuccessResponse} from "../../stripe/core/_models";
import {ErrorMessage} from "../../../modules/requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATIONS_GET = `${API_URL}/api/integrations`
export const CREATE_PAYMENT = `${API_URL}/api/mollie/create`
export const VERIFY_PAYMENT = `${API_URL}/api/mollie/verify`

export function getMollieLink(): Promise<string|boolean> {
    return axios.get<any>(`${INTEGRATIONS_GET}/mollie`)
        .then(response => response.data?.action_url ?? false)
        .catch(err => false)
}

export function checkIntegrationMollie(data:object): Promise<StatusResponse|ErrorMessage> {
    return axios.post(
        `${INTEGRATIONS_GET}/mollie/check`,
        data).then(response => response.data as unknown as StatusResponse)
        .catch(err => ({success:false, error: "Unknown error"}))
}

export function createPayment(data:object): Promise<RedirectResponse|ErrorMessage> {
    return axios.post(
        `${CREATE_PAYMENT}`,
        data).then(response => response.data as unknown as RedirectResponse)
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}

export function verifyPayment(id:string,uid:string, type:string): Promise<SuccessResponse|RedirectResponse|ErrorMessage> {
    return axios.post(
        `${VERIFY_PAYMENT}`,
        {id,uid,type}).then(response => {
            if("redirect" in response.data)
                return response.data as unknown as RedirectResponse
            else
                return response.data as unknown as SuccessResponse
        })
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}