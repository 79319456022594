import {Route, Routes} from 'react-router-dom'
import {RequestLayout} from "../../_metronic/layout/RequestLayout";
import {SingleRequest} from "../pages/requests/SingleRequest";
import {RequestNotFound} from "../pages/requests/RequestNotFound";

const RequestRoutes = () => {
    return (
        <Routes>
            <Route element={<RequestLayout />}>
                <Route path=':linkid' element={<SingleRequest />} />
                <Route path=':linkid/:var1' element={<SingleRequest />} />
                <Route path=':linkid/:var1/:var2' element={<SingleRequest />} />
                <Route path=':linkid/:var1/:var2/:var3' element={<SingleRequest />} />
                <Route path=':linkid/:var1/:var2/:var3/:var4' element={<SingleRequest />} />
                <Route path=':linkid/:var1/:var2/:var3/:var4/:var5' element={<SingleRequest />} />
                <Route path=':linkid/:var1/:var2/:var3/:var4/:var5/:var6' element={<SingleRequest />} />
            </Route>
            <Route path='not-found' element={<RequestNotFound />} />
        </Routes>
    )
}

/*const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}*/

export {RequestRoutes}
