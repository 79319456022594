import React, {FC} from 'react'
import Skeleton from 'react-loading-skeleton'
import {useIntl} from 'react-intl'

type HeaderSubProp = {
  status: string | undefined
  type: string | undefined
}

const HeaderSub: FC<HeaderSubProp> = ({status, type}) => {
  const intl = useIntl()
  if (typeof type === 'undefined' || typeof status === 'undefined')
    return (
      <h3 className='flex-grow-1 w-100 fs-6 text-end skeleton-sub'>
        <Skeleton width={100} height={35} />
      </h3>
    )
  if (!['link', 'preauth', 'donate'].includes(type) || status === '')
    return <h3 className='flex-grow-1 w-100 fs-6 text-end'>&nbsp;</h3>
  let statusText = ''
  if (type === 'link') {
    switch (status) {
      case 'PENDING':
        statusText = intl.formatMessage({
          id: 'LINK.SENDREQUEST',
          defaultMessage: 'sends you a request.',
        })
        break
      default:
        statusText = intl.formatMessage({
          id: 'LINK.PAIDREQUEST',
          defaultMessage: 'Thank you for your payment.',
        })
        break
    }
  } else if (type === 'preauth') {
    switch (status) {
      case 'PENDING':
        statusText = intl.formatMessage({
          id: 'LINK.SENDREQUEST.PREAUTH',
          defaultMessage: 'sends you a pre authorization.',
        })
        break
      default:
        statusText = intl.formatMessage({
          id: 'LINK.PAIDREQUEST.PREAUTH',
          defaultMessage: 'Thank you for authorizing.',
        })
        break
    }
  } else if (type === 'donation') {
    switch (status) {
      case 'PENDING':
        statusText = intl.formatMessage({
          id: 'LINK.SENDREQUEST.DONATION',
          defaultMessage: 'Contribute to this donation event.',
        })
        break
      default:
        statusText = intl.formatMessage({
          id: 'LINK.PAIDREQUEST.DONATION',
          defaultMessage: 'Thank you for all for donating!.',
        })
        break
    }
  }
  return <h3 className='flex-grow-1 w-100 fs-6 text-end real-sub lh-sm mb-0'>{statusText}</h3>
}

export {HeaderSub}
