import axios from 'axios'
import {PspIntegration, PspIntegrationKeys, StatusResponse} from "./_models";
import {ErrorMessage} from "../../modules/requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATIONS_GET = `${API_URL}/api/integrations`
export const INTEGRATION_KEYS = `${API_URL}/api/integration/keys`
export const INTEGRATION_REMOVE = `${API_URL}/api/integration/remove`

export function getIntegrations() {
    return axios.get<Array<PspIntegration>>(`${INTEGRATIONS_GET}`).then(response => response.data ?? false).catch(err => false)
}
export function getIntegrationKeys(uid:number):Promise<PspIntegrationKeys|ErrorMessage> {
    return axios.post(`${INTEGRATION_KEYS}`,{uid}).then(response => response.data as unknown as PspIntegrationKeys)
        .catch(err => ({status:'error', error: 'unknown'} as ErrorMessage))
}
export function removeIntegration(id: number) {
    return axios.post<StatusResponse>(`${INTEGRATION_REMOVE}`,{id}).then(response => response.data ?? {status:false}).catch(err => ({status:false}))
}