import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent} from '../../assets/ts/components'

const ContentFluid: React.FC = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div id='kt_content_container' className='content-fluid d-flex flex-row p-5 align-items-center justify-content-center w-100'>
      {children}
    </div>
  )
}

export {ContentFluid}
