const dumbTextFilter = (rows: any[], id: string | number, filterValue: any) => {
    console.log('text-stupid search filter')
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
    })
}

export default dumbTextFilter