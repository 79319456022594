import React, {FC, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {useDashboard} from "./DashboardWrapper";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {KTSVG} from "../../../_metronic/helpers";
import {check} from "../../modules/auth/core/_requests";
import {useParams} from "react-router";
import {IlocalFilter, PrTable} from "../../../_metronic/partials/widgets/tables/PrTable";
import Select, {components, DropdownIndicatorProps, GroupBase} from "react-select";
import {CurrencyFormat} from "../../modules/currency/components/format";
import {SubscriptionStatus as SubStatus} from "./components/SubscriptionStatus";
import {SubscriptionStatus} from "../../modules/auth";
import Skeleton from "react-loading-skeleton";
import {Subscriptions} from "../../../_metronic/partials/widgets/tables/content/subscriptions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getNonceLocal, getViewIdLocal, setNonceLocal, setViewIdLocal} from "./components/NonceHelper";

const overviewBreadCrumbs: Array<PageLink> = [
    {
        title: 'PayRequest',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Subscription Management',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    }
]

const Overview: FC = () => {
    const intl = useIntl()
    const navigator = useNavigate()
    let {viewid,nid} = useParams()
    const {setLoggedin,customer,setCustomer,setNonce,setCid} = useDashboard()
    const [loadingData, setLoadingData] = useState(true);

    const [globalFilter, setGlobalFilter] = useState("");
    const [localFilter, setLocalFilter] = useState<IlocalFilter>({columnId:null,filter:null});
    const [pageCount, setPageCount] = useState(10);

    useEffect(() => {
        if(typeof viewid == "undefined" || typeof nid == "undefined") {
            const ntest = getNonceLocal()
            const vtest = getViewIdLocal()
            if(typeof ntest == "undefined" || typeof vtest == "undefined")
                return
            nid = ntest
            viewid = vtest
        }
        check(nid,viewid).then((res) => {
            if("error" in res){
                navigator('/dashboard')
                return
            }
            setCustomer(res.customer)
            setCid(res.cid)
            setNonce(res.nonce)
            setNonceLocal(res.nonce)
            setViewIdLocal(res.cid)
            setLoggedin(true)
            if(typeof res.customer.subscriptions === "undefined" || res.customer.subscriptions.length === 0) {
                setSubscriptions([])
            }else {
                setSubscriptions(res.customer.subscriptions.map(sub => {
                    return {
                        id: sub.id,
                        name: <span className='text-dark fw-bolder text-hover-primary fs-6'>
                                {sub.name}
                            </span>,
                        price: (
                            <>
                            <span data-filter={sub.price} className='text-dark fw-bolder text-hover-primary fs-6'>
                              <CurrencyFormat currency={sub.currency} amount={sub.price} formatAmount={true}/>
                            </span>
                                {/*{typeof currentUser !== "undefined" && typeof currentUser.currency !== "undefined" && sub.currency !== null && sub.currency.toUpperCase() !== currentUser.currency.toUpperCase() && (
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                                <CurrencyFormat currency={currentUser.currency} amount={sub.amountBase}/>
                              </span>
                            )}*/}
                            </>
                        ),
                        status: <SubStatus data-filter={sub.status} status={sub.status}/>,
                        createdAt: sub.createdAt !== null && <>
                                <span data-filter={sub.createdAt}
                                      className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {intl.formatDate(sub.createdAt, {year: 'numeric', month: 'long', day: 'numeric'})}
                                </span>
                            <span className='text-muted fw-bold text-muted d-block fs-8'>
                                  {intl.formatTime(sub.createdAt, {hour12: false})}
                                </span>
                        </>,
                        next: sub.next !== null && <>
                                <span data-filter={sub.next} className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {intl.formatDate(sub.next, {year: 'numeric', month: 'long', day: 'numeric'})}
                                </span>
                            <span className='text-muted fw-bold text-muted d-block fs-8'>
                                  {intl.formatTime(sub.next, {hour12: false})}
                                </span>
                        </>,
                        actions: false
                    }
                }).reverse())
            }
            setLoadingData(false)
        })
    }, [nid]);

    const [subscriptions, setSubscriptions] = useState<Array<any>>([]);
    const sortPrice = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            const av: number = parseFloat(a.original[columnId].props.children[0].props['data-filter']);
            const bv: number = parseFloat(b.original[columnId].props.children[0].props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const sortDateTime = useMemo(() => {
        return (a: any, b: any, columnId: string, desc: boolean) => {
            console.log(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter'])
            const av: Date = new Date(typeof a.original[columnId].props.children[0] !== "undefined"?a.original[columnId].props.children[0].props['data-filter'] : a.original[columnId].props.children.props['data-filter']);
            console.log(av)
            const bv: Date = new Date(typeof b.original[columnId].props.children[0] !== "undefined"?b.original[columnId].props.children[0].props['data-filter'] : b.original[columnId].props.children.props['data-filter']);
            return av > bv ? 1 : (bv > av ? -1 : 0);
        };
    },[]);
    const sortee = useMemo(() => [
            {
                id: "createdAt",
                desc: true
            }
        ],
        [])
    const columns = useMemo(() => [
        {
            Header: intl.formatMessage({id: 'TABLE.NAME', defaultMessage: 'Name'}),
            accessor: 'name',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            disableSortBy: true
        },/*{
            Header: intl.formatMessage({id: 'TABLE.CUSTOMER', defaultMessage: 'Customer'}),
            accessor: 'customer',
            className: 'min-w-150px',
            filter: 'fuzzyText',
            disableSortBy: true
        },*/{
            Header: intl.formatMessage({id: 'TABLE.PRICE', defaultMessage: 'Price'}),
            accessor: 'price',
            className: 'min-w-80px',
            filter: 'fuzzyText',
            sortType: sortPrice
        },{
            Header: intl.formatMessage({id: 'TABLE.STATUS', defaultMessage: 'Status'}),
            accessor: 'status',
            className: 'min-w-120px',
            filter: 'filterSearch',
            disableSortBy: true
        },{
            Header: intl.formatMessage({id: 'TABLE.CREATED', defaultMessage: 'Created at'}),
            accessor: 'createdAt',
            className: 'min-w-120px',
            filter: 'rangeDate',
            defaultCanSort: true,
            sortType: sortDateTime
        },{
            Header: intl.formatMessage({id: 'TABLE.NEXT', defaultMessage: 'Next payment'}),
            accessor: 'next',
            className: 'min-w-120px',
            filter: 'rangeDate',
            sortType: sortDateTime
        },{
            Header: intl.formatMessage({id: 'TABLE.ACTIONS', defaultMessage: 'Actions'}),
            accessor: 'actions',
            className: 'min-w-0px text-end',
            disableSortBy: true
        }
    ], [])
    const onChange = (e:any) => setGlobalFilter(e?.target?.value ?? "");
    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr072.svg'
                    className='svg-icon-2x'
                />
            </components.DropdownIndicator>
        );
    };

    return <>
        <PageTitle breadcrumbs={overviewBreadCrumbs}>Overview</PageTitle>
        <div className='page d-flex flex-row justify-content-center'>
            <div className='row g-5 container'>
                <div className="col-md-8 col-12">
                    <div className='card mb-5 mb-xl-10'>
                        <div className='card-header border-0'>
                            {!loadingData?(
                                <div className='d-flex my-5 pt-5 w-100'>
                                    <div className="d-flex align-items-center position-relative my-1">
                                        {/*
                                     @ts-ignore */}
                                        <FontAwesomeIcon icon="fa-duotone fa-magnifying-glass" className='fs-3 ms-4 position-absolute' />
                                        <input type="text"
                                               value={globalFilter}
                                               className="form-control form-control-solid w-250px ps-14"
                                               placeholder={intl.formatMessage({id: 'MENU.SEARCH', defaultMessage: 'Search...'})}
                                               onChange={onChange}/>
                                    </div>
                                    <div className='d-flex justify-content-end flex-shrink-0 ms-auto my-1'>
                                        {/* begin::Menu */}
                                        <button type="button"
                                                className='btn btn-sm btn-flex btn-primary fw-bolder px-7'
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen031.svg'
                                                className='me-3 ms-0'
                                            />
                                            Filter
                                        </button>

                                        {/* begin::SubMenu */}
                                        <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px w-lg-350px py-4' data-kt-menu='true'>
                                            <div className="px-7 py-5">
                                                <div className="fs-5 text-dark fw-bolder">Filteroptions</div>
                                            </div>

                                            <div className="separator border-gray-200"></div>

                                            <div className="px-7 py-5" data-kt-products-table-filter="form">

                                                <div className="mb-10">
                                                    <label className="form-label fs-6 fw-bold">Status:</label>
                                                    <Select
                                                        components={{DropdownIndicator}}
                                                        // @ts-ignore
                                                        options={[
                                                            { value:'',label:"All"},
                                                            { value:SubscriptionStatus.ENABLED,label:"Enabled"},
                                                            { value:SubscriptionStatus.PAUSED,label:"Paused"},
                                                            { value:SubscriptionStatus.CANCELLED,label:"Cancelled"},
                                                            { value:SubscriptionStatus.PENDING_CANCEL,label:"Pending cancel"},
                                                        ] as GroupBase<any>}
                                                        onChange={event => {
                                                            // @ts-ignore
                                                            setLocalFilter({columnId: 'status', filter: event?.value ?? null})
                                                        }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                background: '#f5f8fa',
                                                                borderColor: '#f5f8fa',
                                                                color: '#5e6278',
                                                                transition: 'color 0.2s ease, background-color 0.2s ease',
                                                                borderRadius: '0.3rem'
                                                            }),
                                                            valueContainer: (provided) => ({
                                                                ...provided,
                                                                minHeight: 'calc(1.5em + 1rem + 2px)',
                                                                padding: '0.5rem 1rem',
                                                                fontSize: '1.25rem',
                                                            })
                                                        }}/>
                                                </div>

                                            </div>
                                        </div>
                                        {/* end::SubMenu */}
                                        {/* end::Menu */}
                                    </div>
                                </div>
                            ):(<div className='d-flex justify-content-between flex-row align-items-center w-100 mt-10'>
                                <Skeleton width={250} height={43}/>
                                <div className='d-flex flex-row ms-auto align-items-center'>
                                    <Skeleton width={209} height={43} style={{marginRight: 15}}/>
                                    <Skeleton width={61} height={13} style={{marginRight: 10}}/>
                                    <Skeleton width={263} height={43}/>
                                </div>
                            </div>)}
                        </div>
                        <div className='card-body py-3'>
                            <div className='table-responsive'>
                                {!loadingData?(<>
                                    <PrTable columns={columns} sortee={sortee} rawdata={subscriptions} globalFilter={globalFilter} localFilter={localFilter} pageSize={pageCount} TableContent={Subscriptions} tableType={'subscriptions'}/>
                                </>):(
                                    <div style={{marginTop: 30}}>
                                        <Skeleton width={'100%'} height={35} style={{marginBottom: 10}}/>
                                        <Skeleton width={'100%'} height={55} count={10}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12">
                    <div data-kt-sticky="true" data-kt-sticky-width={true}>
                        {/*<SubStatus expanded={true} status={subscription?.status}/>*/}

                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-header border-0 py-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Customer</span>
                                    <span className='text-muted fw-bold fs-7'>Who did you send it to?</span>
                                </h3>
                            </div>
                            <div className='card-body pt-3 pb-6'>
                                {/* show avatar on the left, name and mail next to it*/}
                                <div className='row'>
                                    <div className='col-md-3 col-12'>
                                        <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                                            {customer?.avatar?<img src={customer?.avatar} alt={customer?.name} />:<Skeleton width={40} height={40} />}
                                        </div>
                                    </div>
                                    <div className='col-md-9 col-12'>
                                        {customer?.name ?? <Skeleton width={100} height={18} />}
                                        <span className='d-block text-muted fw-bold fs-7'>{customer?.email ?? <Skeleton width={120} height={14}/> }</span>
                                    </div>
                                </div>
                                {/*{(typeof mollieMandate !== "undefined" && subscription?.psp && subscription?.psp === 1) && (<>*/}
                                {/*    <div className="separator separator-dashed border-secondary my-10"></div>*/}
                                {/*    <h5 className="mb-4">Payment Details</h5>*/}
                                {/*    <div className="fw-semibold text-gray-600 d-flex align-items-center mb-4">*/}
                                {/*        /!**/}
                                {/*    @ts-ignore *!/*/}
                                {/*        {mollieMandate?.method === "ideal" && <FontAwesomeIcon icon={'fab fa-ideal'} className="me-2 fs-2" />}*/}
                                {/*        /!**/}
                                {/*    @ts-ignore *!/*/}
                                {/*        {mollieMandate?.method === "creditcard" && <FontAwesomeIcon icon={'fad fa-credit-card-front'} className="me-2 fs-2" />}*/}
                                {/*        /!**/}
                                {/*    @ts-ignore *!/*/}
                                {/*        {mollieMandate?.method === "paypal" && <FontAwesomeIcon icon={'fab fa-paypal'} className="me-2 fs-2" />}*/}
                                {/*        /!**/}
                                {/*    @ts-ignore *!/*/}
                                {/*        {mollieMandate?.method === "directdebit" && <FontAwesomeIcon icon={'fad fa-credit-card'} className="me-2 fs-2" />}*/}
                                {/*        {mollieMandate?.details?.consumerAccount ?? mollieMandate?.details?.cardLabel}*/}
                                {/*    </div>*/}
                                {/*    <div className="fw-semibold text-gray-600 d-flex align-items-center mb-4">*/}

                                {/*        /!**/}
                                {/*    @ts-ignore *!/*/}
                                {/*        {mollieMandate?.details?.consumerName && <FontAwesomeIcon icon={'fad fa-user'} className="me-2 fs-2" />}*/}
                                {/*        {mollieMandate?.details?.consumerName ?? mollieMandate?.details?.cardHolder}*/}
                                {/*    </div>*/}
                                {/*</>)}*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export {Overview}