import clsx from "clsx";
import React from "react";

interface iPagination {
    gotoPage: Function,
    previousPage: Function,
    canPreviousPage: boolean,
    nextPage: Function,
    canNextPage: boolean,
    pageIndex: number,
    pageCount: number,
    pageOptions: any[]
}

export const Pagination:React.FC<iPagination> = ({gotoPage, previousPage, canPreviousPage, nextPage, canNextPage, pageIndex, pageCount, pageOptions}) => {
    return (
        <ul className="pagination justify-content-end ms-auto">
            <li key='prevv' className={clsx(!canPreviousPage?'disabled':'','page-item previous')}>
                <a href="#" onClick={event => { event.preventDefault(); gotoPage(0); }} className="page-link">
                    <i className="previous"></i><i className="previous"></i>
                </a>
            </li>
            <li key='prev' className={clsx(!canPreviousPage?'disabled':'','page-item previous')}>
                <a href="#" onClick={event => { event.preventDefault(); previousPage(); }} className="page-link">
                    <i className="previous"></i>
                </a>
            </li>

            {Array(5).fill(pageIndex+1).map((x, y) => {
                const pageNum = (pageIndex-1)+y;
                if(pageNum<=0||pageNum>pageOptions.length)
                    return "";
                return (
                    <li key={'page-'+pageNum} className={clsx(pageNum==x?'active':'','page-item')}>
                        <a href="#" onClick={event => { event.preventDefault(); gotoPage(pageNum-1); }} className="page-link">
                            {pageNum}
                        </a>
                    </li>
                );
            })}

            <li key='next' className={clsx(!canNextPage?'disabled':'','page-item next')}>
                <a href="#" onClick={event => { event.preventDefault(); nextPage(); }} className="page-link">
                    <i className="next"></i>
                </a>
            </li>
            <li key='nextt' className={clsx(!canNextPage?'disabled':'','page-item next')}>
                <a href="#" onClick={event => { event.preventDefault(); gotoPage(pageCount - 1) }} className="page-link">
                    <i className="next"></i><i className="next"></i>
                </a>
            </li>
        </ul>
    )
};