import React, {FC, useEffect, useMemo, useState} from "react";
import {useRequest} from "../../pages/requests/RequestWrapper";

import {KTSVG} from "../../../_metronic/helpers";
import {createPayment} from "./core/_requests";
import {useIntl} from "react-intl";
import Select from "react-select";
import countryList from "react-select-country-list";

/*
* We have multiple steps..
* 1. Show bank choice, card or just a continue button
* 2. Handle in app payment or redirect to external payment
* 3. Return to our app or JS response, handle errors or success
* 4. Show verification
*/

type MollieData = {[index: string]:any}

const MollieLoader: FC = () => {
    const intl = useIntl()
    const {amount, type, step, method,extraFields, variables, setVariables, setShowNext, setShowPrev, product} = useRequest()
    const [error, setError] = useState<string>();
    const [failed, setFailed] = useState<string>();
    const [sending, setSending] = useState(false);
    const countryOptions = useMemo(() => countryList().getData(), [])
    const [errors, setErrors] = useState<{[index:string]:string}|undefined>(undefined);
    useEffect(() => {
        if(step===2) {
            setSending(true)
            setFailed(undefined)
            setErrors(undefined)
            let errorTest = false
            if (typeof variables?.street === "undefined" || variables?.street  === "") {
                errorTest = true
                setErrors(oldErrors => ({
                    ...(typeof oldErrors === "undefined" ? ({}) : oldErrors),
                    street: intl.formatMessage({
                        id: "ERRORS.STREET",
                        defaultMessage: "Please enter your street and number"
                    })
                }))
            }
            if (typeof variables?.postal === "undefined" || variables?.postal === "") {
                errorTest = true
                setErrors(oldErrors => ({
                    ...(typeof oldErrors === "undefined" ? ({}) : oldErrors),
                    postal: intl.formatMessage({id: "ERRORS.POSTAL", defaultMessage: "Please enter your postalcode"})
                }))
            }
            if (typeof variables?.city === "undefined" ||  variables?.city == "") {
                errorTest = true
                setErrors(oldErrors => ({
                    ...(typeof oldErrors === "undefined" ? ({}) : oldErrors),
                    city: intl.formatMessage({id: "ERRORS.CITY", defaultMessage: "Please enter your city"})
                }))
            }
            if (typeof variables?.country === "undefined"||variables?.country == "") {
                errorTest = true
                setErrors(oldErrors => ({
                    ...(typeof oldErrors === "undefined" ? ({}) : oldErrors),
                    country: intl.formatMessage({id: "ERRORS.COUNTRY", defaultMessage: "Please select a country"})
                }))
            }

            if (errorTest) {
                setFailed(undefined)
                setSending(false)
                return
            }
            const splitName = (typeof variables?.name === 'string' ? variables?.name : ' ').split(' ')
            if(splitName.length===1) {
                setFailed(undefined)
                setError("Please enter your first and last name")
                setShowNext(false)
                setShowPrev(true)
                setSending(false)
                return
            }
            let data: MollieData = {
                id: typeof product === "undefined" ? variables.linkid : variables?.personalId,
                currency: variables.currency,
                type,
                billingAddress: {
                    givenName: splitName.shift(),
                    familyName: splitName.join(' '),
                    email: variables?.mail,
                    streetAndNumber: variables?.street,
                    postalCode: variables?.postal,
                    city: variables?.city,
                    country: variables?.country
                },
                note: variables?.note,
                method: method?.startsWith('creditcard') ? method.split('-')[0] : method,
            }
            if(typeof variables?.ordernumber !== "undefined" && variables?.ordernumber !== "")
                data = {...data, ordernumber: variables?.ordernumber}
            if (typeof product !== "undefined") {
                if (product?.metaValues?.find(x => x.slug === "cycle") && product?.metaValues?.find(x => x.slug === "cycle")?.value !== "")
                    data = {...data, subscription: product?.id ?? '0'}
                else
                    data = {...data, onetime: product?.id ?? '0'}
            } else
                data = {...data, amount: typeof amount === "undefined" ? 100 : parseFloat(amount.replace('custom-',''))}
            if(typeof extraFields !== "undefined" && Array.isArray(extraFields) && extraFields.length > 0)
                data = {...data, metadata: extraFields.map(x => ['number','string','E164Number','boolean'].includes(typeof x)?x:JSON.stringify(x))}
            else if(typeof extraFields !== "undefined" && extraFields)
                data = {...data, metadata: extraFields}
            createPayment(data).then(resp => {
                if ("error" in resp) {
                    setSending(false)
                    setShowNext(false)
                    setShowPrev(true)
                    setFailed(undefined)
                    setError(resp.error)
                    return;
                }
                if(resp.status === "failed")
                    setFailed(intl.formatMessage({id: 'GENERAL.FAILED', defaultMessage: 'Your payment is failed, please try again!'}))
                if(document.body.classList.contains('iframe-body'))
                    window.parent.location.href = resp?.redirect
                else
                    window.location.href = resp?.redirect
            })
        }
    }, [step]);

    useEffect(() => {
        setShowPrev(true)
        setShowNext(true)
    }, []);


    if(typeof error !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{error}</div>
        </div>
    }
    if(typeof failed !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning">
            <KTSVG path="/media/icons/duotune/general/gen045.svg" className="svg-icon-3x svg-icon-warning me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{failed}</div>
        </div>
    }
    if(sending) {
        return <div className="d-flex flex-column w-100 py-15">
            <h3 data-kt-indicator="on"
                className="d-flex card-title align-items-center flex-column justify-content-center mb-3">
        <span className="indicator-label">
            {intl.formatMessage({id: 'GENERAL.LOADINGMOLLIE', defaultMessage: 'Creating payment...'})}
        </span>
                <span className="card-label fw-bolder fs-3 indicator-progress text-center">
            {intl.formatMessage({id: 'GENERAL.LOADINGMOLLIE', defaultMessage: 'Creating payment...'})}<br/>
            <span className="spinner-border align-middle mt-10"></span>
        </span>
            </h3>
        </div>
    }
    //streetAndNumber, postalCode, city, country
    return <div className="d-flex justify-content-between flex-column px-0 pt-0 pb-6 align-items-center border-gray-200 border border-start-0 border-top-0 border-end-0 gap-6 mt-0 pr__customer_fields">
        {/*
         @ts-ignore */}
        <input type="text" className="form-control shadow-sm fw-normal fs-7" value={variables?.street??''} onChange={e=>setVariables({...variables,street:e?.target?.value})} placeholder="Street and number" />
        {typeof errors?.street !== "undefined"&&<div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mt-5 mb-10">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{errors?.street}</div>
        </div>}
        {/*
         @ts-ignore */}
        <input type="text" className="form-control shadow-sm fw-normal fs-7" value={variables?.postal??''} onChange={e=>setVariables({...variables,postal:e?.target?.value})} placeholder="Postalcode" />
        {typeof errors?.postal !== "undefined"&&<div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mt-5 mb-10">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{errors?.postal}</div>
        </div>}
        {/*
         @ts-ignore */}
        <input type="text" className="form-control shadow-sm fw-normal fs-7" value={variables?.city??''} onChange={e=>setVariables({...variables,city:e?.target?.value})} placeholder="City" />
        {typeof errors?.city !== "undefined"&&<div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mt-5 mb-10">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{errors?.city}</div>
        </div>}

        <Select
            styles={{
                control: (provided) => ({
                    ...provided,
                    background: '#ffffff',
                    padding: '0.1rem 1rem',
                    fontSize: '1.1rem',
                    color: '#3f4254',
                    borderRadius: '0.475rem',
                    width: '100%',
                    border: '1px  solid  var(--btn-background1) !important'
                })
            }}
            className={'w-100'}
            // @ts-ignore
            options={countryOptions}
            defaultValue={countryOptions.find(_country => _country.value === (typeof variables?.country === "string"?variables?.country:'').toUpperCase())??undefined}
            placeholder={intl.formatMessage({id: 'GENERAL.SELECTCOUNTRY', defaultMessage: 'Select country'})}
            onChange={value => setVariables({...variables,country:value?.value??''})}
        />
        {typeof errors?.country !== "undefined"&&<div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger mt-5 mb-10">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{errors?.country}</div>
        </div>}
    </div>
}

export {MollieLoader}
