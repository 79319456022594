import {FC} from "react";
import {Step1} from "./p24/Step1";
import {useRequest} from "../../../pages/requests/RequestWrapper";

export type P24Props = {
    setError: Function
}

const P24: FC<P24Props> = ({setError}) => {
    const {step} = useRequest()

    switch (step) {
        case 1:
        case 2:
        default:
            return <Step1 setError={setError} />
    }
}

export {P24}