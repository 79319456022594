import {paymentMethodIcons} from "../../modules/requests/core/_assets";

const priceRegex:RegExp = /^([\d\,\.])+$/i
const currencyRegex:RegExp = /^([\w]{3})$/i
const methodNames:string[] = Object.keys({...paymentMethodIcons,creditcard:' '})
const mailRegex:RegExp = /(.*)\@(.*)$/i

const requestSolver = (...variables: any[]) => {
    const [linkid, ...rest] = variables;
    const solved:{[index: string]:string} = {linkid, page: 'normal'};
    rest.filter(x => x !== undefined)
        .forEach((x,i) => {
            let matches;
            if(typeof x !== "string")
                return
            else if(i===0 && (x==='subscriptions'||x==='shop'||x==='verification'||x==='paymentverification'))
                solved['page'] = x
            else if(typeof solved['page'] !== "undefined" && ['subscriptions','shop'].includes(solved['page'])) {
                if(i===1)
                    solved['pid'] = x
                else if(i===2)
                    solved['name'] = x
                else if(i===3)
                    solved['email'] = x
                else if(i===4)
                    solved['note'] = x
            }else if((i===0||i===1) && (matches = priceRegex.exec(x)) !== null){
                if(x.indexOf(',')!==-1&&x.indexOf('.')!==-1){
                    if(x.indexOf(',')<x.indexOf('.'))
                        solved['price'] = x.replace(',','')
                    else
                        solved['price'] = x.replace('.','').replace(',','.')
                }else if(x.indexOf(',')!==-1)
                    solved['price'] = x.replace(',',(x.indexOf(',')+3)>=x.length?'.':'')
                else 
                    solved['price'] = x
            }else if(i===0 && methodNames.includes(x))
                solved['method'] = ["visa","mastercard","maestro","americanexpress"].includes(x)?'creditcard':x
            else if((i===0||i===1) && (matches = currencyRegex.exec(x)) !== null)
                solved['currency'] = x
            else if((i===2||i===3||i===4) && mailRegex.test(x))
                solved['email'] = x
            else if((i===1||i===2||i===3) && x.length>0){
                if(typeof solved['note'] === "undefined")
                    solved['note'] = x
                else
                    solved['name'] = x
            }
        })
    return solved
}

const hexToTextColor = (hex: string) => {
    const hexCode = hex.charAt(0) === '#'
        ? hex.substring(1, 6)
        : hex;

    const hexR = parseInt(hexCode.substring(0, 2), 16);
    const hexG = parseInt(hexCode.substring(2, 2), 16);
    const hexB = parseInt(hexCode.substring(4, 2), 16);
    // Gets the average value of the colors
    const contrastRatio = (hexR + hexG + hexB) / (255 * 3);

    return contrastRatio >= 0.5
        ? 'black'
        : 'white';
}

export { requestSolver, hexToTextColor };