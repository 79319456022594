import {FC, useEffect, useState} from "react";
import {IbanElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useRequest} from "../../../../pages/requests/RequestWrapper";
import {IdealProps} from "../Ideal";
import {useIntl} from "react-intl";
import {CSSTransition} from "react-transition-group";

const Step1: FC<IdealProps> = ({setError}) => {
    const intl = useIntl();
    const {step, variables, setVariables, setShowNext,product} = useRequest()
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState<boolean>(false);
    const options = {
        supportedCountries: ['SEPA'],
        style: {
            base: {
                color: '#32325d',
                padding: '10px 12px',
                lineHeight: '24px',
                fontFamily: 'Poppins, Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }
    }
    const handleResponse = (resp: {[key:string]:any}) => {
        if("error" in resp){
            setError(resp?.error?.message)
            setLoading(false)
        }else{
            switch (resp.paymentIntent.status) {
                case "succeeded":
                case "processing":
                    setVariables({...variables, payment_intent: resp.paymentIntent.id, page: "verification"})
                    break;
                case "requires_payment_method":
                    setError("Your payment was not successful, please try again.")
                    setShowNext(true)
                    break;
                default:
                    setError("Something went wrong.")
                    setShowNext(true)
                    break;
            }
        }
    }
    useEffect(() => {
        if(stripe !== null && elements !== null && step === 2) {
            const ibanBank = elements.getElement(IbanElement);
            if(ibanBank === null) {
                setError("Could not find ideal bank element")
                return
            }
            if(typeof variables?.name === "boolean"){
                setError("Please fill in your name")
                return
            }
            if(typeof variables?.mail === "boolean"){
                setError("Please fill in your email")
                return
            }
            if(typeof variables?.client_secret !== "string"){
                setError("For unknown reason the payment couldn't be finished, please try again")
                return
            }
            setLoading(true)
            if (product?.metaValues?.find(x => x.slug === "cycle") && product?.metaValues?.find(x => x.slug === "cycle")?.value !== ""){
                stripe.confirmSepaDebitSetup(variables.client_secret, {
                    payment_method: {
                        sepa_debit: ibanBank,
                        billing_details: {
                            name: variables.name,
                            email: variables.mail
                        }
                    },
                    return_url: `${process.env.REACT_APP_PUBLIC_URL}/${variables?.linkid}/verification`,
                }).then(resp => handleResponse(resp))
                    .catch(error => {setError(error.message); setLoading(false)})
            }else {
                stripe.confirmSepaDebitPayment(variables.client_secret, {
                    payment_method: {
                        sepa_debit: ibanBank,
                        billing_details: {
                            name: variables.name,
                            email: variables.mail
                        }
                    },
                    return_url: `${process.env.REACT_APP_PUBLIC_URL}/${variables?.linkid}/verification`,
                }).then(resp => handleResponse(resp))
                    .catch(error => {setError(error.message); setLoading(false)})
            }
        }
    }, [step]);


    return <>
        <CSSTransition
            in={loading}
            timeout={300}
            classNames="pr__stepper"
            unmountOnExit
        >
            <h3 data-kt-indicator="on" className="d-flex card-title align-items-center flex-column justify-content-center mb-3 py-15">
                <span className="indicator-label">
                    {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}
                </span>
                <span className="card-label fw-bolder fs-3 indicator-progress text-center">
                    {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}<br/>
                    <span className="spinner-border align-middle mt-10"></span>
                </span>
            </h3>
        </CSSTransition>

        <CSSTransition
            in={!loading}
            timeout={300}
            classNames="pr__stepper"
        >
            <div className="mandate-acceptance">
                By providing your payment information and confirming this payment,
                you authorise (A) Geert Jan Sloos and Stripe, our payment service
                provider, to send instructions to your bank to debit your account and
                (B) your bank to debit your account in accordance with those
                instructions. As part of your rights, you are entitled to a refund
                from your bank under the terms and conditions of your agreement with
                your bank. A refund must be claimed within 8 weeks starting from the
                date on which your account was debited. Your rights are explained in
                a statement that you can obtain from your bank. You agree to receive
                notifications for future debits up to 2 days before they occur.
            </div>
            <IbanElement options={options}/>
        </CSSTransition>
    </>
}

export {Step1}