/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {createContext, useContext, useMemo, useState, Dispatch, SetStateAction} from 'react'
import {PspIntegrationKeys} from "../../integrations/core/_models";
import {Products} from "../../modules/auth";

interface RequestContextProps {
    type: string | undefined
    setType: Dispatch<SetStateAction<string | undefined>>
    status: string | undefined
    setStatus: Dispatch<SetStateAction<string | undefined>>
    amount: string | undefined
    setAmount: Dispatch<SetStateAction<string | undefined>>
    psp: string | undefined
    setPsp: Dispatch<SetStateAction<string | undefined>>
    method: string | undefined
    setMethod: Dispatch<SetStateAction<string | undefined>>
    variables: {[index: string]:string|boolean}
    setVariables: Dispatch<SetStateAction<{[index: string]:string|boolean}>>
    extraFields: {[index: string]:string|any[]}
    setExtraFields: Dispatch<SetStateAction<{[index: string]:string|any[]}>>
    step: number
    setStep: Dispatch<SetStateAction<number>>
    integrations: PspIntegrationKeys
    setIntegrations: Dispatch<SetStateAction<PspIntegrationKeys>>
    bg: string | undefined
    setBg: Dispatch<SetStateAction<string | undefined>>
    showPrev: boolean
    setShowPrev: Dispatch<SetStateAction<boolean>>
    showNext: boolean
    setShowNext: Dispatch<SetStateAction<boolean>>
    product: Products | undefined
    setProduct: Dispatch<SetStateAction<Products | undefined>>
}

const RequestContext = createContext<RequestContextProps>({
    type: undefined,
    setType: (prevState: SetStateAction<string | undefined>) => prevState,
    status: undefined,
    setStatus: (prevState: SetStateAction<string | undefined>) => prevState,
    amount: undefined,
    setAmount: (prevState: SetStateAction<string | undefined>) => prevState,
    psp: undefined,
    setPsp: (prevState: SetStateAction<string | undefined>) => prevState,
    method: undefined,
    setMethod: (prevState: SetStateAction<string | undefined>) => prevState,
    variables: {},
    setVariables: (prevState: SetStateAction<{[index: string]:string|boolean}>) => prevState,
    extraFields: {},
    setExtraFields: (prevState: SetStateAction<{[index: string]:string|any[]}>) => prevState,
    step: 0,
    setStep: (prevState: SetStateAction<number>) => prevState,
    integrations: {},
    setIntegrations: (prevState: SetStateAction<PspIntegrationKeys>) => prevState,
    bg: undefined,
    setBg: (prevState: SetStateAction<string | undefined>) => prevState,
    showPrev: false,
    setShowPrev: (prevState: SetStateAction<boolean>) => prevState,
    showNext: false,
    setShowNext: (prevState: SetStateAction<boolean>) => prevState,
    product: undefined,
    setProduct: (prevState: SetStateAction<Products | undefined>) => prevState,
})

interface RequestProviderProps {
    children: React.ReactNode
}

function RequestProvider({ children }: RequestProviderProps): JSX.Element {
    const [type, setType] = useState<string | undefined>()
    const [status, setStatus] = useState<string | undefined>()
    const [amount, setAmount] = useState<string | undefined>()
    const [psp, setPsp] = useState<string | undefined>()
    const [method, setMethod] = useState<string | undefined>()
    const [variables, setVariables] = useState<{[index: string]:string|boolean}>({})
    const [extraFields, setExtraFields] = useState<{[index: string]:string|any[]}>({})
    const [step, setStep] = useState<number>(0)
    const [integrations, setIntegrations] = useState<PspIntegrationKeys>({})
    const [bg, setBg] = useState<string | undefined>()
    const [showPrev, setShowPrev] = useState<boolean>(false)
    const [showNext, setShowNext] = useState<boolean>(false)
    const [product, setProduct] = useState<Products | undefined>()

    const memo = useMemo(() => ({ type, setType, status, setStatus, amount, setAmount, method, setMethod, variables, setVariables,extraFields,setExtraFields, step, setStep, psp, setPsp, integrations,
            setIntegrations, bg, setBg, showPrev, setShowPrev, showNext, setShowNext, product, setProduct }),
        [type, setType, status, setStatus, amount, setAmount, method, setMethod, variables, setVariables,extraFields,setExtraFields, step, setStep, psp, setPsp, integrations, setIntegrations,
            bg, setBg, showPrev, setShowPrev, showNext, setShowNext, product, setProduct])

    return <RequestContext.Provider value={memo}>{children}</RequestContext.Provider>
}

const RequestConsumer = RequestContext.Consumer

const useRequest = () => useContext(RequestContext)

export { RequestProvider, RequestConsumer, useRequest }
