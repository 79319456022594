import axios from 'axios'
import {IntegrationUrl, RedirectResponse, StatusResponse, StripeIntent, SuccessResponse} from "./_models";
import {ErrorMessage} from "../../../modules/requests/core/_models";

const API_URL = process.env.REACT_APP_API_URL

export const INTEGRATION_URL = `${API_URL}/integrations/stripe`
export const INTEGRATION_CHECK_URL = `${API_URL}/integrations/stripe/check`

export const CREATE_INTENT = `${API_URL}/api/stripe/create-intent`
export const VERIFY_PAYMENT = `${API_URL}/api/stripe/verify`

export function getIntegrationStripe() {
    return axios.get<IntegrationUrl>(`${INTEGRATION_URL}`).then(response => response.data ?? false).catch(err => false)
}

export function checkIntegrationStripe(code:string, scope: string, error: string|null): Promise<StatusResponse|ErrorMessage> {
    const data: {[index: string]:any} = {code,scope}
    if(error) data['error'] = error
    return axios.post(
        `${INTEGRATION_CHECK_URL}`,
        data).then(response => response.data as unknown as StatusResponse)
        .catch(err => ({success:false, error: "Unknown error"}))
}

export function createIntent(data:object): Promise<StripeIntent|ErrorMessage> {
    return axios.post(`${CREATE_INTENT}`,data)
        .then(response => response.data ?? false)
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}

export function verifyPayment(data:object): Promise<SuccessResponse|RedirectResponse|ErrorMessage> {
    return axios.post(`${VERIFY_PAYMENT}`,data)
        .then(response => {
            if("redirect" in response?.data)
                return response.data as RedirectResponse
            else
                return response.data as SuccessResponse
        })
        .catch(err => ({status:'error', error: err?.response?.data?.error ?? "Unknown error"}) as ErrorMessage)
}