import axios from 'axios'
import {PersonalLink, PersonalLinkProduct} from "../../auth";
import {ErrorMessage, PaymentLink, paymentMethodsByPsp, RequestLog} from "./_models";

const API_URL = process.env.REACT_APP_API_URL

export const LINK_API = `${API_URL}/api/link`
export const LINK_RETRIEVE = `${API_URL}/api/retrieve`
export const LINK_LOG_API = `${API_URL}/api/sendLog`
export const LINK_METHODS_API = `${API_URL}/api/methods`
export const LINK_LOG_GET = `${API_URL}/api/log`
export const CUSTOMER_UPLOAD_FILE = `${API_URL}/api/contentupload`

// get paymnentlink by id and return response data
export function getPaymentLink(linkId:string): Promise<PaymentLink | ErrorMessage | boolean> {
    return axios.post(LINK_API,{ linkId })
        .then(response => response.data as PaymentLink)
        .catch(err => typeof err.error!=='undefined' ? err : false)
}
export function getLinkOrPersonal(search:string): Promise<PaymentLink | PersonalLink | PersonalLinkProduct | ErrorMessage> {
    return axios.post(LINK_RETRIEVE,{ search })
        .then(response => {
            if("type" in response.data){
                if(response.data.type==='personallink')
                    return response.data.data as PersonalLink
                else if(response.data.type==='product')
                    return response.data.data as PersonalLinkProduct
                else
                    return response.data.data as PaymentLink
            }else
                return {status:'error', error: 'unknown'} as ErrorMessage
        })
        .catch(() => ({status:'error', error: 'unknown'} as ErrorMessage))
}

export function sendPaymentLinkLog(linkId:string, title: string, type: string = "open-box") {
    axios.post(LINK_LOG_API, { linkId, title, type })
}

export function getPaymentLinkLogs(linkId:string): Promise<Array<RequestLog> | null> {
    return axios.post(LINK_LOG_GET, { linkId })
        .then(response => response.data as Array<RequestLog>)
        .catch(() => null)
}

export function getPaymentMethods(id:string,type:string): Promise<paymentMethodsByPsp|ErrorMessage> {
    return axios.post(LINK_METHODS_API, { id, type })
        .then(response => response.data as unknown as paymentMethodsByPsp)
        .catch(err => ("error" in err? err : {status:'error', error: 'unknown'}) as ErrorMessage)
}

export function getCountry(): Promise<string> {
    return axios.get('https://api.hostip.info')
        .then(xml => {
            return (new window.DOMParser()).parseFromString(xml.data, "text/xml");
        }).then(xmlDoc => xmlDoc.getElementsByTagName('countryAbbrev')[0].innerHTML)
}
type fileUploadType = {url:string}

export function fileUpload(filename: string, token: string, chunck: string, type: string): Promise<fileUploadType|boolean> {
    return axios.post(CUSTOMER_UPLOAD_FILE, chunck, {
        params: {
            token: token,
            imageName: filename
        },
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => response.data as unknown as fileUploadType).catch(() => false)
}