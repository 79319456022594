import {FC, useEffect, useState} from "react";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import {useRequest} from "../../../../pages/requests/RequestWrapper";
import {IdealProps} from "../Ideal";
import {useIntl} from "react-intl";
import {CSSTransition} from "react-transition-group";

const Step1: FC<IdealProps> = ({setError}) => {
    const intl = useIntl();
    const {step, variables, setShowNext, product} = useRequest()
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        setShowNext(false)
        if(stripe !== null && elements !== null) {
            if(typeof variables?.name === "boolean"){
                setError("Please fill in your name")
                return
            }
            if(typeof variables?.mail === "boolean"){
                setError("Please fill in your email")
                return
            }
            if(typeof variables?.client_secret !== "string"){
                setError("For unknown reason the payment couldn't be finished, please try again")
                return
            }
            setLoading(true)
            if (product?.metaValues?.find(x => x.slug === "cycle") && product?.metaValues?.find(x => x.slug === "cycle")?.value !== ""){

                stripe.confirmBancontactSetup(variables.client_secret, {
                    payment_method: {
                        billing_details: {
                            name: variables.name,
                            email: variables.mail
                        }
                    },
                    return_url: `${process.env.REACT_APP_PUBLIC_URL}/${variables?.linkid}/verification`,
                }).then(resp => {
                    if ("error" in resp) {
                        setError(resp?.error?.message)
                        setLoading(false)
                    }
                })
                    .catch(error => {
                        setError(error.message);
                        setLoading(false)
                    })
            }else {
                stripe.confirmBancontactPayment(variables.client_secret, {
                    payment_method: {
                        billing_details: {
                            name: variables.name
                        }
                    },
                    return_url: `${process.env.REACT_APP_PUBLIC_URL}/${variables?.linkid}/verification`,
                }).then(resp => {
                    if ("error" in resp) {
                        setError(resp?.error?.message)
                        setLoading(false)
                    }
                })
                    .catch(error => {
                        setError(error.message);
                        setLoading(false)
                    })
            }
        }
    }, [step]);


    return <>
        <CSSTransition
            in={loading}
            timeout={300}
            classNames="pr__stepper"
            unmountOnExit
        >
            <h3 data-kt-indicator="on" className="d-flex card-title align-items-center flex-column justify-content-center mb-3 py-15">
                <span className="indicator-label">
                    {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}
                </span>
                <span className="card-label fw-bolder fs-3 indicator-progress text-center">
                    {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}<br/>
                    <span className="spinner-border align-middle mt-10"></span>
                </span>
            </h3>
        </CSSTransition>
    </>
}

export {Step1}