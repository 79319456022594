import {FC, useEffect, useState} from "react";
import {EpsBankElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useRequest} from "../../../../pages/requests/RequestWrapper";
import {IdealProps} from "../Ideal";
import {useIntl} from "react-intl";
import {CSSTransition} from "react-transition-group";

const Step1: FC<IdealProps> = ({setError}) => {
    const intl = useIntl();
    const {step, variables} = useRequest()
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState<boolean>(false);
    const options = {
        style: {
            base: {
                color: '#32325d',
                padding: '10px 12px',
                lineHeight: '24px',
                fontFamily: 'Poppins, Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }
    }
    useEffect(() => {
        if(stripe !== null && elements !== null && step === 2) {
            const epsBank = elements.getElement(EpsBankElement);
            if(epsBank === null) {
                setError("Could not find EPS bank element")
                return
            }
            if(typeof variables?.name === "boolean"){
                setError("Please fill in your name")
                return
            }
            if(typeof variables?.client_secret !== "string"){
                setError("For unknown reason the payment couldn't be finished, please try again")
                return
            }
            setLoading(true)
            stripe.confirmEpsPayment(variables.client_secret, {
                payment_method: {
                    eps: epsBank,
                    billing_details: {
                        name: variables.name
                    }
                },
                return_url: `${process.env.REACT_APP_PUBLIC_URL}/${variables?.linkid}/verification`,
            }).then(resp => {
                if("error" in resp){
                    setError(resp?.error?.message)
                    setLoading(false)
                }
            })
                .catch(error => {setError(error.message); setLoading(false)})
        }
    }, [step]);


    return <>
        <CSSTransition
            in={loading}
            timeout={300}
            classNames="pr__stepper"
            unmountOnExit
        >
            <h3 data-kt-indicator="on" className="d-flex card-title align-items-center flex-column justify-content-center mb-3 py-15">
                <span className="indicator-label">
                    {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}
                </span>
                <span className="card-label fw-bolder fs-3 indicator-progress text-center">
                    {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}<br/>
                    <span className="spinner-border align-middle mt-10"></span>
                </span>
            </h3>
        </CSSTransition>

        <CSSTransition
            in={!loading}
            timeout={300}
            classNames="pr__stepper"
        >
            <EpsBankElement options={options}/>
        </CSSTransition>
    </>
}

export {Step1}