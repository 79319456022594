import React, {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useRequest} from "../../pages/requests/RequestWrapper";
import {verifyPayment} from "./core/_requests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {KTSVG} from "../../../_metronic/helpers";

const StripeVerify: FC = () => {
    const intl = useIntl()
    const urlParams = new URLSearchParams(window.location.search)
    const {variables, type} = useRequest()
    const [isPaid, setIsPaid] = useState<boolean|undefined>();
    const [error, setError] = useState<string>();
    const [failed, setFailed] = useState<string>();
    useEffect(() => {
        if(typeof variables === "undefined")
            return
        let data:{[key:string]:any} = {
            id: variables?.linkid ?? '',
            type: type ?? 'link'
        };
        if(urlParams.get('payment_intent')||variables?.payment_intent)
            data.payment_intent = variables?.payment_intent ?? urlParams.get('payment_intent')
        if(urlParams.get('setup_intent')||variables?.setup_intent)
            data.setup_intent = variables?.setup_intent ?? urlParams.get('setup_intent')
        verifyPayment(data).then(resp => {
            if("redirect" in resp) {
                setIsPaid(true)
                setTimeout(() => {
                    if(document.body.classList.contains('iframe-body'))
                        window.parent.location.href = resp.redirect
                    else
                        window.location.href = resp.redirect
                },1500)
            }else if("error" in resp)
                setError(resp.error)
            else if(resp.status !== "success")
                setFailed(intl.formatMessage({id: 'GENERAL.FAILED', defaultMessage: 'Your payment is failed, please try again!'}))
            else
                setIsPaid(resp.status==='success')
        })
    }, [variables]);

    if(typeof error !== "undefined") {
        return <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger">
            <KTSVG path="/media/icons/duotune/general/gen050.svg" className="svg-icon-3x svg-icon-danger me-5"/>
            <div className="text-gray-700 fw-bold fs-6">{error}</div>
        </div>
    }
    return <>
        {typeof isPaid !== "boolean" && <div className="d-flex flex-column w-100 py-15">
            <h3 data-kt-indicator="on" className="d-flex card-title align-items-center flex-column justify-content-center mb-3">
        <span className="indicator-label">
            {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}
        </span>
                <span className="card-label fw-bolder fs-3 indicator-progress text-center">
            {intl.formatMessage({id: 'GENERAL.VERIFYPAYMENT', defaultMessage: 'Verifying payment...'})}<br/>
            <span className="spinner-border align-middle mt-10"></span>
        </span>
            </h3>
        </div>}
        {isPaid && <div className="px-10">
            <div className="alert bg-light-success d-flex flex-column flex-sm-row p-5 mt-10">
                <span className="svg-icon svg-icon-2hx svg-icon-success me-4 mb-5 mb-sm-0">
                    {/*
                    @ts-ignore */}
                    <FontAwesomeIcon icon="fa-duotone fa-face-smile-beam" />
                </span>
                <div className="d-flex flex-column text-success pe-0">
                    <h5 className="mb-1">Thank you for your {type==='preauth'?'authorization':'payment'}</h5>
                    <span>Your {type==='preauth'?'authorization':'payment'} has been registered and the owner has been notified.</span>
                </div>
            </div>
        </div>}
    </>
}
export {StripeVerify}