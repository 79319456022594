import {Outlet} from 'react-router-dom'
import {ScrollTop} from './components/ScrollTop'
import {PageDataProvider} from './core'
import {RequestProvider} from "../../app/pages/requests/RequestWrapper";

const RequestLayout = () => {
  return (
    <PageDataProvider>
        <RequestProvider>
              <Outlet />
        </RequestProvider>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {RequestLayout}
