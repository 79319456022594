import React, {FC, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import { useFormik } from "formik";
import * as Yup from 'yup'
import clsx from "clsx";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {login} from "../../modules/auth/core/_requests";

const loginBreadCrumbs: Array<PageLink> = [
    {
        title: 'PayRequest',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Subscription Management',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    }
]

const Login: FC = () => {
    const intl = useIntl()
    const [sending, setSending] = useState(false)
    const [response, setResponse] = useState(false)
    const translatable = defineMessages({
        incorrect: {
            id: 'AUTH.INCORRECT',
            defaultMessage: 'Your mail address is unknown',
        },
        format: {
            id: 'AUTH.FORMAT',
            defaultMessage: 'Wrong email format',
        },
        xsymbols: {
            id: 'AUTH.XSYMBOLS',
            defaultMessage: '{type} {num} symbols',
        },
        emailrequired: {
            id: 'AUTH.EMAILREQUIRED',
            defaultMessage: 'Email is required',
        }
    });
    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage(translatable.format))
            .min(3, intl.formatMessage(translatable.xsymbols, {type: 'Minimal', num: 3}))
            .max(50, intl.formatMessage(translatable.xsymbols, {type: 'Maximum', num: 50}))
            .required(intl.formatMessage(translatable.emailrequired)),
    });
    const initialValues = {
        email: ''
    };
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setSending(true)
            try {
                login(values.email).then(resp => {
                    console.log(resp)
                    setSending(false)
                    setSubmitting(false)
                    if(resp?.status==='success'){
                        setResponse(true);
                    }else{
                        setStatus(intl.formatMessage(translatable.incorrect))
                    }
                })
            } catch (error) {
                console.error(error)
                setStatus(intl.formatMessage(translatable.incorrect))
                setSubmitting(false)
                setSending(false)
            }
        },
    })
    return <>
        <PageTitle breadcrumbs={loginBreadCrumbs}>Login</PageTitle>
        <div className='page d-flex flex-row justify-content-center'>
            <div className={"card rounded-3 w-md-550px"}>
                <div className="card-body p-10 p-lg-20">

                    <form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='pr__sm__login'
                    >
                        <div className="text-center mb-11">
                            <h1 className="text-dark fw-bolder mb-3">{intl.formatMessage({id: 'SUB.LOGIN', defaultMessage: 'Login'})}</h1>
                            <div className="text-gray-500 fw-semibold fs-6">{intl.formatMessage({id: 'SUB.LOGINDESC', defaultMessage: 'Fill in your email below and we will send you a \'magic\' login link to view and manage your subscriptions.'})}</div>
                        </div>
                        {response?(
                            <div className='text-center'>
                                <h1 className="text-dark mb-3">
                                    <img alt="Logo"
                                         src={toAbsoluteUrl('/media/icons/communication/mail-notification.svg')}
                                         className="me-1"/>
                                    <FormattedMessage
                                        id="AUTH.MAIL"
                                        defaultMessage="You've got mail!"
                                    />
                                </h1>
                                <div className="text-gray-400 fw-bold fs-4">
                                    <FormattedMessage
                                        id="AUTH.CHECKMAIL"
                                        defaultMessage="Please open your mail client and click on the magic login link we sent to you"
                                    />
                                    <br/>
                                    <button type="submit"
                                            id="kt_resend_submit"
                                            className="btn btn-lg btn-active-light-primary btn-text-primary"
                                            onClick={() => formik.submitForm}>
                                    {!sending &&
                                        <span className='indicator-label'>
                                          <FormattedMessage
                                              id="AUTH.RESEND"
                                              defaultMessage="Resend Link"
                                          />
                                        </span>}
                                    {sending &&
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                          <FormattedMessage
                                              id="GENERAL.WAIT"
                                              defaultMessage="Please wait..."
                                          />
                                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>}
                                    </button>
                                </div>
                            </div>
                        ):(
                            <>
                                {formik.status ? (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                ) : ''}

                                <div className='fv-row mb-10'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                        <FormattedMessage
                                            id="GLOBAL.EMAIL"
                                            defaultMessage="Email"
                                        />
                                    </label>
                                    <input
                                        placeholder='Email'
                                        {...formik.getFieldProps('email')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.email && formik.errors.email},
                                            {'is-valid': formik.touched.email && !formik.errors.email}
                                        )}
                                        type='email'
                                        name='email'
                                        autoComplete='off'
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    )}
                                </div>

                                <div className='text-center'>
                                    <button
                                        type='submit'
                                        id='kt_sign_in_submit'
                                        className='btn btn-lg btn-primary w-100 mb-5'
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!sending && <span className='indicator-label'>
                                            <FormattedMessage
                                            id="GENERAL.CONTINUE"
                                            defaultMessage="Continue" />
                                        </span>}
                                        {sending && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                              <FormattedMessage
                                                  id="GENERAL.WAIT"
                                                  defaultMessage="Please wait..."
                                              />
                                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    </>
}

export {Login}